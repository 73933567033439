// Source: https://github.com/fawazahmed0/currency-api

const fetchWithFallback = async (links: string[]) => {
  let response;
  // eslint-disable-next-line no-restricted-syntax
  for (const link of links) {
    try {
      // eslint-disable-next-line no-await-in-loop
      response = await fetch(link);
      if (response.ok) return response?.json();
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  return response?.json();
};

const getConvertedRate = async (targetCurrency: string): Promise<number | undefined> => {
  const currentDate = new Date().toISOString().split('T')[0];
  const suffix = `${targetCurrency}.json?d=${currentDate}`;
  const minSuffix = `${targetCurrency}.min.json?d=${currentDate}`;
  const resp = (await fetchWithFallback([
    `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd/${minSuffix}`,
    `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd/${suffix}`,
    `https://raw.githubusercontent.com/fawazahmed0/currency-api/1/latest/currencies/usd/${minSuffix}`,
    `https://raw.githubusercontent.com/fawazahmed0/currency-api/1/latest/currencies/usd/${suffix}`,
  ]));
  return resp?.[targetCurrency as keyof typeof resp] as number | undefined;
};

export default getConvertedRate;
