/* eslint-disable no-param-reassign */
import { AuthClientEvent }             from '@react-keycloak/core/lib/types';
import { createSlice, PayloadAction }  from '@reduxjs/toolkit';
import { ApiUser }                     from '../../interfaces/api/ApiUser';
import AuthState, { KeycloakUserInfo } from '../../interfaces/store/states/AuthState';

const initialState: AuthState = {
  keycloakUserInfo: null,
  token: null,
  user: null,
  keycloakErrorType: null,
  hasError: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setKeycloakUserInfo: (state, action: PayloadAction<KeycloakUserInfo>) => {
      state.keycloakUserInfo = action.payload;
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<ApiUser>) => {
      state.user = action.payload;
    },
    setKeycloakError: (state, action: PayloadAction<AuthClientEvent>) => {
      state.keycloakErrorType = action.payload;
      state.hasError = true;
    },
  },
});

export const {
  setKeycloakUserInfo, setToken, setKeycloakError, setUserInfo,
} = authSlice.actions;

const authReducer = authSlice.reducer;
export default authReducer;
