import { createApi } from '@reduxjs/toolkit/query/react';
import API_TAGS      from '../../core/constants/api.tags.constants';
import ApiBaseQuery  from '../../core/config/apiBaseQuery';

const fundEducationApi = createApi({
  reducerPath: 'fundEducation',
  baseQuery: ApiBaseQuery,
  tagTypes: API_TAGS,
  endpoints: () => ({}),
});

export default fundEducationApi;
