import { generatePath }                                            from 'react-router-dom';
import {
  CHARGED_AMOUNT_TAG,
  DONATIONS_ADMIN_TAG, DONATIONS_TAG, LIST_ID_TAG, PROJECTS_TAG,
} from '../../core/constants/api.tags.constants';
import { DONATIONS_URIS }                                         from '../../core/constants/api.uris.constants';
import {
  ApiDonation, ChargedAmountInfo, DonationFields, DonationInfo,
} from '../../core/interfaces/api/ApiDonation';
import ListData                     from '../../core/interfaces/general/ListData';
import PageableInputType            from '../../core/shared/utils/pageableInputType';
import serializeObjectToQueryParams from '../../core/shared/utils/serializeObjectToQueryParams';
import fundEducationApi             from '../apis/fundEducation.api';

export type DonationFilters = {
  search?: string;
}

type GetDonationsAdminInputType = {
  filters?: DonationFilters;
} & PageableInputType;

type GetDonationsInputType = {
  projectId: string;
} & PageableInputType;

export const donationsApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getSponsorCount: build.query<{ count: string }, string | undefined>({
      query: (id) => generatePath(DONATIONS_URIS.sponsorCount, { id }),
      providesTags: (_, __, id) => [{ type: DONATIONS_TAG, id }],
    }),
    getDonationsAdmin: build.query<ListData<ApiDonation>, GetDonationsAdminInputType>({
      query: ({
        page = 1,
        perPage = 10,
        filters = {},
      }) => `${DONATIONS_URIS.adminDonations}?page=${page}&perPage=${perPage}${serializeObjectToQueryParams(filters)}`,
      providesTags: (result) => (
        result
          ? [
            ...result.data.map(({ id }) => ({ type: DONATIONS_ADMIN_TAG, id } as const)),
            { type: DONATIONS_ADMIN_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: DONATIONS_ADMIN_TAG, id: LIST_ID_TAG }]
      ),
    }),
    getDonations: build.query<ListData<ApiDonation>, GetDonationsInputType>({
      query: ({
        projectId,
        page = 1,
        perPage = 5,
      }) => `${generatePath(DONATIONS_URIS.donations, { id: projectId })}?page=${page}&perPage=${perPage}`,
      providesTags: (_, __, { projectId }) => [{ type: DONATIONS_TAG, id: projectId }],
    }),
    getDonationInfo: build.query<DonationInfo, string | undefined>({
      query: (id) => generatePath(DONATIONS_URIS.donationInfo, { id }),
      providesTags: (_, __, id) => [{ type: DONATIONS_TAG, id }],
    }),
    getChargedAmount: build.query<ChargedAmountInfo, number>({
      query: (amount) => `${DONATIONS_URIS.chargedAmount}?${serializeObjectToQueryParams({ amount }, false)}`,
      providesTags: (_, __, amount) => [{ type: CHARGED_AMOUNT_TAG, id: amount }],
    }),
    createDonation: build.mutation<null, DonationFields>({
      query: (body) => ({
        url: DONATIONS_URIS.donate,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { projectId }) => [
        { type: DONATIONS_TAG, id: projectId },
        { type: PROJECTS_TAG, id: projectId },
      ],
    }),
    refundDonation: build.mutation<null, string>({
      query: (id) => ({
        url: generatePath(DONATIONS_URIS.refund, { id }),
        method: 'PUT',
      }),
      invalidatesTags: (_result, _error, id) => [{ type: DONATIONS_ADMIN_TAG, id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetSponsorCountQuery,
  useGetDonationsAdminQuery,
  useGetDonationsQuery,
  useGetDonationInfoQuery,
  useGetChargedAmountQuery,
  useCreateDonationMutation,
  useRefundDonationMutation,
} = donationsApi;
