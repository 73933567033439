import parse                          from 'html-react-parser';
import React                          from 'react';
import { File }                       from 'react-feather';
import { useTranslation }             from 'react-i18next';
import { generatePath, useNavigate }  from 'react-router-dom';
import AlertError                     from '../../../../../../components/Alerts/AlertError';
import { FILES_URIS }                 from '../../../../../constants/api.uris.constants';
import APP_PATHS                      from '../../../../../constants/routes.constants';
import useDownloadFile                from '../../../../../hooks/useDownloadFile';
import useGetRoles, { ADMIN }         from '../../../../../hooks/useGetRoles';
import { ApiProject }                 from '../../../../../interfaces/api/ApiProject';
import { EditButton, OutlinedButton } from '../../../../../../components/buttons';
import './index.less';
import { SchoolInfoCard }             from '../SchoolInfoCard';

type Props = {
  project: ApiProject;
  canEdit: boolean;
}

export const GeneralDetails: React.FC<Props> = ({ project, canEdit }) => {
  const { t } = useTranslation(['common', 'organization']);
  const navigate = useNavigate();
  const downloadFile = useDownloadFile();
  const { role } = useGetRoles();
  const { edit, detail } = APP_PATHS.organization.projects;

  const goToEdit = () => navigate(generatePath(`${detail}/${edit}`, {
    institutionId: project.institutionId,
    projectId: project.id,
  }));

  const onDownload = () => {
    const extension = project.deletionJustification?.documentUrl.split('.').pop();

    const downloadUrl = generatePath(
      FILES_URIS.deletionJustifications,
      { id: project.deletionJustification?.id },
    );
    downloadFile(downloadUrl, `${project.title}.${extension}`);
  };

  return (
    <div id='project-general-details'>
      {
        project.statusId !== 1 && (
          <AlertError title={ t('projects.deleted.title') }>
            <p>
              <b>{ t('projects.deleted.reason') }</b>
              { ' ' }
              { project.deletionJustification?.description }
            </p>
            {
              role === ADMIN && (
                <OutlinedButton onClick={ () => onDownload() } className='download-btn'>
                  <File />
                  { t('projects.deleted.document') }
                </OutlinedButton>
              )
            }
          </AlertError>
        )
      }
      {
        canEdit && (
          <EditButton onClick={ goToEdit } className='edit-project-btn'>
            { t('organization:projects.edit') }
          </EditButton>
        )
      }
      <h2>{ project.title }</h2>
      <p id='summary'>{ project.summary }</p>
      {
        project.student != null && <SchoolInfoCard project={project} />
      }
      <div id='project-description'>
        { parse(project.description) }
      </div>
    </div>
  );
};
