import { BreadcrumbComponentType } from 'use-react-router-breadcrumbs';
import APP_PATHS                   from '../../../core/constants/routes.constants';
import { GradeBreadcrumb }         from './GradeBreadcrumb';
import { InstitutionBreadcrumb }   from './InstitutionBreadcrumb';
import { ProjectBreadcrumb }       from './ProjectBreadcrumb';
import { ProjectUpdateBreadcrumb } from './ProjectUpdateBreadcrumb';
import { UserTypeBreadcrumb }      from './UserTypeBreadcrumb';

export type BreadcrumbItemType = {
  breadcrumb: string | BreadcrumbComponentType;
  path: string;
}

export const ADMIN_BREADCRUMBS_ITEMS: BreadcrumbItemType[] = [
  {
    breadcrumb: 'admin:routes.root',
    path: APP_PATHS.admin.root,
  },
  {
    breadcrumb: 'admin:routes.payment_gateway',
    path: APP_PATHS.admin.paymentGateways,
  },
  {
    breadcrumb: 'admin:routes.blog_entries',
    path: APP_PATHS.admin.blogEntries,
  },
  /* {
    breadcrumb: 'admin:routes.school_reports',
    path: APP_PATHS.admin.schoolsReports,
  }, */
  {
    breadcrumb: 'admin:routes.users',
    path: APP_PATHS.admin.users,
  },
  {
    breadcrumb: 'admin:routes.organizations',
    path: APP_PATHS.admin.organizations,
  },
  {
    breadcrumb: UserTypeBreadcrumb,
    path: APP_PATHS.admin.usersType,
  },
  {
    breadcrumb: 'admin:routes.pending_accounts',
    path: APP_PATHS.admin.petitions,
  },
  {
    breadcrumb: 'admin:routes.project_category_types',
    path: APP_PATHS.admin.projectCategoryTypes,
  },
  {
    breadcrumb: 'admin:routes.grades.root',
    path: APP_PATHS.admin.grades.root,
  },
  {
    breadcrumb: 'admin:routes.grades.create',
    path: APP_PATHS.admin.grades.root,
  },
  {
    breadcrumb: GradeBreadcrumb,
    path: APP_PATHS.admin.grades.edit,
  },
  {
    breadcrumb: 'admin:routes.donations',
    path: APP_PATHS.admin.donations,
  },
];

export const ORGANIZATION_BREADCRUMBS_ITEMS: BreadcrumbItemType[] = [
  {
    breadcrumb: 'organization:routes.root',
    path: APP_PATHS.organization.root,
  },
  {
    breadcrumb: 'organization:routes.edit',
    path: APP_PATHS.organization.edit,
  },
  {
    breadcrumb: 'organization:routes.institutions.list',
    path: APP_PATHS.organization.institutions,
  },
  {
    breadcrumb: InstitutionBreadcrumb,
    path: APP_PATHS.organization.institution,
  },
  {
    breadcrumb: 'organization:routes.institutions.create',
    path: APP_PATHS.organization.createInstitution,
  },
  {
    breadcrumb: 'organization:routes.institutions.edit',
    path: APP_PATHS.organization.editInstitution,
  },
  {
    breadcrumb: 'organization:routes.projects.new_student',
    path: APP_PATHS.organization.newStudentProject,
  },
  {
    breadcrumb: 'organization:routes.projects.new_institutional',
    path: APP_PATHS.organization.newInstitutionalProject,
  },
  {
    breadcrumb: 'organization:routes.project_list',
    path: APP_PATHS.organization.projectList,
  },
  {
    breadcrumb: ProjectBreadcrumb,
    path: APP_PATHS.organization.projectRoot,
  },
  {
    breadcrumb: 'organization:routes.projects.detail',
    path: APP_PATHS.organization.projects.detail,
  },
  {
    breadcrumb: 'organization:routes.projects.edit',
    path: `${APP_PATHS.organization.projects.detail}/${APP_PATHS.organization.projects.edit}`,
  },
  {
    breadcrumb: 'organization:routes.projects.scores',
    path: `${APP_PATHS.organization.projects.detail}/${APP_PATHS.organization.projects.scores}`,
  },
  {
    breadcrumb: 'organization:routes.projects.updates',
    path: `${APP_PATHS.organization.projects.detail}/${APP_PATHS.organization.projects.updates}`,
  },
  {
    breadcrumb: ProjectUpdateBreadcrumb,
    path: `${APP_PATHS.organization.projects.detail}/${APP_PATHS.organization.projects.updatesEdit}`,
  },
];

export const SPONSOR_BREADCRUMBS_ITEMS: BreadcrumbItemType[] = [
  {
    breadcrumb: 'sponsor:routes.root',
    path: APP_PATHS.sponsor.home,
  },
  {
    breadcrumb: 'sponsor:routes.project_list',
    path: APP_PATHS.sponsor.projectList,
  },
  {
    breadcrumb: ProjectBreadcrumb,
    path: APP_PATHS.sponsor.sponsorProjectDetail,
  },
];
