import React, {
  useCallback, useEffect, useMemo, useState,
}                                                              from 'react';
import {
  Button, Form, message, Upload, UploadProps,
}                                                              from 'antd';
import Icon, { UploadOutlined }                        from '@ant-design/icons';
import { RcFile, UploadChangeParam }                   from 'antd/lib/upload/interface';
import { Trash }                                       from 'react-feather';
import { useTranslation }                              from 'react-i18next';
import { ImageIcon }                                   from '../../../core/constants/assets.constants';
import { getFileAsSrc, normFile, validateFileIsImage } from '../utils';
import './index.less';

export type ImageFieldValuesType = RcFile | string | undefined;

type ChangedValueType = (changedValue: ImageFieldValuesType) => void

type ImageFieldProps = {
  id?: string;
  value?: ImageFieldValuesType;
  onChange?: (file: ImageFieldValuesType) => void;
  onDelete?: () => void;
  disabled?: boolean;
  mode?: 'compact' | 'normal';
}

const ImageField: React.FC<ImageFieldProps> = ({
  value, onChange, onDelete, disabled, mode = 'normal',
}) => {
  const { t } = useTranslation();
  const [initialValue] = useState(value);
  const [file, setFile] = useState<RcFile | null>(null);
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const [internalChange, setInternalChange] = useState(false);

  const triggerChange = useCallback<ChangedValueType>((changedValue) => {
    setInternalChange(true);
    onChange?.(changedValue);
  }, []);

  const handleChange = ({ fileList }: UploadChangeParam) => {
    const uploadedFile = fileList[0].originFileObj ?? null;

    if (uploadedFile !== null && !validateFileIsImage(uploadedFile)) {
      message.warn(t('image_field.invalid_file'));
      return;
    }

    setInternalChange(true);
    setFile(uploadedFile);
  };

  const onClear = () => {
    if (file !== null) {
      setFile(null);
      setThumbnail(null);
      onChange?.(initialValue);
    }
  };

  useEffect(() => {
    if (typeof value === 'string') setThumbnail(value);
  }, [value]);

  useEffect(() => {
    if (!internalChange) {
      setInternalChange(true);
      if (value && typeof value === 'object' && file?.uid !== value.uid) {
        setFile(value);
      } else if (value === undefined && file !== null) {
        setFile(null);
        setThumbnail(null);
      }
    }
  }, [file, internalChange, value]);

  useEffect(() => {
    if (file !== null) triggerChange(file);
  }, [file, triggerChange]);

  useEffect(() => {
    if (file !== null) getFileAsSrc(file).then(setThumbnail);
  }, [file]);

  useEffect(() => {
    setInternalChange(false);
  }, [onChange]);

  const uploadProps: UploadProps = useMemo(() => ({
    accept: 'image/*',
    className: `custom-image-field ${mode}`,
    maxCount: 1,
    showUploadList: false,
    beforeUpload: () => false,
    onChange: handleChange,
    disabled,
  }), [disabled, mode]);

  return (
    <Form.Item
      noStyle
      valuePropName='fileList'
      getValueFromEvent={ normFile }
    >
      { mode === 'normal' && (
        <Upload.Dragger { ...uploadProps }>
          <p className={ `upload-info-container ${thumbnail === null ? 'not-thumbnail' : ''}` }>
            { thumbnail === null ? (
              <Icon component={ ImageIcon.ReactComponent } />
            ) : (
              <img className='thumbnail' src={ thumbnail } alt={ t('image_field.alt_text') } />
            ) }
          </p>
        </Upload.Dragger>
      ) }

      { mode === 'compact' && (
        <Upload { ...uploadProps }>
          <Button disabled={ disabled } size='large' icon={ <UploadOutlined /> }>
            { file === null ? t('image_field.label') : file.name }
          </Button>
        </Upload>
      ) }

      { !disabled && (
        <div className={ `custom-image-field-toolbar ${mode}` }>
          <Button
            size='large'
            type={ mode === 'normal' ? 'text' : 'primary' }
            icon={ <Trash /> }
            onClick={ onClear }
          />

          { onDelete !== undefined && (
            <Button
              size='large'
              type={ mode === 'normal' ? 'text' : 'primary' }
              danger={ mode === 'compact' }
              icon={ <Trash /> }
              onClick={ onDelete }
            />
          ) }
        </div>
      ) }
    </Form.Item>
  );
};

export default ImageField;
