import React                                         from 'react';
import ReactDOM                                      from 'react-dom/client';
import { Provider as StoreProvider }                 from 'react-redux';
import { ReactKeycloakProvider as KeycloakProvider } from '@react-keycloak/web';
import { BrowserRouter }                             from 'react-router-dom';
import App                                           from './App';
import appKeycloakInstance                           from './core/config/keycloak.config';
import { handleInitError }                           from './core/shared/utils/auth';
import appStore                                      from './core/store';
import { setToken }                                  from './core/store/features/authSlice';
import reportWebVitals                               from './reportWebVitals';
import './styles/main.less';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StoreProvider store={ appStore }>
    <KeycloakProvider
      authClient={ appKeycloakInstance }
      onEvent={ handleInitError }
      onTokens={ ({ token }) => appStore.dispatch(setToken(token ?? null)) }
    >
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </KeycloakProvider>
  </StoreProvider>,
);

reportWebVitals();
