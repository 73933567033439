/* eslint-disable consistent-return */
import { RuleObject } from 'rc-field-form/lib/interface';

type NumberValidation = (_: RuleObject, value: number) => Promise<undefined>;

export const minNumber = (
  minValue: number,
  errorMessage: string,
): NumberValidation => async (_: RuleObject, value: number) => {
  if (value < minValue) {
    return Promise.reject(new Error(errorMessage));
  }
};

export const maxNumber = (
  maxValue: number,
  errorMessage: string,
): NumberValidation => async (_: RuleObject, value: number) => {
  if (value > maxValue) {
    return Promise.reject(new Error(errorMessage));
  }
};
