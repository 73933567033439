import {
  Button, Col, Form, Row,
}                                     from 'antd';
import React, { useEffect }            from 'react';
import { useTranslation }              from 'react-i18next';
import { useAppDispatch }              from '../../../../core/hooks/store';
import useCrudNotificationResponse     from '../../../../core/hooks/useCrudNotificationResponse';
import { setLoader }                   from '../../../../core/store/features/uiSlice';
import { useCompletePetitionMutation } from '../../../../adapters/services/organizations.service';
import './style.less';

const { Item } = Form;

const CompletePetitionForm: React.FC = () => {
  const { t } = useTranslation('organization');
  const dispatch = useAppDispatch();

  const [complete, {
    isLoading,
    isSuccess,
    error,
  }] = useCompletePetitionMutation();

  useCrudNotificationResponse({
    isSuccess,
    error,
    successNotification: {
      message: t('wizard.step4.actions.complete.success.title'),
      description: t('wizard.step4.actions.complete.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  return (
    <Form
      layout='vertical'
      name='frmCompletePetition'
      requiredMark={ false }
      className='wizard-form'
      onFinish={ complete }
    >
      <Row gutter={ [16, 0] }>
        <Col span={ 24 } className='text-center'>
          <h4>{ t('wizard.step4.form.title') }</h4>
        </Col>
        <Col span={ 24 } className='wizard-step-description'>
          { t('wizard.step4.form.description') }
        </Col>
      </Row>
      <Item>
        <Button
          block
          type='primary'
          size='large'
          htmlType='submit'
        >
          { t('wizard.step4.form.submit') }
        </Button>
      </Item>
    </Form>
  );
};

export default CompletePetitionForm;
