import {
  Button, Card, Col, DatePicker, Empty, Form, Input, InputNumber, Modal, notification, Row,
}                                               from 'antd';
import TextArea                                 from 'antd/lib/input/TextArea';
import React, { useEffect, useState }           from 'react';
import { useForm }                              from 'antd/lib/form/Form';
import moment                                   from 'moment';
import { StoreValue, ValidatorRule }            from 'rc-field-form/lib/interface';
import { PlusCircle, Trash2 }                   from 'react-feather';
import { TFunction, useTranslation }            from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useGetOrganizationCategoryTypesQuery } from '../../../../adapters/services/projectCategoryTypes.service';
import {
  useCreateProjectMutation, useDeleteProjectMutation,
  useGetProjectQuery,
  useUpdateProjectMutation,
}                                               from '../../../../adapters/services/projects.service';

import ImageField             from '../../../../components/fields/ImageField';
import { ensureIsValidImage } from '../../../../components/fields/ImageField/ImageValidators';
import WYGIWYSField           from '../../../../components/fields/WYGIWYSField';
import DeleteProjectForm
  from '../../../../components/forms/organization/DeleteProjectForm/form';
import APP_PATHS                   from '../../../constants/routes.constants';
import { useAppDispatch }          from '../../../hooks/store';
import useCrudNotificationResponse from '../../../hooks/useCrudNotificationResponse';
import useDBTranslation            from '../../../hooks/useDBTranslation';
import { ProjectTypeEnum }         from '../../../interfaces/api/ApiProject';
import defaultCardColProps
  from '../../../shared/defaults/defaultCardColProps';
import { defaultInfoProps }                                           from '../../../shared/defaults/defaultModalProps';
import { setLoader }                                                  from '../../../store/features/uiSlice';
import CategorySelector                                               from './CategorySelector';
import StudentFormItems                                               from './StudentFormItems';
import { buildProjectFormData, ProjectCategoryFields, ProjectFields } from './helpers';
import './index.less';

const { Item, List, ErrorList } = Form;

type Props = {
  newStudent?: boolean;
  newInstitutional?: boolean;
}

const initialExpirationDate = moment(new Date().setFullYear(new Date().getFullYear() + 1));

const initialValuesInstitutional: ProjectFields = {
  title: '',
  summary: '',
  description: { html: '', text: '' },
  imageUrl: undefined,
  expirationDate: initialExpirationDate,
  student: undefined,
  categories: [],
};

const initialValuesStudent: ProjectFields = {
  ...initialValuesInstitutional,
  student: {
    id: undefined,
    firstName: '',
    lastName: '',
    birthdate: moment(),
    gradeId: '',
  },
};

const studentMaxGoal = 5000;
const institutionMaxGoal = 50000;

const listValidator: (t: TFunction) => ValidatorRule = (t) => ({
  // eslint-disable-next-line consistent-return
  validator: async (_, categories: ProjectCategoryFields[] | undefined) => {
    if (categories === undefined || categories.length < 1) {
      return Promise.reject(new Error(t('organization:projects.form.categories.empty')));
    }
  },
});

const ManageProjectPage: React.FC<Props> = ({ newStudent, newInstitutional }) => {
  const { t } = useTranslation(['common', 'organization']);
  const tDB = useDBTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = useForm<ProjectFields>();
  const { institutionId, projectId } = useParams();

  const [isStudent, setIsStudent] = useState<boolean>(true);
  const [isInstitutional, setIsInstitutional] = useState<boolean>(false);

  const { data } = useGetProjectQuery(projectId, { skip: projectId === undefined });
  const { data: types } = useGetOrganizationCategoryTypesQuery();

  const [createProject, {
    isLoading: isCreating,
    isSuccess: created,
    error: createError,
  }] = useCreateProjectMutation();

  const [updateProject, {
    isLoading: isUpdating,
    isSuccess: updated,
    error: updateError,
  }] = useUpdateProjectMutation();

  const [deleteProject, {
    isLoading: isDeleting,
    isSuccess: deleted,
    error: deleteError,
  }] = useDeleteProjectMutation();

  useCrudNotificationResponse({
    isSuccess: created,
    error: createError,
    onClose: () => navigate(generatePath(APP_PATHS.organization.institution, { institutionId })),
    successNotification: {
      message: t('organization:projects.actions.save.success.title'),
      description: t('organization:projects.actions.save.success.description'),
    },
  });
  useCrudNotificationResponse({
    isSuccess: updated,
    error: updateError,
    successNotification: {
      message: t('organization:projects.actions.update.success.title'),
      description: t('organization:projects.actions.update.success.description'),
    },
  });
  useCrudNotificationResponse({
    isSuccess: deleted,
    error: deleteError,
    onClose: () => navigate(generatePath(APP_PATHS.organization.institution, { institutionId })),
    successNotification: {
      message: t('organization:projects.actions.delete.success.title'),
      description: t('organization:projects.actions.delete.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isCreating || isUpdating || isDeleting));
  }, [dispatch, isCreating, isUpdating, isDeleting]);

  useEffect(() => {
    setIsStudent(newStudent || data?.typeId === ProjectTypeEnum.STUDENT);
    setIsInstitutional(newInstitutional || data?.typeId === ProjectTypeEnum.INSTITUTIONAL);
  }, [newStudent, newInstitutional, data]);

  useEffect(() => {
    if (data !== undefined) {
      form.setFieldsValue({
        title: data.title,
        summary: data.summary,
        description: {
          html: data.description,
          text: undefined,
        },
        imageUrl: data.imageUrl,
        expirationDate: moment(data.expirationDate, 'YYYY-MM-DD'),
        categories: data.categories.map((category) => ({
          id: category.id,
          goal: category.goal,
          raised: category.raised,
          imageUrl: category.imageUrl,
          description: {
            html: category.description,
            text: undefined,
          },
          typeId: category.typeId,
        })),
      });
      if (data.student != null) {
        const { student } = data;
        form.setFieldsValue({
          student: {
            id: student.id,
            firstName: student.firstName,
            lastName: student.lastName,
            birthdate: moment(student.birthdate, 'YYYY-MM-DD'),
            gradeId: student.gradeId,
          },
        });
      }
    }
  }, [data, form]);

  const addType = (
    typeId: string,
    add: (defaultValue?: StoreValue, insertIndex?: (number | undefined)) => void,
  ) => {
    add({
      raised: '0.00',
      typeId,
    });
    Modal.destroyAll();
  };

  const onAddType = (
    add: (defaultValue?: StoreValue, insertIndex?: (number | undefined)) => void,
  ) => {
    const categories: ProjectCategoryFields[] = form.getFieldValue('categories');
    Modal.info({
      ...defaultInfoProps,
      title: t('organization:projects.form.categories.types.title'),
      width: '65%',
      content: <CategorySelector
        selectType={ (typeId) => addType(typeId, add) }
        types={ (types ?? []).filter(({ id }) => !categories?.some(({ typeId }) => typeId === id)) }
      />,
    });
  };

  const onDelete = () => {
    if (data !== undefined) {
      Modal.info({
        ...defaultInfoProps,
        title: t('organization:projects.delete.title'),
        width: '65%',
        content: <DeleteProjectForm
          entity={ data }
          onDelete={ deleteProject }
        />,
      });
    }
  };

  const handleFinish = (fields: ProjectFields) => {
    const totalGoal = fields.categories
      .map((category) => category.goal)
      .reduce((prev, current) => prev + current);

    if (isStudent && totalGoal > studentMaxGoal) {
      notification.warn({
        message: t('organization:projects.form.categories.max_goal.title'),
        description: t('organization:projects.form.categories.max_goal.description_student') + studentMaxGoal,
      });
      return;
    }
    if (isInstitutional && totalGoal > institutionMaxGoal) {
      notification.warn({
        message: t('organization:projects.form.categories.max_goal.title'),
        description: t('organization:projects.form.categories.max_goal.description_institution') + institutionMaxGoal,
      });
      return;
    }

    const formData = buildProjectFormData(fields);

    if (data === undefined) {
      if (institutionId !== undefined) {
        formData.append('institution_id', institutionId.toString());

        const studentId = form.getFieldValue('student').id;
        if (studentId !== undefined && fields.student !== undefined) {
          formData.append('student[id]', studentId);
        }
        createProject(formData);
      }
    } else {
      updateProject({
        id: data.id,
        body: formData,
      });
    }
  };

  return (
    <Row justify='center'>
      <Col {...defaultCardColProps}>
        <Card id='create-project-form'>
          <h1>
            {
              newStudent && t('organization:projects.titles.create_student')
            }
            {
              newInstitutional && t('organization:projects.titles.create_institutional')
            }
            {
              data?.typeId === ProjectTypeEnum.STUDENT && t('organization:projects.titles.update_student')
            }
            {
              data?.typeId === ProjectTypeEnum.INSTITUTIONAL && t('organization:projects.titles.update_institutional')
            }
          </h1>
          <Form<ProjectFields>
            form={ form }
            className='mt-10'
            layout='vertical'
            name='frmProject'
            requiredMark={ false }
            initialValues={ isInstitutional ? initialValuesInstitutional : initialValuesStudent }
            onFinish={ handleFinish }
          >
            {
              isStudent && <StudentFormItems isNew={newStudent} form={form} />
            }
            <Row gutter={ [0, 24] }>
              <Col span={ 24 }>
                <Item
                  label={ t('organization:projects.form.title.label') }
                  name='title'
                  rules={ [
                    {
                      required: true,
                      message: t('organization:projects.form.title.required'),
                    },
                  ] }
                >
                  <Input
                    name='title'
                    placeholder={ t('organization:projects.form.title.placeholder') }
                  />
                </Item>
              </Col>
              <Col span={ 24 }>
                <Item
                  label={ t('organization:projects.form.expiration_date.label') }
                  name='expirationDate'
                  rules={ [
                    {
                      required: true,
                      message: t('organization:projects.form.expiration_date.required'),
                    },
                  ] }
                >
                  <DatePicker
                    name='expirationDate'
                    disabledDate={ (d) => !d || d.isBefore(moment()) }
                  />
                </Item>
              </Col>
              <Col span={ 24 }>
                <Item
                  name='imageUrl'
                  label={ t('organization:projects.form.image_url.label') }
                  rules={ [ensureIsValidImage(t)] }
                >
                  <ImageField />
                </Item>
              </Col>
              <Col span={ 24 }>
                <Item
                  label={ t('organization:projects.form.summary.label') }
                  name='summary'
                  rules={ [
                    {
                      required: true,
                      message: t('organization:projects.form.summary.required'),
                    },
                  ] }
                >
                  <TextArea
                    name='summary'
                    placeholder={ t('organization:projects.form.summary.placeholder') }
                  />
                </Item>
              </Col>
              <Col span={ 24 }>
                <Item
                  label={ t('organization:projects.form.description.label') }
                  name='description'
                  rules={ [
                    { required: true, message: t('organization:projects.form.description.required') },
                  ] }
                >
                  <WYGIWYSField
                    showCount
                    placeholder={ t('organization:projects.form.description.placeholder') }
                  />
                </Item>
              </Col>
              <Col span={ 24 }>
                <List name='categories' rules={[listValidator(t)]}>
                  { (categories, { add, remove }, { errors }) => (
                    <Row>
                      <Col span={ 24 }>
                        <h2 className='category-title'>
                          <span>{ t('organization:projects.form.categories.title') }</span>
                          <Button
                            type='primary'
                            className='add-btn'
                            onClick={ () => onAddType(add) }
                            disabled={ isInstitutional && form.getFieldValue('categories').length > 0 }
                          >
                            { t('common:add') }
                            <PlusCircle />
                          </Button>
                        </h2>
                      </Col>
                      {
                        categories.length === 0 && (
                          <Col span={24}>
                            <Empty
                              description={ t('organization:projects.form.categories.empty') }
                            />
                          </Col>
                        )
                      }
                      {
                        categories.map((field, i) => (
                          <Col key={ field.key } span={ 24 } className='category-card'>
                            <h4 className='category-title'>
                              <span>{ tDB(types?.find(({ id }) => form.getFieldValue('categories')[i].typeId === id), 'name') }</span>
                              {
                                form.getFieldValue('categories')[i].raised === '0.00'
                                && (
                                  <Button
                                    danger
                                    type='text'
                                    icon={<Trash2 />}
                                    onClick={ () => remove(i) }
                                  />
                                )
                              }
                            </h4>
                            <div className='category-content mb-8'>
                              <Item
                                label={ t('organization:projects.form.categories.goal.title') }
                                name={ [i, 'goal'] }
                                rules={ [
                                  {
                                    required: true,
                                    message: t('organization:projects.form.categories.goal.required'),
                                  },
                                ] }
                              >
                                <InputNumber
                                  type='number'
                                  min={ 1 }
                                  max={ isStudent ? studentMaxGoal : institutionMaxGoal }
                                  precision={ 2 }
                                  step={ 0.01 }
                                  placeholder={ t('organization:projects.form.categories.goal.placeholder') }
                                />
                              </Item>
                              <Item
                                name={ [i, 'imageUrl'] }
                                label={ t('organization:projects.form.image_url.label') }
                                rules={ [ensureIsValidImage(t)] }
                              >
                                <ImageField />
                              </Item>
                              <Item
                                label={ t('organization:projects.form.description.label') }
                                name={ [i, 'description'] }
                                rules={ [
                                  { required: true, message: t('organization:projects.form.description.required') },
                                ] }
                              >
                                <WYGIWYSField
                                  showCount
                                  placeholder={ t('organization:projects.form.description.placeholder') }
                                />
                              </Item>
                            </div>
                          </Col>
                        ))
                      }
                      {
                        errors.length !== 0
                        && (
                          <Col span={ 24 }>
                            <ErrorList errors={ errors } />
                          </Col>
                        )
                      }
                    </Row>
                  )}
                </List>
              </Col>
              <Col span={24}>
                <Item>
                  <Button
                    block
                    type='primary'
                    size='large'
                    htmlType='submit'
                  >
                    { t('common:save') }
                  </Button>
                </Item>
              </Col>
            </Row>
          </Form>
          {
            data !== undefined && (
              <>
                <br />
                <br />
                <Button
                  block
                  danger
                  onClick={ onDelete }
                >
                  { t('organization:projects.delete.title') }
                </Button>
              </>
            )
          }
        </Card>
      </Col>
    </Row>
  );
};

export default ManageProjectPage;
