import { Button }             from 'antd';
import React                  from 'react';
import { useTranslation }     from 'react-i18next';
import { Link }               from 'react-router-dom';
import { PosterVideo, Video } from '../../../../../constants/assets.constants';

import './index.less';
import APP_PATHS from '../../../../../constants/routes.constants';

export const HomeBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='home-banner'>
      <div className='home-banner--body__container container mx-auto'>
        <div className='home-banner--body__title text-white mb-1'>
          { t('public:banner.title') }
        </div>
        <div className='home-banner--body__subtitle text-white mb-4'>
          { t('public:banner.sub_title') }
        </div>
        <div className='home-banner--body__action'>
          <Link to={APP_PATHS.public.discover}>
            <Button type='primary'>
              { t('public:banner.action') }
            </Button>
          </Link>
        </div>

        <div className='home-banner--body__card mx-auto'>
          <div className='home-banner--body__card--video'>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */ }
            <video
              controls
              height='100%'
              poster={ PosterVideo }
            >
              <source src={ Video } />
              { t('public:banner.video.not_supported') }
            </video>
          </div>
          <div className='home-banner--body__card--text'>
            <div className='text-h3 mb-2'>
              { t('public:banner.video.title') }
            </div>
            <div className='text-body1 text-gray'>
              { t('public:banner.video.description') }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
