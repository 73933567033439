import { generatePath }                  from 'react-router-dom';
import { BLOG_ENTRIES_TAG, LIST_ID_TAG } from '../../core/constants/api.tags.constants';
import { BLOG_ENTRIES_URIS }             from '../../core/constants/api.uris.constants';
import ApiBlogEntry                      from '../../core/interfaces/api/ApiBlogEntry';
import ListData                          from '../../core/interfaces/general/ListData';
import PageableInputType                 from '../../core/shared/utils/pageableInputType';
import serializeObjectToQueryParams      from '../../core/shared/utils/serializeObjectToQueryParams';
import fundEducationApi                  from '../apis/fundEducation.api';

export type BlogEntryFilters = {
  title?: string;
  status?: number;
}

type GetBlogEntriesInputType = {
  filters?: BlogEntryFilters;
} & PageableInputType;

export const blogEntriesApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedBlogEntries: build.query<ListData<ApiBlogEntry>, GetBlogEntriesInputType>({
      query: ({
        page = 1,
        perPage = 12,
        filters = {},
      }) => `${BLOG_ENTRIES_URIS.root}?page=${page}&perPage=${perPage}${serializeObjectToQueryParams(filters)}`,
      providesTags: (result) => (
        result
          ? [
            ...result.data.map(({ id }) => ({ type: BLOG_ENTRIES_TAG, id } as const)),
            { type: BLOG_ENTRIES_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: BLOG_ENTRIES_TAG, id: LIST_ID_TAG }]
      ),
    }),
    createBlogEntry: build.mutation<null, FormData>({
      query: (body) => ({
        url: BLOG_ENTRIES_URIS.root,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: BLOG_ENTRIES_TAG, id: LIST_ID_TAG }],
    }),
    updateBlogEntry: build.mutation<null, { id: string; body: FormData }>({
      query: ({ id, body }) => {
        body.append('_method', 'PUT');

        return {
          url: generatePath(BLOG_ENTRIES_URIS.action, { id }),
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: BLOG_ENTRIES_TAG, id }],
    }),
    deleteBlogEntry: build.mutation<null, ApiBlogEntry>({
      query: (payload) => ({
        url: generatePath(BLOG_ENTRIES_URIS.action, { id: payload.id }),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: BLOG_ENTRIES_TAG, id }],
    }),
    publishBlogEntry: build.mutation<null, ApiBlogEntry>({
      query: (payload) => ({
        url: generatePath(BLOG_ENTRIES_URIS.publish, { id: payload.id }),
        method: 'PUT',
      }),
      invalidatesTags: () => [{ type: BLOG_ENTRIES_TAG, id: LIST_ID_TAG }],
    }),
    unpublishBlogEntry: build.mutation<null, ApiBlogEntry>({
      query: (payload) => ({
        url: generatePath(BLOG_ENTRIES_URIS.unpublish, { id: payload.id }),
        method: 'PUT',
      }),
      invalidatesTags: () => [{ type: BLOG_ENTRIES_TAG, id: LIST_ID_TAG }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPaginatedBlogEntriesQuery,
  useCreateBlogEntryMutation,
  useUpdateBlogEntryMutation,
  useDeleteBlogEntryMutation,
  usePublishBlogEntryMutation,
  useUnpublishBlogEntryMutation,
} = blogEntriesApi;
