export const BLOG_ENTRIES_URIS = {
  root: '/admin/blogs/entries',
  action: '/admin/blogs/entries/:id',
  publish: '/admin/blogs/entries/:id/publish',
  unpublish: '/admin/blogs/entries/:id/unpublish',
};

export const BLOG_TYPES_URIS = {
  root: '/admin/blogs/types',
};

export const BLOG_TAGS_URIS = {
  root: '/admin/blogs/tags',
  action: '/admin/blogs/tags/:id',
};

export const EXAMPLES_URIS = {
  root: '/admin/example',
  action: '/admin/example/:id',
};

export const ORGANIZATION_PETITIONS_URIS = {
  root: '/admin/organizations/petitions',
  approve: '/admin/organizations/petitions/:id/approve',
  reject: '/admin/organizations/petitions/:id/reject',
};

export const ORGANIZATION_URIS = {
  root: '/admin/organizations',
  delete: '/admin/organizations/:id',
  organization: '/organization',
  petition: '/organization/petition',
  message: '/organization/petition/message',
  documents: '/organization/petition/documents',
  complete: '/organization/petition/complete',
};

export const INSTITUTIONS_URIS = {
  root: '/organization/institutions',
  action: '/organization/institutions/:id',
  find: '/public/institutions/:id',
};

export const COUNTRIES_URIS = {
  root: '/public/countries',
};

export const PROJECTS_URIS = {
  publicRoot: '/public/projects',
  publicAction: '/public/projects/:id',
  organizationRoot: '/organization/projects',
  organizationAction: '/organization/projects/:id',
  schoolInfo: '/public/projects/:projectId/school-info',
  updates: '/public/projects/:projectId/updates',
};

export const PROJECT_UPDATES_URIS = {
  root: '/organization/projects/updates',
  action: '/organization/projects/updates/:id',
};

export const DONATIONS_URIS = {
  donations: '/public/projects/:id/donations',
  sponsorCount: '/public/projects/:id/sponsor-count',
  donationInfo: '/public/donations/:id',
  donate: '/public/donate',
  chargedAmount: '/public/donate/amount',
  adminDonations: '/admin/donations',
  refund: '/admin/donations/:id/refund',
};

export const PROJECT_CATEGORY_TYPES_URI = {
  organizationRoot: '/organization/projects/category-types',
  root: 'admin/project-category-types',
  action: 'admin/project-category-types/:id',
};

export const GRADES_URI = {
  root: 'admin/grades',
  action: 'admin/grades/:id',
  organizationRoot: '/organization/institutions/:id/grades',
};

export const STUDENTS_URI = {
  available: '/organization/institutions/:id/students/available',
};

export const REPORT_CARDS_URI = {
  root: '/organization/projects/:id/report-cards',
  create: '/organization/report-cards',
  action: '/organization/report-cards/:id',
  latest: '/public/projects/:id/report-cards/latest',
};

export const FILES_URIS = {
  petitionDocuments: '/files/organizations/petitions/documents/:id',
  deletionJustifications: '/files/projects/justifications/:id',
};

export const USERS_URIS = {
  counts: '/admin/users/count',
  type: '/admin/user-types/:id/users',
  delete: '/admin/users/:id',
};

export const PROFILE_URIS = {
  root: '/common/profile',
  password: '/common/profile/password',
};

export const HOME_URIS = {
  blogEntries: '/public/blogs/:typeId',
  blogEntry: '/public/blogs/entry/:id',
  blogEntryRelated: '/public/blogs/entry/:id/related',
  platformCount: '/public/platform-count',
};

export const SPONSOR_PROJECTS_URIS = {
  root: '/sponsor/projects',
  setReminder: '/sponsor/projects/:id/reminder/set',
  unsetReminder: '/sponsor/projects/:id/reminder/unset',
};

export const SIGNUP_URIS = {
  emailCheck: '/public/email-check',
  signup: '/public/signup',
};
