import {
  Button, Col, Empty, Form, FormInstance, Input, InputNumber, Row,
}                                          from 'antd';
import { ValidatorRule }                   from 'rc-field-form/lib/interface';
import { useEffect }                       from 'react';
import * as React                          from 'react';
import { Trash2 }                          from 'react-feather';
import { TFunction, useTranslation }       from 'react-i18next';
import { OutlinedButton }                  from '../../../../../../components/buttons';
import { ApiReportCard, ReportCardFields } from '../../../../../interfaces/api/ApiReportCard';
import { ProjectCategoryFields }           from '../../../ManageProjectPage/helpers';
import './index.less';

const { List, Item } = Form;

const initialValues: ReportCardFields = {
  scores: [],
};

export type ReportCardsCallback = (fields: ReportCardFields, entity?: ApiReportCard) => void;

type Props = {
  entity?: ApiReportCard;
  previousReport?: ApiReportCard;
  onFinish?: ReportCardsCallback;
  form: FormInstance<ReportCardFields>;
  isReadOnly?: boolean;
}

const listValidator: (t: TFunction) => ValidatorRule = (t) => ({
  // eslint-disable-next-line consistent-return
  validator: async (_, categories: ProjectCategoryFields[] | undefined) => {
    if (categories === undefined || categories.length < 1) {
      return Promise.reject(new Error(t('organization:report_cards.form.empty')));
    }
  },
});

export const ReportCardForm: React.FC<Props> = ({
  entity,
  previousReport,
  form,
  onFinish,
  isReadOnly = false,
}) => {
  const { t } = useTranslation(['common', 'organization']);

  useEffect(() => {
    if (previousReport !== undefined) {
      form.setFieldsValue({
        scores: previousReport.scores.map((score) => ({
          subject: score.subject,
        })),
      });
    }
    if (entity !== undefined) {
      form.setFieldsValue({
        projectId: entity.projectId,
        scores: entity.scores.map((score) => ({
          id: score.id,
          subject: score.subject,
          score: score.score,
        })),
      });
    }
  }, [form, entity]);

  return (
    <Form<ReportCardFields>
      form={ form }
      layout='vertical'
      name='frmReportCard'
      requiredMark={ false }
      initialValues={ initialValues }
      className={ `report-card ${isReadOnly ? 'report-card-readonly' : ''}`}
      onFinish={ (v) => (onFinish ? onFinish(v, entity) : null)}
    >
      <List name='scores' rules={[listValidator(t)]}>
        { (scores, { add, remove }) => (
          <Row gutter={ [0, 24] }>
            {
                !isReadOnly && (
                  <Col span={24}>
                    <OutlinedButton onClick={ add }>
                      { t('organization:report_cards.form.add') }
                    </OutlinedButton>
                  </Col>
                )
              }
            {
                scores.length === 0 && (
                  <Col span={24}>
                    <Empty
                      description={ t('organization:report_cards.form.empty') }
                    />
                  </Col>
                )
              }
            {
                scores.map((field, i) => (
                  <Col span={24}>
                    <Row key={ field.key } className='subject-item'>
                      <Col span={24}>
                        <Item
                          label={(
                            <>
                              <span>
                                { t('organization:report_cards.form.subject.title') }
                              </span>
                              {
                                !isReadOnly && (
                                  <Button
                                    danger
                                    type='text'
                                    icon={<Trash2 />}
                                    onClick={ () => remove(i) }
                                  />
                                )
                              }
                            </>
                          )}
                          name={ [i, 'subject'] }
                          rules={ [
                            {
                              required: true,
                              message: t('organization:report_cards.form.subject.required'),
                            },
                          ] }
                        >
                          <Input
                            placeholder={ t('organization:report_cards.form.subject.placeholder') }
                          />
                        </Item>
                      </Col>
                      <Col span={24}>
                        <Item
                          label={ t('organization:report_cards.form.score.title') }
                          name={ [i, 'score'] }
                          rules={ [
                            {
                              required: true,
                              message: t('organization:report_cards.form.score.required'),
                            },
                          ] }
                        >
                          <InputNumber
                            type='number'
                            min={ 0 }
                            max={ 10 }
                            precision={ 2 }
                            step={ 0.01 }
                            placeholder={ t('organization:report_cards.form.score.placeholder') }
                          />
                        </Item>
                      </Col>
                    </Row>
                  </Col>
                ))
              }
            {
                !isReadOnly && (
                  <Col span={24}>
                    <Item>
                      <Button
                        block
                        type='primary'
                        size='large'
                        htmlType='submit'
                        disabled={scores.length === 0}
                      >
                        { t('common:save') }
                      </Button>
                    </Item>
                  </Col>
                )
              }
          </Row>
        )}
      </List>
    </Form>
  );
};
