import * as React             from 'react';
import { ColumnsType }        from 'antd/lib/table/interface';
import { Trans }              from 'react-i18next';
import ApiProjectCategoryType from '../../../interfaces/api/ApiProjectCategoryType';

const columns: ColumnsType<ApiProjectCategoryType> = [
  {
    title: () => <Trans i18nKey='admin:projects.category_types.form.name.label' />,
    dataIndex: 'name',
  },
  {
    title: () => <Trans i18nKey='admin:projects.category_types.form.name_en.label' />,
    dataIndex: 'nameEn',
  },
];

export default columns;
