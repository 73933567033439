function serializeObjectToQueryParams(
  obj: { [k: string]: number | string | string[] | number[] | undefined },
  prependAmpersand = true,
) {
  const str: string[] = [];
  Object.keys(obj).forEach((p) => {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      const element = obj[p];

      if (element !== undefined) {
        if (Array.isArray(element)) {
          element.forEach((value) => str.push(`${encodeURIComponent(p)}[]=${encodeURIComponent(value)}`));
        } else {
          str.push(`${encodeURIComponent(p)}=${encodeURIComponent(element)}`);
        }
      }
    }
  });
  const prefix = prependAmpersand ? '&' : '';
  return str.length > 0 ? prefix + str.join('&') : '';
}

export default serializeObjectToQueryParams;
