import React, { useEffect }        from 'react';
import { useTranslation }          from 'react-i18next';
import { generatePath, Link }      from 'react-router-dom';
import { Plus }                    from 'react-feather';
import {
  Button, Card, Col, Modal, Row,
}                                         from 'antd';
import {
  useDeleteInstitutionMutation,
  useGetInstitutionsQuery,
}                                         from '../../../../adapters/services/institutions.service';
import { useGetOrganizationQuery }        from '../../../../adapters/services/organizations.service';
import AlertError                         from '../../../../components/Alerts/AlertError';
import { EditCircleButton }               from '../../../../components/buttons';
import APP_PATHS                          from '../../../constants/routes.constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import useCrudNotificationResponse        from '../../../hooks/useCrudNotificationResponse';
import { ApiInstitution }                 from '../../../interfaces/api/ApiInstitution';
import { defaultConfirmProps }            from '../../../shared/defaults/defaultModalProps';
import { setLoader }                      from '../../../store/features/uiSlice';

const ListInstitutionsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { petitionStatus } = useAppSelector((state) => state.org);
  const { data } = useGetInstitutionsQuery();

  const { data: organization, isLoading, isFetching } = useGetOrganizationQuery();
  const [deleteInstitution, {
    isLoading: isDeleting,
    isSuccess: deletedInstitution,
    error: institutionError,
  }] = useDeleteInstitutionMutation();

  const onDeleteInstitution = ({ id }: ApiInstitution) => {
    Modal.confirm({
      ...defaultConfirmProps,
      title: t('organization:institutions.actions.delete.confirm'),
      onOk: () => deleteInstitution(id),
      width: '50%',
    });
  };

  useCrudNotificationResponse({
    isSuccess: deletedInstitution,
    error: institutionError,
    successNotification: {
      message: t('organization:institutions.actions.delete.success.title'),
      description: t('organization:institutions.actions.delete.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isDeleting));
  }, [dispatch, isDeleting]);

  return (
    <Row justify='center'>
      <Col xs={24} md={20} xl={14}>
        <Row gutter={[0, 16]}>
          {
            petitionStatus.pending && (
              <Col span={24}>
                <AlertError title={ t('organization:institutions.verify_account.title') }>
                  { t('organization:institutions.verify_account.description') }
                </AlertError>
              </Col>
            )
          }
          <Col span={24}>
            <Card loading={isLoading || isFetching}>
              <div className='d-flex justify-space-between align-center mb-8 text-break-word'>
                <div className='text-h2'>{ organization?.name }</div>
                {
                  petitionStatus.approved && (
                    <Link to={ APP_PATHS.organization.edit }>
                      <EditCircleButton />
                    </Link>
                  )
                }
              </div>

              <Button type='primary' block disabled={!petitionStatus.approved}>
                <Link to={ APP_PATHS.organization.createInstitution }>
                  <div className='d-flex justify-center align-center'>
                    <Plus className='mr-2' />
                    { t('organization:institutions.actions.add_institution') }
                  </div>
                </Link>
              </Button>

              {
                data !== undefined
                && (
                  data.map((institution) => (
                    <Row align='middle' className='user-panel px-4 py-2 my-2' key={institution.id}>
                      <Col span={24}>
                        <div className='text-body-bold mb-2'>
                          <span>{ institution.name }</span>
                        </div>
                      </Col>
                      <Col>
                        <div className='d-flex'>
                          <div className='mr-4'>
                            <Link to={ generatePath(
                              APP_PATHS.organization.institution,
                              { institutionId: institution.id },
                            )}
                            >
                              <button
                                type='button'
                                className='btn-text-custom btn-text-custom-primary'
                              >
                                { t('common:see') }
                              </button>
                            </Link>
                          </div>
                          <div>
                            <button
                              type='button'
                              className='btn-text-custom btn-text-custom-secondary'
                              onClick={ () => onDeleteInstitution(institution) }
                            >
                              { t('common:delete') }
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))
                )
              }
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ListInstitutionsPage;
