import Search, { SearchProps }  from 'antd/lib/input/Search';
import React                    from 'react';
import { Search as SearchIcon } from 'react-feather';
import { useTranslation }       from 'react-i18next';
import './index.less';

export const SearchInput: React.FC<SearchProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Search
      allowClear
      className='search-input'
      suffix={ <SearchIcon /> }
      placeholder={ t('common:search') }
      { ...props }
    />
  );
};
