import { generatePath }                            from 'react-router-dom';
import { ORGANIZATION_PETITIONS_TAG, LIST_ID_TAG } from '../../core/constants/api.tags.constants';
import { ORGANIZATION_PETITIONS_URIS }             from '../../core/constants/api.uris.constants';
import { ApiOrganizationPetition }                 from '../../core/interfaces/api/ApiOrganizationPetition';
import ListData                                    from '../../core/interfaces/general/ListData';
import PageableInputType                           from '../../core/shared/utils/pageableInputType';
import serializeObjectToQueryParams                from '../../core/shared/utils/serializeObjectToQueryParams';
import fundEducationApi                            from '../apis/fundEducation.api';

export type OrganizationPetitionFilters = {
  status?: number;
}

type GetOrganizationPetitionsInputType = {
  filters?: OrganizationPetitionFilters;
} & PageableInputType;

export const organizationPetitionsApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationPetitions:
      build.query<ListData<ApiOrganizationPetition>, GetOrganizationPetitionsInputType>({
        query: ({
          page = 1,
          perPage = 12,
          filters = {},
        }) => `${ORGANIZATION_PETITIONS_URIS.root}?page=${page}&perPage=${perPage}${serializeObjectToQueryParams(filters)}`,
        providesTags: (result) => (
          result
            ? [
              ...result.data.map(({ id }) => ({ type: ORGANIZATION_PETITIONS_TAG, id } as const)),
              { type: ORGANIZATION_PETITIONS_TAG, id: LIST_ID_TAG },
            ]
            : [{ type: ORGANIZATION_PETITIONS_TAG, id: LIST_ID_TAG }]
        ),
      }),
    approveOrganizationPetition: build.mutation<null, string>({
      query: (id) => ({
        url: generatePath(ORGANIZATION_PETITIONS_URIS.approve, { id }),
        method: 'PUT',
      }),
      invalidatesTags: () => [{ type: ORGANIZATION_PETITIONS_TAG, id: LIST_ID_TAG }],
    }),
    rejectOrganizationPetition: build.mutation<null, string>({
      query: (id) => ({
        url: generatePath(ORGANIZATION_PETITIONS_URIS.reject, { id }),
        method: 'PUT',
      }),
      invalidatesTags: () => [{ type: ORGANIZATION_PETITIONS_TAG, id: LIST_ID_TAG }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetOrganizationPetitionsQuery,
  useApproveOrganizationPetitionMutation,
  useRejectOrganizationPetitionMutation,
} = organizationPetitionsApi;
