/* eslint-disable */
import {
  DeleteOutlined, EditOutlined, EllipsisOutlined, EyeOutlined,
}                                         from '@ant-design/icons';
import { Button, Space, Tooltip }         from 'antd';
import { ButtonProps }                    from 'antd/lib/button/button';
import React, { ReactElement, ReactNode } from 'react';
import { Link }                           from 'react-router-dom';
import { assertFC }                       from '../../core/shared/utils/assertFC';
import {
  ActionKeys, ActionPayload, ActionTuple, CrudActionKeys,
}                                         from './types';

type ActionsColumnProps<Entity> = {
    actions: ActionTuple<Entity>[];
    entity: Entity;
    rowIndex: number;
}

type CrudDefaultPropsRecord = Record<CrudActionKeys, ButtonProps>

const crudData: CrudDefaultPropsRecord = {
  view: {
    icon: <EyeOutlined />,
    title: 'Details',
  },
  edit: {
    icon: <EditOutlined />,
    title: 'Edit',
    className: 'btn-warning',
  },
  delete: {
    icon: <DeleteOutlined />,
    title: 'Delete',
    danger: true,
  },
};

const actionsOrder: ActionKeys[] = ['view', 'edit', 'delete', 'custom'];

type ConditionalWrapperProps = {
    children: ReactElement;
    condition: boolean;
    wrapper: (children: ReactNode) => ReactElement;
};

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children);

const returnIfFunction = (data: unknown): Function | undefined => typeof data === 'function' ? data : undefined

function ActionsColumn<E>(props: ActionsColumnProps<E>) {
  const { actions, rowIndex, entity } = props;

  const sortActions = (
    a: ActionTuple<E>,
    b: ActionTuple<E>,
  ) => actionsOrder.indexOf(a[0]) - actionsOrder.indexOf(b[0]);

  return (
    <Space>
      { [...actions].sort(sortActions).map(([actionKey, data], i) => {
        const customAction: ActionPayload<E> | null = actionKey === 'custom' ? data : null;

        const callback = customAction !== null
          ? customAction.callback
          : returnIfFunction(data);

        const buttonProps: ButtonProps | undefined = customAction !== null
          ? customAction.props
          : crudData[actionKey as CrudActionKeys];

        const link = 'asLink' in data && data.asLink ? data.getLink(entity) : undefined;
        const linkData = 'asLink' in data ? data : undefined;

        return (
          <ConditionalWrapper
            key={ i }
            condition={ link !== undefined }
            wrapper={ (c) => <Link to={ link as string } state={ linkData?.state }>{ c }</Link> }
          >
            <Tooltip title={ buttonProps?.title ?? 'Custom action' }>
              <Button
                { ...(buttonProps ?? {
                  icon: actionKey === 'custom' ? <EllipsisOutlined /> : null,
                }) }
                title={ undefined }
                size='large'
                shape='circle'
                onClick={ callback ? () => callback(entity, rowIndex) : undefined }
              />
            </Tooltip>
          </ConditionalWrapper>
        );
      }) }
    </Space>
  );
}

assertFC(ActionsColumn);

export default ActionsColumn;
