import { Col, Row }           from 'antd';
import React                  from 'react';
import { useTranslation }     from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { OutlinedButton }     from '../../../../../../components/buttons';
import ProjectCard            from '../../../../../../components/projects/ProjectCard';
import APP_PATHS              from '../../../../../constants/routes.constants';
import { ApiProject }         from '../../../../../interfaces/api/ApiProject';
import ListData               from '../../../../../interfaces/general/ListData';

import './index.less';

type Props = {
  data: ListData<ApiProject> | undefined;
}

export const HomeInstitutionalProjects: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const buildLink = (projectId: string) => generatePath(
    APP_PATHS.public.projectDetail,
    { projectId },
  );

  return (
    <>
      <p className='title-projects text-h3 mb-4 text-green'>
        { t('public:projects.institutional.title') }
      </p>
      <Row
        gutter={ [
          {
            xs: 0,
            sm: 16,
            md: 24,
          },
          {
            xs: 32,
            sm: 16,
            md: 24,
          },
        ] }
        justify='center'
      >
        {
          data?.data.map((project) => (
            <Col
              xs={ 24 }
              sm={ 12 }
              lg={ 8 }
              key={ project.id }
              className='gutter-row'
            >
              <ProjectCard project={ project } to={ buildLink(project.id) } />
            </Col>
          ))
        }
      </Row>
      <div className='mt-8'>
        <Link to={ `${generatePath(APP_PATHS.public.discover)}?type=INSTITUTIONAL` }>
          <OutlinedButton block={false} className='mx-auto'>
            { t('public:projects.see_all') }
          </OutlinedButton>
        </Link>
      </div>
    </>
  );
};
