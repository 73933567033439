import { ImageFieldValuesType } from '../../../../components/fields/ImageField';

export type UserProfileFields = {
  firstName: string;
  lastName: string;
  imageUrl: ImageFieldValuesType;
}

export const buildProfileFormData = (values: UserProfileFields) => {
  const frmData = new FormData();

  const {
    firstName, lastName, imageUrl,
  } = values;

  if (imageUrl != null && typeof imageUrl !== 'string') {
    frmData.append('image', imageUrl);
  }
  frmData.append('first_name', firstName);
  frmData.append('last_name', lastName);

  return frmData;
};
