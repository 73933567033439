import React, { useEffect, useRef }     from 'react';
import { useLocation }                  from 'react-router-dom';
import { useGetPaginatedProjectsQuery } from '../../../../adapters/services/projects.service';
import { PlatformInfo }                 from '../../../../components/PlatformInfo';
import { APP_HASHES }                   from '../../../constants/routes.constants';
import {
  HomeBanner,
  HomeHowItWorks,
  HomeInstitutionalProjects,
  PlatformCount,
  HomeStepsToRegister,
  HomeStudentProjects,
}                                       from './components';

import './style.less';

const HomePublicPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { hash } = useLocation();

  const {
    data: studentsProjects,
    isLoading: studentsLoading,
    isFetching: studentsFetching,
  } = useGetPaginatedProjectsQuery({
    perPage: 9,
    page: 1,
    filters: {
      type: 'STUDENT',
      status: 'ACTIVE',
      order: 'RANDOM',
    },
  });

  const {
    data: institutionalProjects,
    isLoading: institutionsLoading,
    isFetching: institutionalFetching,
  } = useGetPaginatedProjectsQuery({
    perPage: 6,
    page: 1,
    filters: {
      type: 'INSTITUTIONAL',
      status: 'ACTIVE',
      order: 'RANDOM',
    },
  });

  useEffect(() => {
    if (hash === APP_HASHES.howItWorks) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hash, studentsLoading, studentsFetching, institutionsLoading, institutionalFetching]);

  return (
    <>
      <div className='home-background'>
        <section>
          <HomeBanner />
        </section>

        <section className='projects container mx-auto'>
          {
            studentsProjects?.total !== 0 && (
              <div className='projects--students'>
                <HomeStudentProjects data={ studentsProjects } />
              </div>
            )
          }
          {
            institutionalProjects?.total !== 0 && (
              <div className='projects--institutional'>
                <HomeInstitutionalProjects data={ institutionalProjects } />
              </div>
            )
          }
        </section>
      </div>

      <section ref={ref} id='how-it-works' className='home--how-it-works'>
        <HomeHowItWorks />
      </section>

      <section>
        <PlatformCount />
      </section>

      <section>
        <PlatformInfo />
      </section>

      <section className='home--steps-to-register'>
        <HomeStepsToRegister />
      </section>
    </>
  );
};
export default HomePublicPage;
