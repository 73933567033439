import { DeleteOutlined }                                from '@ant-design/icons';
import {
  Button, Col, Form, FormInstance, Input, Row, Select,
} from 'antd';
import _                                         from 'lodash';
import * as React                                from 'react';
import { useEffect, useState }                   from 'react';
import { useTranslation }                        from 'react-i18next';
import ApiBlogEntry, { BlogStatusEnum }          from '../../../../core/interfaces/api/ApiBlogEntry';
import ApiBlogTag, { CreateBlogTagPayload }      from '../../../../core/interfaces/api/ApiBlogTag';
import ApiBlogType                               from '../../../../core/interfaces/api/ApiBlogType';
import ImageField, { ImageFieldValuesType }      from '../../../fields/ImageField';
import { ensureIsValidImage, validateImageSize } from '../../../fields/ImageField/ImageValidators';
import WYGIWYSField, { WYSIWYGValueType }        from '../../../fields/WYGIWYSField';
import './style.less';

const { Item } = Form;
const { Option } = Select;

export type BlogEntryFields = {
  title: string;
  body: WYSIWYGValueType;
  imageUrl: ImageFieldValuesType;
  typeId: number;
  tagsIds: string[];
}

const initialValues: BlogEntryFields = {
  title: '',
  body: { html: '', text: '' },
  imageUrl: undefined,
  typeId: 1,
  tagsIds: [],
};

export type BlogEntriesCallback = (field: BlogEntryFields, entity?: ApiBlogEntry) => void;

type Props = {
  title: string;
  entity?: ApiBlogEntry;
  onFinish: BlogEntriesCallback;
  form: FormInstance<BlogEntryFields>;
  types: ApiBlogType[];
  tags: ApiBlogTag[];
  handleCreateTag: (arg: CreateBlogTagPayload) => void;
  handleDeleteTag: (arg: string) => void;
}

const BlogEntryForm: React.FC<Props> = ({
  title,
  entity,
  form,
  onFinish, types,
  tags,
  handleCreateTag,
  handleDeleteTag,
}) => {
  const isReadOnly: boolean = entity?.blogStatusId === BlogStatusEnum.PUBLISHED;
  const { t } = useTranslation(['common', 'admin']);

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (entity !== undefined) {
      // Filtering possible deleted tags that the entity might hold
      const availableTagsIds = tags.map(({ id }) => id);
      const validEntityTags = entity.tags?.filter(({ id }) => availableTagsIds.includes(id));
      const entityTags = validEntityTags?.map((tag) => tag.id);

      form.setFieldsValue({
        title: entity.title,
        body: {
          html: entity.body,
          text: undefined,
        },
        imageUrl: entity.imageUrl,
        typeId: entity.typeId,
        tagsIds: entityTags,
      });

      setSelectedTags(entityTags ?? []);
    }
  }, [entity, form]);

  /* Every time a tag is created or deleted, this effect will immediately
  * remove the last element of selectedTags, as it is an element provided
  * automatically by the antd select component and it doesn't hold an id.
  * If it was a tag creation, the effect will add a new element to
  * selectedTags, which will be the newly created tag with its corresponding id
  * */
  useEffect(() => {
    if (selectedTags.length > 0) {
      const selectedTagsClone = _.clone(selectedTags);
      const lastTag = selectedTagsClone.pop();
      const newTag = tags.find(({ name }) => name === lastTag);
      if (newTag !== undefined) {
        selectedTagsClone.push(newTag.id);
      }
      setSelectedTags(selectedTagsClone);
      form.setFieldsValue({ tagsIds: selectedTagsClone });
    }
  }, [tags]);

  const handleTagChange = (values: string[]): void => {
    // At this point, values will hold the current state of tagsIds,
    // whereas selectedTags will hold its previous state

    if (values.length > selectedTags.length) {
      const addedTag = values.filter((tag) => !selectedTags.includes(tag))[0];

      if (tags.find((tag) => tag.id === addedTag) === undefined) {
        handleCreateTag({ name: addedTag });
      }
    }

    setSelectedTags(values);
  };

  const handleDelete = (id: string): void => {
    handleDeleteTag(id);
  };

  return (
    <>
      <span className='ant-modal-confirm-title'>{ title }</span>
      <Form<BlogEntryFields>
        form={ form }
        className='mt-10'
        layout='vertical'
        preserve={ false }
        name='frmBlogEntry'
        requiredMark={ false }
        initialValues={ initialValues }
        onFinish={ (v) => onFinish(v, entity) }
      >
        <Row gutter={ [16, 0] }>
          <Col span={ 24 }>
            <Item
              label={ t('admin:blog_entries.form.title.label') }
              name='title'
              rules={ [
                {
                  required: true,
                  message: t('admin:blog_entries.form.title.required'),
                },
              ] }
            >
              <Input
                disabled={ isReadOnly }
                name='title'
                placeholder={ t('admin:blog_entries.form.title.placeholder') }
              />
            </Item>
          </Col>
          <Col span={ 24 }>
            <Item
              name='imageUrl'
              label={ t('admin:blog_entries.form.image_url.label') }
              rules={ [ensureIsValidImage(t), validateImageSize(t, 400, 400)] }
            >
              <ImageField disabled={ isReadOnly } />
            </Item>
            <p className='text center _16'>
              { t('admin:blog_entries.form.image_url.size') }
            </p>
          </Col>
          <Col span={ 24 }>
            <Item
              label={ t('admin:blog_entries.form.body.label') }
              name='body'
              rules={ [
                { required: true, message: t('admin:blog_entries.form.body.required') },
              ] }
            >
              <WYGIWYSField
                showCount
                disabled={ isReadOnly }
                placeholder={ t('admin:blog_entries.form.body.placeholder') }
              />
            </Item>
          </Col>
          <Col span={ 24 }>
            <Item
              name='tagsIds'
              label={ t('admin:blog_entries.form.tags.label') }
            >
              <Select
                allowClear
                showSearch
                mode='tags'
                disabled={ isReadOnly }
                optionFilterProp='label'
                onChange={ handleTagChange }
                placeholder={ t('admin:blog_entries.form.tags.placeholder') }
                getPopupContainer={ (trigger) => trigger.parentNode }
              >
                {
                  tags.map(({ id, name }) => (
                    <Option key={ id } value={ id }>
                      { name }
                      {
                        !selectedTags.includes(id)
                        && (
                          <DeleteOutlined onClick={ () => handleDelete(id) } />
                        )
                      }
                    </Option>
                  ))
                }
              </Select>
            </Item>
          </Col>
          <Col span={ 24 }>
            <Item
              name='typeId'
              label={ t('admin:blog_entries.form.type.label') }
              rules={ [
                { required: true, message: t('admin:blog_entries.form.type.required') },
              ] }
            >
              <Select
                disabled={ isReadOnly }
                placeholder={ t('admin:blog_entries.form.type.placeholder') }
                getPopupContainer={ (trigger) => trigger.parentNode }
                options={ types.map(({ id, name }) => ({ value: id, label: name }))}
              />
            </Item>
          </Col>
        </Row>
        {
          entity?.blogStatusId !== BlogStatusEnum.PUBLISHED
          && (
            <Item>
              <Button
                block
                type='primary'
                size='large'
                htmlType='submit'
              >
                { t('common:save') }
              </Button>
            </Item>
          )
        }
      </Form>
    </>
  );
};

export default BlogEntryForm;
