import { PlusOutlined }                from '@ant-design/icons';
import { useForm }                     from 'antd/lib/form/Form';
import React, { useEffect, useState }  from 'react';
import {
  Button,
  Col, Empty, Modal, Row, Segmented,
}                                                    from 'antd';
import { useTranslation }            from 'react-i18next';
import {
  BlogEntryFilters,
  useGetPaginatedBlogEntriesQuery,
}                                                              from '../../../../adapters/services/blogEntries.service';
import {
  useCreateBlogTagMutation, useDeleteBlogTagMutation,
  useGetBlogTagsQuery,
}                                                              from '../../../../adapters/services/blogTags.service';
import { useGetBlogTypesQuery }                                from '../../../../adapters/services/blogTypes.service';
import BlogEntriesViewer, { EntriesActions }                   from '../../../../components/BlogEntriesViewer';
import BlogEntryForm, { BlogEntriesCallback, BlogEntryFields } from '../../../../components/forms/admin/BlogEntryForm/form';
import { SearchInput }                                         from '../../../../components/inputs';
import { useFilterSearchParams }                               from '../../../hooks/useFilterSearchParams';
import usePagination                                           from '../../../hooks/usePagination';
import ApiBlogEntry                                            from '../../../interfaces/api/ApiBlogEntry';
import { defaultConfirmProps, defaultInfoProps }               from '../../../shared/defaults/defaultModalProps';
import buildBlogEntryFormData                                  from './helpers';
import useBlogEntriesEffects                                   from './useBlogEntriesEffects';

const queryParamToInt = (queryStatus: string | null): number | undefined => (
  queryStatus !== null ? parseInt(queryStatus, 10) : undefined
);

const buildFilters = (queryTitle: string | null, queryStatus: string | null): BlogEntryFilters => ({
  title: queryTitle ?? undefined,
  status: queryParamToInt(queryStatus),
});

const ListBlogEntriesPage = () => {
  const { t } = useTranslation(['common', 'admin']);

  const { page, setQueryPage } = usePagination();
  const [form] = useForm<BlogEntryFields>();
  const { searchParams, onFilter } = useFilterSearchParams();
  const queryTitle = searchParams.get('title');
  const queryStatus = searchParams.get('status');
  const [filters, setFilters] = useState<BlogEntryFilters>(buildFilters(queryTitle, queryStatus));
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<ApiBlogEntry | undefined>();

  const { data, isLoading, isFetching } = useGetPaginatedBlogEntriesQuery({ page, filters });

  const { data: types } = useGetBlogTypesQuery();
  const { data: tags } = useGetBlogTagsQuery();

  const [createTag] = useCreateBlogTagMutation();
  const [deleteTag] = useDeleteBlogTagMutation();

  const onClose = () => {
    setIsModalVisible(false);
    setSelectedEntity(undefined);
  };

  const {
    createEntry, deleteEntry, publishEntry, unpublishEntry, updateEntry,
  } = useBlogEntriesEffects({
    t,
    form,
    onClose,
  });

  useEffect(() => {
    if (queryStatus === null) {
      // Published entries will be shown by default
      searchParams.set('status', '2');
    }
    setFilters(buildFilters(queryTitle, queryStatus));
  }, [queryTitle, queryStatus]);

  const handleFinish: BlogEntriesCallback = (fields, entry) => {
    const formData = buildBlogEntryFormData(fields);

    if (entry === undefined) {
      createEntry(formData);
    } else {
      updateEntry({
        id: entry.id,
        body: formData,
      });
    }
  };

  const onSave = () => {
    setIsModalVisible(true);
  };

  const onEdit = (entry: ApiBlogEntry) => {
    setIsModalVisible(true);
    setSelectedEntity(entry);
  };

  const onDelete = (entry: ApiBlogEntry) => Modal.confirm({
    ...defaultConfirmProps,
    title: t('admin:blog_entries.actions.delete.confirm'),
    onOk: () => deleteEntry(entry),
  });

  const onPublish = (entry: ApiBlogEntry) => Modal.confirm({
    ...defaultConfirmProps,
    title: t('admin:blog_entries.actions.publish.confirm'),
    onOk: () => publishEntry(entry),
  });

  const onUnpublish = (entry: ApiBlogEntry) => Modal.confirm({
    ...defaultConfirmProps,
    title: t('admin:blog_entries.actions.unpublish.confirm'),
    onOk: () => unpublishEntry(entry),
  });

  const actions: EntriesActions = {
    onEdit, onDelete, onPublish, onUnpublish,
  };

  const statusOptions = [
    {
      label: t('admin:blog_entries.published'),
      value: 2,
    },
    {
      label: t('admin:blog_entries.drafts'),
      value: 1,
    },
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={ 24 }>
          <Button
            size='large'
            type='primary'
            icon={ <PlusOutlined /> }
            onClick={ () => onSave() }
          >
            { t('admin:blog_entries.actions.new') }
          </Button>
        </Col>
        <Col span={ 24 }>
          <Segmented
            block
            options={ statusOptions }
            defaultValue={ queryParamToInt(queryStatus) }
            onChange={(value) => onFilter(queryStatus, 'status', value.toString())}
          />
        </Col>
        <Col span={ 24 }>
          <SearchInput
            loading={ isLoading || isFetching }
            defaultValue={ queryTitle ?? undefined }
            onSearch={(value) => onFilter(queryTitle, 'title', value)}
          />
        </Col>
        <Col span={ 24 }>
          <BlogEntriesViewer
            dataSource={ data }
            actions={ actions }
            loading={ isLoading || isFetching }
            paginationData={ {
              onChange: setQueryPage,
              current: page,
              pageSize: 12,
              total: data?.total,
            } }
          />
          { !isFetching && data?.data.length === 0 && <Empty description={ t('admin:blog_entries.not_found') } /> }
        </Col>
      </Row>
      <Modal
        destroyOnClose
        footer={ null }
        transitionName=''
        onCancel={ onClose }
        { ...defaultInfoProps }
        visible={ isModalVisible }
      >
        <BlogEntryForm
          form={ form }
          tags={ tags ?? [] }
          types={ types ?? [] }
          onFinish={ handleFinish }
          entity={ selectedEntity }
          handleCreateTag={ createTag }
          handleDeleteTag={ deleteTag }
          title={ selectedEntity === undefined
            ? t('admin:blog_entries.actions.new')
            : t('admin:blog_entries.actions.update')}
        />
      </Modal>
    </>
  );
};

export default ListBlogEntriesPage;
