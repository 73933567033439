import React from 'react';

import './index.less';

type Props = {
  title: string;
};

const AlertError: React.FC<Props> = ({ children, title }) => (
  <div className='verification-banner mb-4 text-center'>
    <div className='verification-banner__title pa-2 text-body-bold'>{ title }</div>
    <div className='verification-banner__description px-2 pb-2 text-body1'>
      { children }
    </div>
  </div>
);

export default AlertError;
