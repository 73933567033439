import React                            from 'react';
import { Button }                       from 'antd';
import parse                            from 'html-react-parser';
import { useTranslation }               from 'react-i18next';
import { Link }                         from 'react-router-dom';
import { useGetBlogEntriesByTypeQuery } from '../../../../../../adapters/services/blogEntriesPublic.service';
import LoadingCardRow                   from '../../../../../../components/LoadingCardRow';
import { HowItWorksImages }             from '../../../../../constants/assets.constants';
import APP_PATHS                        from '../../../../../constants/routes.constants';
import { BlogTypeEnum }                 from '../../../../../interfaces/api/ApiBlogEntry';

import './index.less';

const perPage = 3;
const { HOME } = BlogTypeEnum;

export const HomeHowItWorks: React.FC = () => {
  const { t } = useTranslation();

  const { data: blogEntries, isLoading } = useGetBlogEntriesByTypeQuery({
    typeId: HOME.toString(),
    page: 1,
    perPage,
  });

  return (
    <div className='how-works container mx-auto'>
      <div className='how-works--title text-center text-primary mb-6'>
        { t('public:how_it_works.title') }
      </div>
      <div className='how-works how-works--blog-entries'>
        {
          isLoading
            ? <LoadingCardRow count={3} />
            : blogEntries?.data.map((blogEntry) => (
              <div className='how-works--blog-entry mb-8' key={blogEntry.id}>
                <div className='how-works--blog-entry__image mb-4'>
                  <img src={blogEntry.imageUrl} alt={blogEntry.title} />
                </div>
                <div className='how-works--blog-entry__title text-h4 text-black mb-2'>
                  { blogEntry.title }
                </div>
                <div className='how-works--blog-entry__body text-body1 text-black-surface'>
                  { parse(blogEntry.body) }
                </div>
              </div>
            ))
        }
      </div>
      <div className='how-works how-works--information'>
        <div className='how-works how-works--information__section-1'>
          <div className='how-works how-works--information__image mb-6'>
            <img src={HowItWorksImages['image-1']} alt='how it works 1' />
          </div>
          <div>
            <div className='text-h2 mb-6'>
              { t('public:how_it_works.information.title') }
            </div>
            <div className='how-works how-works--information__text-p1-1 text-body1 text-black-surface'>
              { t('public:how_it_works.information.paragraphs_1.part_1') }
            </div>
            <div className='how-works how-works--information__text-p1-2 text-body1 text-black-surface'>
              { t('public:how_it_works.information.paragraphs_1.part_2') }
            </div>
          </div>
        </div>
        <div className='how-works how-works--information__section-2'>
          <div className='how-works how-works--information__image'>
            <img src={HowItWorksImages['image-2']} alt='how it works 2' />
          </div>
          <div className='how-works how-works-information__text'>
            <div className='how-works how-works--information__text-p2-1 text-body1 text-black-surface mb-8'>
              { t('public:how_it_works.information.paragraphs_2.part_1') }
            </div>
            <div className='how-works how-works--information__text-p2-2 text-body1 text-black-surface mb-8'>
              { t('public:how_it_works.information.paragraphs_2.part_2') }
            </div>
            <div className='how-works how-works--information__action'>
              <Link to={APP_PATHS.public.discover}>
                <Button type='primary'>
                  { t('public:how_it_works.information.action') }
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
