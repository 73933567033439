import React                           from 'react';
import { Card, Progress }              from 'antd';
import parse                           from 'html-react-parser';
import { useTranslation }              from 'react-i18next';
import { useNavigate }                 from 'react-router-dom';
import useConvertCurrency              from '../../../core/hooks/useConvertCurrency';
import { ApiProject, ProjectTypeEnum } from '../../../core/interfaces/api/ApiProject';
import './index.less';

interface Props {
  project: ApiProject;
  to: string;
  animation?: boolean;
}

const ProjectCard: React.FC<Props> = ({
  project, to, animation = false, children,
}) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const convert = useConvertCurrency();

  return (
    <Card
      hoverable
      className={`project-card ${project.statusId === 1 ? '' : 'project-deleted'} ${animation ? 'project-card-animation' : ''}`}
      cover={
        <img alt={ project.title } src={ project.imageUrl } />
      }
      onClick={ () => navigate(to) }
    >
      <p className='text-bold'>
        { project.title }
      </p>
      <div className='text-body1 body-container'>
        { parse(project.description) }
      </div>
      <div>
        <span className='goal-message'>
          {
            t(project.typeId === ProjectTypeEnum.STUDENT
              ? 'common:projects.card.student_goal'
              : 'common:projects.card.institution_goal')
          }
        </span>
        <Progress
          showInfo={ false }
          percent={ (project.raised / project.goal) * 100 }
          strokeColor={ project.typeId === ProjectTypeEnum.STUDENT
            ? 'var(--fund-education-primary-color)'
            : 'var(--fund-education-green)'}
        />
        <p className='text-body1 progress-message'>
          <b>
            { `${convert(project.raised)} ${t('common:projects.card.raised')} `}
          </b>
          { convert(project.goal) }
        </p>
        <div>
          { children }
        </div>
      </div>
    </Card>
  );
};

export default ProjectCard;
