import { generatePath } from 'react-router-dom';
import {
  LATEST_REPORT_TAG,
  LIST_ID_TAG,
  REPORT_CARDS_TAG,
} from '../../core/constants/api.tags.constants';
import { REPORT_CARDS_URI }                from '../../core/constants/api.uris.constants';
import { ApiReportCard, ReportCardFields } from '../../core/interfaces/api/ApiReportCard';
import fundEducationApi                    from '../apis/fundEducation.api';

export const reportCardsApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getReportCards: build.query<ApiReportCard[], string | undefined>({
      query: (projectId) => generatePath(REPORT_CARDS_URI.root, { id: projectId }),
      providesTags: (result) => (
        result
          ? [
            ...result.map(({ id }) => ({ type: REPORT_CARDS_TAG, id } as const)),
            { type: REPORT_CARDS_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: REPORT_CARDS_TAG, id: LIST_ID_TAG }]
      ),
    }),
    createReportCard: build.mutation<null, ReportCardFields>({
      query: (body) => ({
        url: REPORT_CARDS_URI.create,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, _error, { projectId }) => [
        { type: REPORT_CARDS_TAG, id: LIST_ID_TAG },
        { type: LATEST_REPORT_TAG, id: projectId },
      ],
    }),
    updateReportCard: build.mutation<null, ReportCardFields>({
      query: ({ id, ...payload }) => ({
        url: generatePath(REPORT_CARDS_URI.action, { id }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_result, _error, { id, projectId }) => [
        { type: REPORT_CARDS_TAG, id },
        { type: LATEST_REPORT_TAG, id: projectId },
      ],
    }),
    deleteReportCard: build.mutation<null, ApiReportCard>({
      query: (payload) => ({
        url: generatePath(REPORT_CARDS_URI.action, { id: payload.id }),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id, projectId }) => [
        { type: REPORT_CARDS_TAG, id },
        { type: LATEST_REPORT_TAG, id: projectId },
      ],
    }),
    getLatestReportCard: build.query<ApiReportCard, string | undefined>({
      query: (projectId) => generatePath(REPORT_CARDS_URI.latest, { id: projectId }),
      providesTags: (_result, _error, projectId) => [{ type: LATEST_REPORT_TAG, id: projectId }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetReportCardsQuery,
  useCreateReportCardMutation,
  useUpdateReportCardMutation,
  useDeleteReportCardMutation,
  useGetLatestReportCardQuery,
} = reportCardsApi;
