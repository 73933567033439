import { Table }                                from 'antd';
import { ColumnType }                           from 'antd/lib/table/interface';
import React, { useEffect, useState }           from 'react';
import { useTranslation }                       from 'react-i18next';
import { assertFC }                             from '../../core/shared/utils/assertFC';
import { defaultPagination }                    from '../../core/shared/defaults/pagination';
import ActionsColumn                            from './ActionsColumn';
import { EntityTableProps, GetTablePagination } from './types';

const getPagination: GetTablePagination = (data) => Object.assign(defaultPagination, {
  ...(data ?? {}),
});

function EntityTable<E extends object>(props: EntityTableProps<E>) {
  const {
    loading, columns, data, rowKey, paginated, actions, paginationData,
  } = props;

  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<E[]>([]);
  const [actionsColumns, setActionsColumn] = useState<ColumnType<E> | undefined>();

  useEffect(() => {
    if (data !== undefined) {
      // If paginated, data is of type ListData<E>, else, data is of type E[]
      setDataSource(paginated ? data.data : (data));
    }
  }, [data, paginated]);

  useEffect(() => {
    if (actions !== undefined && Object.keys(actions).length > 0) {
      setActionsColumn({
        title: t('actions'),
        key: 'actions',
        render: (_, e, i) => <ActionsColumn entity={ e } actions={ actions } rowIndex={ i } />,
      });
    }
  }, [actions]);

  return (
    <div className='entity-table'>
      <Table
        loading={ loading }
        columns={ [...columns, (actionsColumns ?? {})] }
        dataSource={ dataSource }
        pagination={ paginated ? getPagination(paginationData) : false }
        rowKey={ rowKey }
      />
    </div>
  );
}

EntityTable.defaultProps = {
  paginated: false,
};

assertFC(EntityTable);

export default EntityTable;
