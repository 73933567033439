import {
  Card, Col, Pagination, Row, Tooltip,
}                                       from 'antd';
import { TablePaginationConfig }           from 'antd/lib/table/interface';
import React                               from 'react';
import parse                               from 'html-react-parser';
import {
  CheckCircle, Edit, Edit3, Eye, Trash2,
} from 'react-feather';
import { useTranslation }               from 'react-i18next';
import ApiBlogEntry, { BlogStatusEnum } from '../../core/interfaces/api/ApiBlogEntry';
import ListData                         from '../../core/interfaces/general/ListData';
import LoadingCardRow                   from '../LoadingCardRow';

import './index.less';

type HandleEntryCallback = (entry: ApiBlogEntry) => void;

export type EntriesActions = {
  onEdit: HandleEntryCallback;
  onDelete: HandleEntryCallback;
  onPublish: HandleEntryCallback;
  onUnpublish: HandleEntryCallback;
};

interface Props {
  dataSource: ListData<ApiBlogEntry> | undefined;
  loading?: boolean;
  paginationData?: TablePaginationConfig;
  actions?: EntriesActions;
}

const BlogEntriesViewer = (props: Props) => {
  const {
    dataSource, loading, paginationData, actions,
  } = props;

  const { t } = useTranslation(['common', 'admin']);

  const buildActions = (entry: ApiBlogEntry): React.ReactNode[] => {
    if (entry.blogStatusId === BlogStatusEnum.PUBLISHED) {
      return [
        <Tooltip title={ t('common:see')}>
          <Eye key='edit' className='action-icon' onClick={ () => actions?.onEdit(entry) } />
        </Tooltip>,
        <Tooltip title={ t('admin:blog_entries.actions.unpublish.action')} color='blue'>
          <Edit3 key='unpublish' className='action-icon' onClick={ () => actions?.onUnpublish(entry) } />
        </Tooltip>,
      ];
    }
    return [
      <Tooltip title={ t('common:edit')}>
        <Edit key='edit' className='action-icon' onClick={ () => actions?.onEdit(entry) } />
      </Tooltip>,
      <Tooltip title={ t('admin:blog_entries.actions.publish.action')} color='blue'>
        <CheckCircle key='publish' className='action-icon' onClick={ () => actions?.onPublish(entry) } />
      </Tooltip>,
      <Tooltip title={ t('common:delete')} color='red'>
        <Trash2 key='delete' onClick={ () => actions?.onDelete(entry) } />
      </Tooltip>,
    ];
  };

  return (
    <Row className='blog-entries-viewer' gutter={ [16, 16] }>
      {
          loading
            ? <LoadingCardRow count={3} />
            : dataSource?.data.map((entry) => (
              <Col xs={24} md={12} lg={8} span={8} key={ entry.id }>
                <Card
                  cover={
                    <img alt={ entry.title } src={ entry.imageUrl } />
                  }
                  actions={ actions !== undefined ? buildActions(entry) : []}
                >
                  <h4>{ entry.title }</h4>
                  <div className='body-container'>
                    { parse(entry.body) }
                  </div>
                </Card>
              </Col>
            ))
        }
      {
        dataSource?.data.length !== 0
        && (
        <Col span={24} className='text-center'>
          <Pagination
            {...paginationData}
          />
        </Col>
        )
      }
    </Row>
  );
};

export default BlogEntriesViewer;
