import React from 'react';
import './index.less';

type Props = {
  title: string | undefined;
  imageUrl: string | undefined;
}

const TitleImage: React.FC<Props> = ({ title, imageUrl }) => (
  <div className='project-title-image'>
    <img alt={ title } src={ imageUrl } />
    <div className='project-title'>
      <h3>
        { title }
      </h3>
    </div>
  </div>
);

export default TitleImage;
