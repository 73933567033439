import { Button }      from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import React           from 'react';
import { Edit }        from 'react-feather';
import './index.less';

export const EditButton: React.FC<ButtonProps> = (props) => {
  const { children, className } = props;
  return (
    <Button
      block
      { ...props }
      className={ `edit-btn ${className ?? ''}` }
    >
      { children }
      <Edit />
    </Button>
  );
};
