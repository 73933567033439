import { Layout }            from 'antd';
import React                 from 'react';
import { Outlet }            from 'react-router-dom';
import Footer                from '../../../components/layouts/Footer';
import { NeedHelpComponent } from '../../../components/NeedHelpComponent';

const { Content } = Layout;

const PublicLayout: React.FC = () => (
  <>
    <div className='container mx-auto pt-10 w-100'>
      <Content>
        <Outlet />
      </Content>
    </div>
    <NeedHelpComponent />
    <Footer />
  </>
);

export default PublicLayout;
