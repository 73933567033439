import React              from 'react';
import { useKeycloak }    from '@react-keycloak/web';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate }    from 'react-router-dom';
import { REDIRECT_PATHS } from '../../core/constants/keycloak.constants';
import APP_PATHS          from '../../core/constants/routes.constants';

const NotAllowed = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const nav = useNavigate();

  const onClick = () => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!keycloak.authenticated) {
      keycloak.logout({ redirectUri: REDIRECT_PATHS.logout });
    } else {
      nav(APP_PATHS.home);
    }
  };

  return (
    <Result
      status='403'
      title={ t('not_allowed.title') }
      subTitle={ t('not_allowed.subtitle') }
      extra={ (
        <Button
          onClick={ onClick }
          type='primary'
        >
          {
            // eslint-disable-next-line no-extra-boolean-cast
            !!keycloak.authenticated ? t('logout') : t('go_home')
          }
        </Button>
      ) }
    />
  );
};

export default NotAllowed;
