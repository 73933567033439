import React                   from 'react';
import { useTranslation }      from 'react-i18next';
import {
  Col, Collapse, Modal, Row,
}                           from 'antd';
import { RightOutlined }    from '@ant-design/icons';
import { ApiInstitution }   from '../../../core/interfaces/api/ApiInstitution';
import { defaultInfoProps } from '../../../core/shared/defaults/defaultModalProps';
import InstitutionForm      from '../../forms/admin/InstitutionForm/form';
import PanelHeader          from './PanelHeader';
import { ApiOrganization }  from '../../../core/interfaces/api/ApiOrganization';

import './index.less';

interface Props {
  data: ApiOrganization;
  handleOnViewResponsible: () => void;
  handleOnDeleteResponsible: () => void;
}

const { Panel } = Collapse;

const ArrowIcon = (isActive: boolean|undefined) => (
  <RightOutlined rotate={isActive ? -90 : 90} style={{ color: 'var(--fund-education-primary-color)' }} />
);

const OrganizationCollapse = (props: Props) => {
  const {
    data: organization, handleOnDeleteResponsible, handleOnViewResponsible,
  } = props;
  const { t } = useTranslation('common');

  const onViewInstitution = (institution: ApiInstitution) => {
    Modal.info({
      ...defaultInfoProps,
      width: '50%',
      title: t('common:institution.actions.see_institution'),
      content: <InstitutionForm entity={institution} />,
    });
  };

  return (
    <Collapse
      bordered={ false }
      expandIcon={ ({ isActive }) => ArrowIcon(isActive) }
      expandIconPosition='end'
      className='site-collapse-custom-borderless'
    >
      <Panel
        key={ organization.id }
        header={(
          <PanelHeader
            name={ organization.name }
            handleOnView={ handleOnViewResponsible }
            handleOnDelete={ handleOnDeleteResponsible }
          />
        )}
        className='my-2'
      >
        {
            organization?.institutions.map((institution) => (
              <Row align='middle' className='user-panel px-4 py-2 my-2' key={institution.id}>
                <Col span={24}>
                  <div className='text-body-bold mb-2'>
                    <span>{ institution.name }</span>
                  </div>
                </Col>
                <Col>
                  <div className='d-flex'>
                    <div className='mr-4'>
                      <button
                        type='button'
                        className='btn-text-custom btn-text-custom-primary'
                        onClick={ () => onViewInstitution(institution) }
                      >
                        { t('common:see') }
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            ))
          }
      </Panel>
    </Collapse>
  );
};

export default OrganizationCollapse;
