import { useKeycloak }               from '@react-keycloak/web';
import React                         from 'react';
import { Navigate, Outlet }          from 'react-router-dom';
import HOME_ROUTES                   from '../../constants/homeRoutes.constants';
import useGetRoles, { UserRoleType } from '../../hooks/useGetRoles';

type Props = {
  role: UserRoleType;
}

const AuthOrPublicMiddleware: React.FC<Props> = ({ role }) => {
  const { keycloak } = useKeycloak();
  const { role: localRole } = useGetRoles();

  const authenticatedRedirection = !keycloak.hasResourceRole(role) && localRole
    ? HOME_ROUTES[localRole] : null;

  return authenticatedRedirection !== null
    ? <Navigate to={ authenticatedRedirection } />
    : <Outlet />;
};

export default AuthOrPublicMiddleware;
