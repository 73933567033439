import { Card, Col, Row }               from 'antd';
import React                            from 'react';
import { useTranslation }               from 'react-i18next';
import { useGetStudentSchoolInfoQuery } from '../../../../../../adapters/services/projects.service';
import LoadingCardRow                   from '../../../../../../components/LoadingCardRow';
import { ApiProject }                   from '../../../../../interfaces/api/ApiProject';
import './index.less';

type Props = {
  project: ApiProject;
}

export const SchoolInfoCard: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const { data } = useGetStudentSchoolInfoQuery(project.id);
  return (
    <Card id='school-info-card'>
      <Row>
        {
          data === undefined && <LoadingCardRow count={1} />
        }
        {
          data !== undefined && (
            <>
              <Col span={ 8 }>
                <div className='info-header'>{ t('projects.student.grade') }</div>
                <div className='info-body'>{ data.gradeAbbreviation }</div>
              </Col>
              <Col span={ 8 }>
                <div className='info-header'>{ t('projects.student.average_score') }</div>
                <div className='info-body'>{ data.averageScore ?? 'N/A' }</div>
              </Col>
              <Col span={ 8 }>
                <div className='info-header'>{ t('projects.student.past_score') }</div>
                <div className='info-body'>{ data.pastScore ?? 'N/A' }</div>
              </Col>
            </>
          )
        }
      </Row>
    </Card>
  );
};
