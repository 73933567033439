import { generatePath }   from 'react-router-dom';
import {
  LIST_ID_TAG,
  SPONSOR_PROJECTS_TAG,
} from '../../core/constants/api.tags.constants';
import { SPONSOR_PROJECTS_URIS } from '../../core/constants/api.uris.constants';
import { ApiSponsorProject }     from '../../core/interfaces/api/ApiProject';
import ListData                  from '../../core/interfaces/general/ListData';
import PageableInputType         from '../../core/shared/utils/pageableInputType';
import fundEducationApi          from '../apis/fundEducation.api';

const invalidateReminder = (id: string) => [
  { type: SPONSOR_PROJECTS_TAG, id },
];

export const sponsorProjectsApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getDonatedProjects: build.query<ListData<ApiSponsorProject>, PageableInputType>({
      query: ({
        page = 1,
        perPage = 6,
      }) => `${SPONSOR_PROJECTS_URIS.root}?page=${page}&perPage=${perPage}`,
      providesTags: (result) => (
        result
          ? [
            ...result.data.map(({ id }) => ({ type: SPONSOR_PROJECTS_TAG, id } as const)),
            { type: SPONSOR_PROJECTS_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: SPONSOR_PROJECTS_TAG, id: LIST_ID_TAG }]
      ),
    }),
    setReminder: build.mutation<null, string>({
      query: (id) => ({
        url: generatePath(SPONSOR_PROJECTS_URIS.setReminder, { id }),
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, id) => invalidateReminder(id),
    }),
    unsetReminder: build.mutation<null, string>({
      query: (id) => ({
        url: generatePath(SPONSOR_PROJECTS_URIS.unsetReminder, { id }),
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, id) => invalidateReminder(id),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetDonatedProjectsQuery,
  useSetReminderMutation,
  useUnsetReminderMutation,
} = sponsorProjectsApi;
