import React      from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Footer     from '../../../components/layouts/Footer';
import NavBar     from '../../../components/layouts/NavBar';

const { Header } = Layout;

const SimplePublicLayout: React.FC = () => (
  <Layout>
    <Header>
      <NavBar />
    </Header>
    <Layout>
      <Outlet />
    </Layout>
    <Footer />
  </Layout>
);

export default SimplePublicLayout;
