import React      from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import NavBar     from '../../../components/layouts/NavBar';
import './main.layout.style.less';

const { Header } = Layout;

const MainLayout: React.FC = () => (
  <Layout>
    <Header className='header'>
      <NavBar />
    </Header>
    <Layout>
      <Outlet />
    </Layout>
  </Layout>
);

export default MainLayout;
