import React, { useEffect, useState }                 from 'react';
import { useLocation, useNavigate }                   from 'react-router-dom';
import { useTranslation }                             from 'react-i18next';
import {
  Button, Card, Col, Empty, Modal, Pagination, Row,
}                                                        from 'antd';
import { ArrowLeftOutlined }                             from '@ant-design/icons';
import { useDeleteUserMutation, useGetUsersByTypeQuery } from '../../../../adapters/services/users.service';
import UserForm                                          from '../../../../components/forms/admin/UserForm/form';
import UserList                                          from '../../../../components/Users/UserList';
import APP_PATHS                                         from '../../../constants/routes.constants';
import { useAppDispatch }                                from '../../../hooks/store';
import useCrudNotificationResponse                       from '../../../hooks/useCrudNotificationResponse';
import usePagination                                     from '../../../hooks/usePagination';
import { ApiUser }                                       from '../../../interfaces/api/ApiUser';
import defaultCardColProps                               from '../../../shared/defaults/defaultCardColProps';
import { defaultConfirmProps, defaultInfoProps }         from '../../../shared/defaults/defaultModalProps';
import { setLoader }                                     from '../../../store/features/uiSlice';

const ListUsersByTypePage = () => {
  const { t } = useTranslation(['common', 'admin']);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [pathName, setPathName] = useState<string>('');

  const { page, setQueryPage } = usePagination();
  const { data, isLoading, isFetching } = useGetUsersByTypeQuery({ id: pathName, page }, { skip: pathName === '' });

  const [deleteUser, {
    isLoading: isDeleting,
    isSuccess: deletedUser,
    error: userError,
  }] = useDeleteUserMutation();

  const userType = {
    ADM: t('admin:user.admins'),
    SPO: t('admin:user.sponsors'),
  };

  useCrudNotificationResponse({
    isSuccess: deletedUser,
    error: userError,
    successNotification: {
      message: t('admin:user.actions.delete.success.title'),
      description: t('admin:user.actions.delete.success.description'),
    },
  });

  useEffect(() => {
    const tmp = pathname.slice(pathname.lastIndexOf('/'), pathname.length)
      .replace('/', '');
    setPathName(tmp);
  }, [pathname]);

  useEffect(() => {
    dispatch(setLoader(isDeleting));
  }, [dispatch, isDeleting]);

  const onView = (user: ApiUser) => {
    Modal.info({
      ...defaultInfoProps,
      width: '50%',
      title: t('admin:user.actions.see_user'),
      content: <UserForm entity={user} />,
    });
  };

  const onDelete = (user: ApiUser) => {
    Modal.confirm({
      ...defaultConfirmProps,
      title: t('admin:user.actions.delete.confirm'),
      onOk: () => deleteUser(user),
      width: '40%',
    });
  };

  return (
    <Row justify='center'>
      <Col { ...defaultCardColProps }>
        <Button className='mb-4' type='primary' icon={<ArrowLeftOutlined />} onClick={() => navigate(APP_PATHS.admin.users)}>
          <span className='text-body-bold'>
            { t('common:go_back') }
          </span>
        </Button>
        <Card loading={isLoading || isFetching}>
          <div className='text-h1'>
            { userType[pathName as keyof typeof userType] }
          </div>
          {
            data?.data.map((user) => (
              <UserList
                key={user.id}
                data={user}
                handleOnView={ () => onView(user) }
                handleOnDelete={ () => onDelete(user) }
              />
            ))
          }
          {
            data?.data.length !== 0
            && (
              <div className='d-flex justify-center mt-4'>
                <Pagination
                  onChange={setQueryPage}
                  current={ page }
                  pageSize={ 10 }
                  total={ data?.total }
                />
              </div>
            )
          }
          { !isFetching && data?.data.length === 0 && <Empty description={ t('admin:user.not_found') } /> }
        </Card>
      </Col>
    </Row>
  );
};

export default ListUsersByTypePage;
