import { BLOG_TYPES_TAG, LIST_ID_TAG } from '../../core/constants/api.tags.constants';
import { BLOG_TYPES_URIS }             from '../../core/constants/api.uris.constants';
import ApiBlogType                     from '../../core/interfaces/api/ApiBlogType';
import fundEducationApi                from '../apis/fundEducation.api';

export const blogTypesApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getBlogTypes: build.query<ApiBlogType[], void>({
      query: () => BLOG_TYPES_URIS.root,
      providesTags: (result) => (
        result
          ? [
            ...result.map(({ id }) => ({ type: BLOG_TYPES_TAG, id } as const)),
            { type: BLOG_TYPES_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: BLOG_TYPES_TAG, id: LIST_ID_TAG }]
      ),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetBlogTypesQuery,
} = blogTypesApi;
