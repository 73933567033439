import React                  from 'react';
import {
  Col, Divider, Image, Row,
} from 'antd';
import { useTranslation }                     from 'react-i18next';
import { Link }                               from 'react-router-dom';
import { FundLogo, FlagSV }                   from '../../../core/constants/assets.constants';
import { CurrencySelector, LanguageSelector } from '../../l10n';

import './index.less';

type ItemLinkType = {
  key: string;
  label: string;
  link: string;
}

const Footer = () => {
  const { t } = useTranslation();

  const itemsLinksHome: ItemLinkType[] = [
    {
      key: '1',
      label: t('public:footer.home.home'),
      link: '/',
    },
    {
      key: '2',
      label: t('public:footer.home.discover_projects'),
      link: '/',
    },
    {
      key: '3',
      label: t('public:footer.home.start_fundraising'),
      link: '/',
    },
    {
      key: '4',
      label: t('public:footer.home.how_it_works'),
      link: '/',
    },
    {
      key: '5',
      label: t('public:footer.home.how_to_write_story'),
      link: '/',
    },
    {
      key: '6',
      label: t('public:footer.home.fundraising_tips'),
      link: '/',
    },
    {
      key: '7',
      label: t('public:footer.home.category_tips'),
      link: '/',
    },
  ];

  const itemsLinksFaq: ItemLinkType[] = [
    {
      key: '1',
      label: t('public:footer.faq.faq'),
      link: '/',
    },
    {
      key: '2',
      label: t('public:footer.faq.funding_blog'),
      link: '/',
    },
    {
      key: '3',
      label: t('public:footer.faq.notation'),
      link: '/',
    },
    {
      key: '4',
      label: t('public:footer.faq.company_information'),
      link: '/',
    },
    {
      key: '5',
      label: t('public:footer.faq.press_media'),
      link: '/',
    },
  ];

  const itemsLinks = ({ key, label, link }: ItemLinkType) => (
    <Link className='d-block text-footer-bold mb-4' to={ link } key={ key }>
      { label }
    </Link>
  );

  return (
    <footer className='footer py-16'>
      <div className='footer__container container w-100 mx-auto'>
        <Row
          gutter={[
            {
              xs: 8, sm: 16, md: 24, lg: 32,
            },
            {
              xs: 8, sm: 16, md: 24, lg: 32,
            },
          ]}
          className='footer__row w-100'
        >
          <Col xs={24} md={12} lg={6} className='footer__column gutter-row' span={6}>
            <h3 className='text-gray mb-6'>Fundraiser</h3>
            <div className='mb-6'>
              <p className='text-gray text-body1 mb-1'>{ t('common:language') }</p>
              <LanguageSelector />
            </div>
            <div className='mb-6'>
              <p className='text-gray text-body1 mb-1'>{ t('common:currency') }</p>
              <CurrencySelector />
            </div>
          </Col>
          <Col xs={24} md={12} lg={6} className='footer__column gutter-row' span={6}>
            { itemsLinksHome.map(itemsLinks) }
          </Col>
          <Col xs={24} md={12} lg={6} className='footer__column gutter-row' span={6}>
            {
              itemsLinksFaq.map(itemsLinks)
            }
          </Col>
          <Col xs={24} md={12} lg={6} className='footer__column gutter-row' span={6}>
            <Link className='d-block text-footer-bold mb-4' to='/'>
              { t('public:footer.about_us.about_us') }
            </Link>
            <div className='text-body1 text-gray mb-4'>
              <Image
                preview={false}
                src={FlagSV}
                width={16}
              />
              <span className='ml-1'>El Salvador</span>
            </div>
            <p className='text-body1 text-footer-bold mb-4'>ESE plus</p>
            <p className='text-sm text-gray mb-4'>
              Sexta Décima Calle Poniente #1713 y 31
              {' '}
              <br />
              {' '}
              Avenida Sur, San Salvador, El Salvador
            </p>
            <p className='text-body1 text-footer-bold mb-4'>
              { t('public:footer.about_us.contact_us') }
            </p>
            <p className='text-sm text-gray mb-2'>
              (+503)2506-4373
            </p>
            <p className='text-sm text-gray mb-2'>
              Contacto@ese.plus
            </p>
          </Col>
        </Row>
        <Row
          gutter={
            {
              xs: 8, sm: 16, md: 24, lg: 32,
            }
          }
          className='footer__row'
          justify='space-between'
        >
          <Col xs={24}>
            <Divider className='footer__divider mb-2' orientation='left' />
          </Col>
          <Col xs={24} lg={12} className='footer__column d-block lg-d-none'>
            <Image
              className='footer__column--image'
              preview={false}
              src={FundLogo.default}
            />
          </Col>
          <Col xs={24} md={8} className='d-none lg-d-block'>
            <p className='text-body1'>&copy; 2022 Fundraiser</p>
          </Col>
          <Col xs={24} md={16} lg={12}>
            <Row className='footer__column--links' justify='end'>
              <Link to='/' className='footer__column--links--link text-body1'>
                { t('public:footer.conditions') }
              </Link>
              <Link to='/' className='footer__column--links--link text-body1 mx-12'>
                { t('public:footer.privacy') }
              </Link>
              <Link to='/' className='footer__column--links--link text-body1'>
                { t('public:footer.legal_information') }
              </Link>
            </Row>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
