import { PlusOutlined }      from '@ant-design/icons';
import {
  Button, Col, Modal, Row,
}       from 'antd';
import { useForm }                        from 'antd/es/form/Form';
import React, { useEffect }               from 'react';
import { useTranslation }                 from 'react-i18next';
import {
  useCreateProjectCategoryTypeMutation,
  useDeleteProjectCategoryTypeMutation,
  useGetProjectCategoryTypesQuery,
  useUpdateProjectCategoryTypeMutation,
}                                  from '../../../../adapters/services/projectCategoryTypes.service';
import EntityTable             from '../../../../components/EntityTable';
import { ActionTuple }         from '../../../../components/EntityTable/types';
import ProjectCategoryTypeForm, {
  ProjectCategoryTypeCallback,
  ProjectCategoryTypeFields,
}                                  from '../../../../components/forms/admin/ProjectCategoryTypeForm/form';
import { useAppDispatch }          from '../../../hooks/store';
import useCrudNotificationResponse from '../../../hooks/useCrudNotificationResponse';
import usePagination               from '../../../hooks/usePagination';
import ApiProjectCategoryType      from '../../../interfaces/api/ApiProjectCategoryType';
import {
  defaultConfirmProps,
  defaultInfoProps,
}                                  from '../../../shared/defaults/defaultModalProps';
import { setLoader } from '../../../store/features/uiSlice';
import columns       from './columns';

const perPage = 10;

const ListProjectCategoryTypes = () => {
  const { t } = useTranslation(['common', 'admin']);
  const dispatch = useAppDispatch();
  const [form] = useForm<ProjectCategoryTypeFields>();

  const { page, setQueryPage } = usePagination();
  const { data, isLoading, isFetching } = useGetProjectCategoryTypesQuery({ page, perPage });

  const [createProjectCategoryType, {
    isLoading: isCreating,
    isSuccess: created,
    error: createError,
  }] = useCreateProjectCategoryTypeMutation();

  const [updateProjectCategoryType, {
    isLoading: isUpdating,
    isSuccess: updated,
    error: updateError,
  }] = useUpdateProjectCategoryTypeMutation();

  const [deleteProjectCategoryType, {
    isLoading: isDeleting,
    isSuccess: deleted,
    error: deleteError,
  }] = useDeleteProjectCategoryTypeMutation();

  useCrudNotificationResponse({ isSuccess: created, error: createError, form });
  useCrudNotificationResponse({ isSuccess: updated, error: updateError, form });
  useCrudNotificationResponse({ isSuccess: deleted, error: deleteError });

  useEffect(() => {
    dispatch(setLoader(isCreating || isUpdating || isDeleting));
  }, [dispatch, isCreating, isUpdating, isDeleting]);

  const handleFinish: ProjectCategoryTypeCallback = (fields, entity) => {
    if (entity === undefined) {
      createProjectCategoryType(fields);
    } else {
      updateProjectCategoryType({
        id: entity.id,
        ...fields,
      });
    }
  };

  const onSave = () => {
    Modal.info({
      ...defaultInfoProps,
      title: t('admin:projects.category_types.new'),
      width: '65%',
      content: <ProjectCategoryTypeForm
        onFinish={ handleFinish }
        form={ form }
      />,
    });
  };

  const onEdit = (projectCategoryType: ApiProjectCategoryType) => {
    Modal.info({
      ...defaultInfoProps,
      title: t('admin:projects.category_types.update'),
      content: <ProjectCategoryTypeForm
        entity={ projectCategoryType }
        onFinish={ handleFinish }
        form={ form }
      />,
    });
  };

  const onDelete = (projectCategoryType: ApiProjectCategoryType) => Modal.confirm({
    ...defaultConfirmProps,
    title: t('admin:projects.category_types.delete'),
    width: '40%',
    onOk: () => deleteProjectCategoryType(projectCategoryType),
  });

  const actions: ActionTuple<ApiProjectCategoryType>[] = [
    ['edit', onEdit],
    ['delete', onDelete],
  ];

  return (
    <Row gutter={ [16, 32] }>
      <Col span={24}>
        <h1>
          { t('admin:projects.category_types.title') }
        </h1>
      </Col>
      <Col span={24}>
        <Button
          icon={ <PlusOutlined /> }
          size='large'
          type='primary'
          onClick={ onSave }
        >
          { t('admin:projects.category_types.actions.new') }
        </Button>
      </Col>
      <Col span={24}>
        <EntityTable
          loading={ isLoading || isFetching }
          data={ data }
          columns={ columns }
          actions={ actions }
          rowKey='id'
          paginated
          paginationData={ {
            onChange: setQueryPage,
            current: page,
            total: data?.total,
          } }
        />
      </Col>
    </Row>
  );
};

export default ListProjectCategoryTypes;
