import React               from 'react';
import { useTranslation }  from 'react-i18next';
import {
  Col, Form, Input, Row,
} from 'antd';
import { ApiUser } from '../../../../core/interfaces/api/ApiUser';

const { Item } = Form;

type Props = {
  entity: ApiUser;
}

const UserForm: React.FC<Props> = ({
  entity,
}) => {
  const { t } = useTranslation('admin');

  return (
    <Form
      layout='vertical'
      preserve={false}
    >
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Item
            label={ t('admin:user.form.first_name') }
          >
            <Input
              value={ entity.firstName }
            />
          </Item>
        </Col>
        <Col span={24}>
          <Item
            label={ t('admin:user.form.last_name') }
          >
            <Input
              value={ entity.lastName }
            />
          </Item>
        </Col>
        <Col span={24}>
          <Item
            label={ t('admin:user.form.email') }
          >
            <Input
              value={ entity.email }
            />
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UserForm;
