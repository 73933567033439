import React, { useEffect, useState } from 'react';
import _                              from 'lodash';
import { Result }                     from 'antd';
import { useTranslation }             from 'react-i18next';
import { useNavigate }                from 'react-router-dom';
import { useAppSelector }             from '../../core/hooks/store';

const AppError = () => {
  const { t } = useTranslation();
  const nav = useNavigate();

  const { keycloakErrorType } = useAppSelector(({ auth }) => auth);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (keycloakErrorType === null) {
      nav(-1);
    } else {
      setError(_.words(keycloakErrorType.replace('on', '')).join(' '));
    }
  }, [keycloakErrorType, nav]);

  return (
    <Result
      status='500'
      title={ error }
      subTitle={ t('default_error') }
    />
  );
};

export default AppError;
