import { RcFile } from 'antd/lib/upload/interface';

export const imgRegex = /[/.](gif|jpg|jpeg|png)$/i;
const audioFilesRegex = /[/.](ogg|wav|mp3|mpeg)$/i;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normFile = (e: any) => {
  if (Array.isArray(e)) return e;

  return e && e.fileList;
};

export const getFileAsSrc = (file: RcFile): Promise<string | null> => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader?.result?.toString() ?? null);
  reader.readAsDataURL(file);
});

export const validateFileIsImage = (file: RcFile): boolean => imgRegex.test(file.type);
export const validateFileIsAudio = (file: RcFile): boolean => audioFilesRegex.test(file.type);
