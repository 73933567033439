import React                             from 'react';
import {
  Button, Col, Empty, Pagination, Row,
} from 'antd';
import { useTranslation }          from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import ProjectCard                 from '../../../../components/projects/ProjectCard';
import APP_PATHS                   from '../../../constants/routes.constants';
import { ApiProject }              from '../../../interfaces/api/ApiProject';
import ListData                    from '../../../interfaces/general/ListData';
import { defaultPagination }       from '../../../shared/defaults/pagination';

type Props = {
  title: string;
  titleClass: string;
  loading: boolean;
  editable: boolean;
  currentPage: number;
  onPageChange: (_p: number) => void;
  projectData: ListData<ApiProject> | undefined;
}

const ProjectList: React.FC<Props> = ({
  title, titleClass, loading, editable, projectData, onPageChange, currentPage,
}) => {
  const { t } = useTranslation(['common', 'organization']);
  const { institutionId } = useParams();

  const buildLink = (projectId: string) => generatePath(
    editable ? APP_PATHS.organization.projectRoot
      : 'insert non editable route',
    { institutionId, projectId },
  );

  // TODO: Make button visible when its functionality is developed, or remove it
  const downloadDetail = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    project: ApiProject,
  ): void => {
    event.stopPropagation();
    alert(`Descargar detalle de ${project.title}`);
  };

  return (
    <Row gutter={[24, 32]} className='project-container'>
      <Col span={24}>
        <h1 className={ titleClass }>{ title }</h1>
      </Col>
      {
        loading || projectData?.total === 0
          ? <Col span={24}><Empty description={ t('common:projects.empty') } /></Col>
          : (
            <>
              <Col span={ 24 } className='text-center'>
                <Pagination
                  pageSize={ 12 }
                  onChange={ onPageChange }
                  current={ currentPage }
                  total={ projectData?.total }
                  { ...defaultPagination }
                />
              </Col>
              {
                projectData?.data.map((project) => (
                  <Col
                    xs={ 24 }
                    sm={ 12 }
                    xl={ 8 }
                    key={ project.id }
                  >
                    <ProjectCard project={ project } to={ buildLink(project.id) } animation>
                      { editable && false
                        && (
                          <Button
                            block
                            className='download-btn'
                            onClick={ (event) => downloadDetail(event, project) }
                          >
                            { t('organization:institution.projects.download.single_detail') }
                          </Button>
                        ) }
                    </ProjectCard>
                  </Col>
                ))
              }
              <Col span={ 24 } className='text-center'>
                <Pagination
                  pageSize={ 12 }
                  onChange={ onPageChange }
                  current={ currentPage }
                  total={ projectData?.total }
                  { ...defaultPagination }
                />
              </Col>
            </>
          )
      }
    </Row>
  );
};

export default ProjectList;
