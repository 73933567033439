import {
  Card, Col, Row,
}                                from 'antd';
import React                     from 'react';
import { useTranslation }        from 'react-i18next';
import { generatePath, Link }    from 'react-router-dom';
import { useGetUserCountsQuery } from '../../../../adapters/services/users.service';
import { OutlinedButton }        from '../../../../components/buttons';
import APP_PATHS                 from '../../../constants/routes.constants';
import defaultCardColProps       from '../../../shared/defaults/defaultCardColProps';

const ListUsersPage = () => {
  const { t } = useTranslation(['common', 'admin']);

  const { data, isLoading, isFetching } = useGetUserCountsQuery();

  return (
    <Row justify='center'>
      <Col { ...defaultCardColProps }>
        <Card>
          <div className='text-h1'>
            { t('admin:user.title') }
          </div>
          <div className='d-flex align-center justify-space-between mt-8'>
            <div className='text-h4'>{t('admin:user.all')}</div>
            <div className='text-body1'>
              {
                (!isLoading || !isFetching)
                  ? `${data?.totalUsers}  ${t('admin:user.title')}`
                  : t('admin:user.loading')
              }
            </div>
          </div>

          <div className='d-flex flex-column mt-8'>
            <div className='d-flex align-center justify-space-between mb-6'>
              <div className='text-h4'>{t('admin:user.organizations')}</div>
              <div className='text-body1'>
                {
                  (!isLoading || !isFetching)
                    ? `${data?.ORG ?? 0}  ${t('admin:user.title')}`
                    : t('admin:user.loading')
                }
              </div>
            </div>

            <div>
              <OutlinedButton>
                <Link to={ APP_PATHS.admin.organizations }>{ t('common:see') }</Link>
              </OutlinedButton>
            </div>
          </div>
          <div className='d-flex flex-column mt-8'>
            <div className='d-flex align-center justify-space-between mb-6'>
              <div className='text-h4'>{t('admin:user.sponsors')}</div>
              <div className='text-body1'>
                {
                  (!isLoading || !isFetching)
                    ? `${data?.SPO ?? 0}  ${t('admin:user.title')}`
                    : t('admin:user.loading')
                }
              </div>
            </div>

            <div>
              <OutlinedButton>
                <Link to={ generatePath(APP_PATHS.admin.usersType, { typeId: 'SPO' })}>{ t('common:see') }</Link>
              </OutlinedButton>
            </div>
          </div>
          <div className='d-flex flex-column mt-8'>
            <div className='d-flex align-center justify-space-between mb-6'>
              <div className='text-h4'>{t('admin:user.admins')}</div>
              <div className='text-body1'>
                {
                  (!isLoading || !isFetching)
                    ? `${data?.ADM ?? 0}  ${t('admin:user.title')}`
                    : t('admin:user.loading')
                }
              </div>
            </div>

            <div>
              <OutlinedButton>
                <Link to={ generatePath(APP_PATHS.admin.usersType, { typeId: 'ADM' }) }>{ t('common:see') }</Link>
              </OutlinedButton>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default ListUsersPage;
