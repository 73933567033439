import React               from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { PublicLogo }      from '../../core/constants/assets.constants';
import './index.less';

const AppLoader = () => (
  <div id='app-loader'>
    <div className='content'>
      <img src={ PublicLogo } alt='fund-education-logo' />
      <div className='spin-cont'>
        <LoadingOutlined style={ { fontSize: 24 } } spin />
      </div>
    </div>
  </div>
);

export default AppLoader;
