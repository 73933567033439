import { Progress }           from 'antd';
import React                  from 'react';
import { useTranslation }     from 'react-i18next';
import useConvertCurrency     from '../../../core/hooks/useConvertCurrency';
import useDBTranslation       from '../../../core/hooks/useDBTranslation';
import { ApiProjectCategory } from '../../../core/interfaces/api/ApiProjectCategory';
import './index.less';

type Props = {
  category: ApiProjectCategory;
  showHeader?: boolean;
  className?: string;
}

export const CategoryProgress: React.FC<Props> = ({
  category,
  showHeader = false,
  className,
}) => {
  const { t } = useTranslation();
  const tDB = useDBTranslation();
  const convert = useConvertCurrency();

  const percent = (category.raised / category.goal) * 100;

  return (
    <div className={ `category-progress ${className}` }>
      {
        showHeader && (
          <div className='progress-header'>
            <span>{ tDB(category.type, 'name') }</span>
            <span>{ `${convert(category.raised)}` }</span>
          </div>
        )
      }
      <Progress
        showInfo={false}
        percent={ percent }
        strokeColor='var(--fund-education-primary-color)'
        trailColor='var(--disabled-bg)'
      />
      <div className='progress-footer'>
        <span>{ `${percent.toFixed(2)}%` }</span>
        <span>{ `${t('projects.categories.goal')} ${convert(category.goal)}` }</span>
      </div>
    </div>
  );
};
