import {
  Card, Col, Row,
}                                                       from 'antd';
import React                                    from 'react';
import { useTranslation }                       from 'react-i18next';
import {
  generatePath, Link, useNavigate, useParams,
}                                                       from 'react-router-dom';
import { useGetInstitutionQuery }                       from '../../../../adapters/services/institutions.service';
import { ProjectFilters, useGetPaginatedProjectsQuery } from '../../../../adapters/services/projects.service';
import { InstitutionalProjectImg, StudentProjectImg }   from '../../../../components/assets/Images';
import { EditButton }                                   from '../../../../components/buttons';
import LoadingCardRow                                   from '../../../../components/LoadingCardRow';
import { NeedHelpComponent }                            from '../../../../components/NeedHelpComponent';
import APP_PATHS                                        from '../../../constants/routes.constants';
import usePagination                                    from '../../../hooks/usePagination';
import ProjectList                                      from './ProjectList';
import './index.less';

type Props = {
  editable?: boolean;
}

const perPage = 9;

const InstitutionProfilePage: React.FC<Props> = ({ editable = false }) => {
  const { t } = useTranslation(['common', 'organization']);
  const { institutionId } = useParams();
  const navigate = useNavigate();

  const { data } = useGetInstitutionQuery(institutionId ?? '');

  const commonFilters: ProjectFilters = {
    institution: data?.id,
    order: 'DATE',
    include: 'DELETED',
  };

  const {
    page: studentPage,
    setQueryPage: setStudentPage,
  } = usePagination('studentPage');
  const {
    data: studentProjects,
    isLoading: studentLoading,
    isFetching: studentFetching,
  } = useGetPaginatedProjectsQuery({
    perPage,
    page: studentPage,
    filters: {
      ...commonFilters,
      type: 'STUDENT',
      status: 'ACTIVE',
    },
  }, { skip: data === undefined });

  const {
    page: studentFinishedPage,
    setQueryPage: setStudentFinishedPage,
  } = usePagination('studentFinishedPage');
  const {
    data: studentFinishedProjects,
    isLoading: studentFinishedLoading,
    isFetching: studentFinishedFetching,
  } = useGetPaginatedProjectsQuery({
    perPage,
    page: studentFinishedPage,
    filters: {
      ...commonFilters,
      type: 'STUDENT',
      status: 'FINISHED',
    },
  }, { skip: data === undefined || !editable });

  const {
    page: institutionalPage,
    setQueryPage: setInstitutionalPage,
  } = usePagination('institutionalPage');
  const {
    data: institutionalProjects,
    isLoading: institutionalLoading,
    isFetching: institutionalFetching,
  } = useGetPaginatedProjectsQuery({
    perPage,
    page: institutionalPage,
    filters: {
      ...commonFilters,
      type: 'INSTITUTIONAL',
      status: 'ACTIVE',
    },
  }, { skip: data === undefined });

  const {
    page: institutionalFinishedPage,
    setQueryPage: setInstitutionalFinishedPage,
  } = usePagination('institutionalFinishedPage');
  const {
    data: institutionalFinishedProjects,
    isLoading: institutionalFinishedLoading,
    isFetching: institutionalFinishedFetching,
  } = useGetPaginatedProjectsQuery({
    perPage,
    page: institutionalFinishedPage,
    filters: {
      ...commonFilters,
      type: 'INSTITUTIONAL',
      status: 'FINISHED',
    },
  }, { skip: data === undefined || !editable });

  const goToNewProject = (path: string) => navigate(generatePath(path, { institutionId }));

  return (
    <div id='institution-profile'>
      {
        data === undefined && <LoadingCardRow count={1} />
      }
      {
        data !== undefined
        && (
          <>
            <Row gutter={[24, 24]}>
              <Col sm={ 24 } md={ 6 }>
                <img
                  alt={ data.name }
                  src={ data.imageUrl }
                  className='profile-img'
                />
              </Col>
              <Col sm={ 24 } md={ 18 }>
                <h2 className='text-primary'>{ data.name }</h2>
                <h5>{ data.description }</h5>
                {
                  editable
                  && (
                    <Link to={
                        generatePath(
                          APP_PATHS.organization.editInstitution,
                          { institutionId: data.id },
                        )
                      }
                    >
                      <EditButton>
                        { t('organization:institution.edit_profile') }
                      </EditButton>
                    </Link>
                  )
                }

              </Col>
              {
                data.images.map((image, key) => (
                  <Col key={ image.id } xs={ 12 } md={ 8 } lg={ 4 }>
                    <img
                      alt={ key.toString() }
                      src={ image.url }
                      className='profile-img'
                    />
                  </Col>
                ))
              }
            </Row>
            {
              editable
              && (
                <Row className='create-project-panel mt-16' gutter={[24, 24]}>
                  <Col span={ 24 }>
                    <h1 className='text-primary text-center'>
                      { t('organization:institution.projects.create.title') }
                    </h1>
                  </Col>
                  <Col xs={ 24 } sm={ 12 } xl={{ span: 9, offset: 3 }}>
                    <Card
                      hoverable
                      onClick={() => goToNewProject(APP_PATHS.organization.newInstitutionalProject)}
                    >
                      <InstitutionalProjectImg />
                      <h3>
                        { t('organization:institution.projects.institutional.create.title') }
                      </h3>
                      <p>
                        { t('organization:institution.projects.institutional.create.description') }
                      </p>
                    </Card>
                  </Col>
                  <Col xs={ 24 } sm={ 12 } xl={ 9 }>
                    <Card
                      hoverable
                      onClick={() => goToNewProject(APP_PATHS.organization.newStudentProject)}
                    >
                      <StudentProjectImg />
                      <h3>
                        { t('organization:institution.projects.student.create.title') }
                      </h3>
                      <p>
                        { t('organization:institution.projects.student.create.description') }
                      </p>
                    </Card>
                  </Col>
                </Row>
              )
            }
            <ProjectList
              title={ t('organization:institution.projects.student.active') }
              titleClass='student-title'
              editable={ editable }
              currentPage={ studentPage }
              onPageChange={ setStudentPage }
              projectData={ studentProjects }
              loading={ studentLoading || studentFetching }
            />
            {
              editable
              && (
              <ProjectList
                title={ t('organization:institution.projects.student.finished') }
                titleClass='student-title'
                editable={ editable }
                currentPage={ studentFinishedPage }
                onPageChange={ setStudentFinishedPage }
                projectData={ studentFinishedProjects }
                loading={ studentFinishedLoading || studentFinishedFetching }
              />
              )
            }
            <ProjectList
              title={ t('organization:institution.projects.institutional.active') }
              titleClass='institutional-title'
              editable={ editable }
              currentPage={ institutionalPage }
              onPageChange={ setInstitutionalPage }
              projectData={ institutionalProjects }
              loading={ institutionalLoading || institutionalFetching }
            />
            {
              editable
              && (
              <ProjectList
                title={ t('organization:institution.projects.institutional.finished') }
                titleClass='institutional-title'
                editable={ editable }
                currentPage={ institutionalFinishedPage }
                onPageChange={ setInstitutionalFinishedPage }
                projectData={ institutionalFinishedProjects }
                loading={ institutionalFinishedLoading || institutionalFinishedFetching }
              />
              )
            }
          </>
        )
      }
      <div className='platform-info'>
        <h1 className='text-center'>
          { t('common:institution.projects.info.title') }
        </h1>
        <p>
          <span>
            { t('common:institution.projects.item1.title') }
          </span>
          { t('common:institution.projects.item1.description') }
        </p>
        <p>
          <span>
            { t('common:institution.projects.item2.title') }
          </span>
          { t('common:institution.projects.item2.description') }
        </p>
        <p>
          <span>
            { t('common:institution.projects.item3.title') }
          </span>
          { t('common:institution.projects.item3.description') }
        </p>
        <p>
          <span>
            { t('common:institution.projects.item4.title') }
          </span>
          { t('common:institution.projects.item4.description') }
        </p>
        <p>
          <span>
            { t('common:institution.projects.item5.title') }
          </span>
          { t('common:institution.projects.item5.description') }
        </p>
      </div>
      {
        !editable && <NeedHelpComponent />
      }
    </div>
  );
};

export default InstitutionProfilePage;
