import { Card, Col, Row }              from 'antd';
import React                           from 'react';
import { useTranslation }              from 'react-i18next';
import { useGetLatestReportCardQuery } from '../../../../../../adapters/services/reportCards.service';
import { LocalizedDateString }         from '../../../../../../components/l10n';
import { ApiProject }                  from '../../../../../interfaces/api/ApiProject';
import { ApiScore }                    from '../../../../../interfaces/api/ApiScore';
import './index.less';

type Props = {
  project: ApiProject;
}

export const ScoresSummary: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();

  const { data } = useGetLatestReportCardQuery(project.id);

  return (
    <Card id='score-summary'>
      <h2>{ t('projects.student.scores.title') }</h2>
      {
        data !== undefined && (
          <>
            {
              Object.keys(data).length === 0 && (
                <p className='score-header'>
                  { t('projects.student.scores.empty') }
                </p>
              )
            }
            {
              Object.keys(data).length !== 0 && (
                <Row gutter={[0, 10]}>
                  <Col span={24} className='score-header'>
                    { t('projects.student.scores.last_updated') }
                    {' '}
                    <LocalizedDateString date={data.updatedAt} long />
                  </Col>
                  {
                    data.scores.map((score: ApiScore) => (
                      <Col span={24} key={score.id} className='score-body d-flex justify-space-between'>
                        <b>{score.subject}</b>
                        <span>{score.score}</span>
                      </Col>
                    ))
                  }
                  <Col span={24} className='score-footer'>
                    { t('projects.student.scores.disclaimer') }
                  </Col>
                </Row>
              )
            }
          </>
        )
      }
    </Card>
  );
};
