import { Card }        from 'antd';
import parse           from 'html-react-parser';
import React           from 'react';
import { useNavigate } from 'react-router-dom';
import ApiBlogEntry    from '../../../../../interfaces/api/ApiBlogEntry';

import './index.less';

type Props = {
  blogEntry: ApiBlogEntry;
  to: string;
  textTruncateLines?: number;
}

export const BlogEntryCard: React.FC<Props> = ({ blogEntry, to, textTruncateLines }) => {
  const navigate = useNavigate();

  return (
    <Card className='blog-entry' onClick={ () => navigate(to) }>
      <img src={ blogEntry.imageUrl } alt={ blogEntry.title } className='blog-entry--image mb-4' />
      <p className='blog-entry--title text-h4 mb-4'>{ blogEntry.title }</p>
      <p
        className={ `blog - entry--description text-body1 text-black-surface mb-0 text-truncate-${textTruncateLines}` }
      >
        { parse(blogEntry.body) }
      </p>
    </Card>
  );
};
