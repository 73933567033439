import { generatePath }           from 'react-router-dom';
import { LIST_ID_TAG, USERS_TAG } from '../../core/constants/api.tags.constants';
import { USERS_URIS }             from '../../core/constants/api.uris.constants';
import { ApiUser, ApiUserCount }  from '../../core/interfaces/api/ApiUser';
import ListData                   from '../../core/interfaces/general/ListData';
import PageableInputType          from '../../core/shared/utils/pageableInputType';
import fundEducationApi           from '../apis/fundEducation.api';

type GetUsersInputType = {
  id: string;
} & PageableInputType;

export const usersApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getUserCounts: build.query<ApiUserCount, void>({
      query: () => USERS_URIS.counts,
      providesTags: () => (
        [{ type: USERS_TAG, id: LIST_ID_TAG }]
      ),
    }),
    getUsersByType: build.query<ListData<ApiUser>, GetUsersInputType>({
      query: ({ id, page = 1, perPage = 10 }) => ({
        url: `${generatePath(USERS_URIS.type, { id })}?page=${page}&perPage=${perPage}`,
        method: 'GET',
      }),
      providesTags: (result) => (
        result
          ? [
            ...result.data.map(({ id }) => ({ type: USERS_TAG, id } as const)),
            { type: USERS_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: USERS_TAG, id: LIST_ID_TAG }]
      ),
    }),
    deleteUser: build.mutation<null, ApiUser>({
      query: (payload) => ({
        url: generatePath(USERS_URIS.delete, { id: payload.id }),
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: USERS_TAG, id: LIST_ID_TAG }],
    }),
  }),
  overrideExisting: true,
});

export const { useDeleteUserMutation, useGetUserCountsQuery, useGetUsersByTypeQuery } = usersApi;
