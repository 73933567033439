import {
  Button, Col, Form, Input, Row,
} from 'antd';
import { useForm }                                       from 'antd/lib/form/Form';
import React, { useEffect }                              from 'react';
import { useTranslation }                                from 'react-i18next';
import { useAppDispatch }                                from '../../../../core/hooks/store';
import useCrudNotificationResponse                       from '../../../../core/hooks/useCrudNotificationResponse';
import { ApiOrganization }                               from '../../../../core/interfaces/api/ApiOrganization';
import { setLoader }                                     from '../../../../core/store/features/uiSlice';
import ImageField                                        from '../../../fields/ImageField';
import { ensureIsValidImage }                            from '../../../fields/ImageField/ImageValidators';
import { buildOrganizationFormData, OrganizationFields } from './helpers';
import {
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
}                                                        from '../../../../adapters/services/organizations.service';
import './style.less';

const { Item } = Form;
const { TextArea } = Input;

const initialValues: OrganizationFields = {
  name: '',
  description: '',
  location: '',
  imageUrl: undefined,
};

type Props = {
  entity?: ApiOrganization;
  onSuccess?: () => void;
}

type OrganizationCallback = (field: OrganizationFields, entity?: ApiOrganization) => void;

const CreateOrganizationForm: React.FC<Props> = ({ entity, onSuccess }) => {
  const { t } = useTranslation(['common', 'organization']);
  const dispatch = useAppDispatch();
  const [form] = useForm<OrganizationFields>();

  const [createOrganization, {
    isLoading: isCreating,
    isSuccess: created,
    error: createError,
  }] = useCreateOrganizationMutation();

  const [updateOrganization, {
    isLoading: isUpdating,
    isSuccess: updated,
    error: updateError,
  }] = useUpdateOrganizationMutation();

  useCrudNotificationResponse({
    isSuccess: created,
    error: createError,
    onClose: onSuccess,
    successNotification: {
      message: t('organization:wizard.step1.actions.save.success.title'),
      description: t('organization:wizard.step1.actions.save.success.description'),
    },
  });
  useCrudNotificationResponse({
    isSuccess: updated,
    error: updateError,
    onClose: onSuccess,
    successNotification: {
      message: t('organization:wizard.step1.actions.update.success.title'),
      description: t('organization:wizard.step1.actions.update.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isCreating || isUpdating));
  }, [dispatch, isCreating, isUpdating]);

  useEffect(() => {
    if (entity !== undefined) {
      form.setFieldsValue({
        name: entity.name,
        description: entity.description,
        location: entity.location,
        imageUrl: entity.imageUrl,
      });
    }
  }, [entity, form]);

  const handleFinish: OrganizationCallback = (fields, organization) => {
    const formData = buildOrganizationFormData(fields);

    if (organization?.id === undefined) {
      createOrganization(formData);
    } else {
      updateOrganization(formData);
    }
  };

  return (
    <Form<OrganizationFields>
      form={ form }
      layout='vertical'
      name='frmOrganization'
      requiredMark={ false }
      className='wizard-form'
      initialValues={ initialValues }
      onFinish={ (v) => handleFinish(v, entity) }
    >
      <Row gutter={ [16, 0] }>
        <Col span={ 24 } className='text-center'>
          <h4>{ t('organization:wizard.step1.form.title') }</h4>
        </Col>
        <Col span={ 24 }>
          <Item
            label={ t('organization:wizard.step1.form.name.label') }
            name='name'
            rules={ [
              {
                required: true,
                message: t('organization:wizard.step1.form.name.required'),
              },
            ] }
          >
            <Input
              name='name'
              placeholder={ t('organization:wizard.step1.form.name.placeholder') }
            />
          </Item>
        </Col>
        <Col span={ 24 }>
          <Item
            label={ t('organization:wizard.step1.form.description.label') }
            name='description'
            rules={ [
              {
                required: true,
                message: t('organization:wizard.step1.form.description.required'),
              },
            ] }
          >
            <TextArea
              name='description'
              placeholder={ t('organization:wizard.step1.form.description.placeholder') }
            />
          </Item>
        </Col>
        <Col span={ 24 }>
          <Item
            label={ t('organization:wizard.step1.form.location.label') }
            name='location'
            rules={ [
              {
                required: true,
                message: t('organization:wizard.step1.form.location.required'),
              },
            ] }
          >
            <TextArea
              name='location'
              placeholder={ t('organization:wizard.step1.form.location.placeholder') }
            />
          </Item>
        </Col>
        <Col span={ 24 }>
          <Item
            name='imageUrl'
            label={ t('organization:wizard.step1.form.image.label') }
            rules={ [ensureIsValidImage(t)] }
          >
            <ImageField />
          </Item>
        </Col>
      </Row>
      <Item>
        <Button
          block
          type='primary'
          size='large'
          htmlType='submit'
        >
          { t('common:save') }
        </Button>
      </Item>
    </Form>
  );
};

export default CreateOrganizationForm;
