import React, { useEffect, useMemo }        from 'react';
import { useKeycloak }                      from '@react-keycloak/web';
import { Modal }                            from 'antd';
import { lowerCase }                        from 'lodash';
import { initReactI18next }                 from 'react-i18next';
import { useLocation, useNavigate }         from 'react-router-dom';
import i18n                                 from 'i18next';
import detector                             from 'i18next-browser-languagedetector';
import TimeAgo                              from 'javascript-time-ago';
import en                                   from 'javascript-time-ago/locale/en.json';
import es                                   from 'javascript-time-ago/locale/es.json';
import { useGetProfileQuery }               from './adapters/services/profile.service';
import { CURRENCY_STORAGE_KEY }             from './core/constants/currencies.constants';
import { useAppDispatch, useAppSelector }   from './core/hooks/store';
import { KeycloakUserInfo }                 from './core/interfaces/store/states/AuthState';
import { setKeycloakUserInfo, setUserInfo } from './core/store/features/authSlice';
import { setCurrency }                      from './core/store/features/uiSlice';
import getConvertedRate                     from './adapters/third-party/currency.service';
import AppLoader                            from './components/AppLoader';
import APP_PATHS                            from './core/constants/routes.constants';
import AppRouter                            from './core/router';
import commonEN                             from './i18n/locales/en/common.json';
import publicEN                             from './i18n/locales/en/public.json';
import adminEN                              from './i18n/locales/en/admin.json';
import organizationEN                       from './i18n/locales/en/organization.json';
import sponsorEN                            from './i18n/locales/en/sponsor.json';
import commonES                             from './i18n/locales/es/common.json';
import publicES                             from './i18n/locales/es/public.json';
import adminES                              from './i18n/locales/es/admin.json';
import organizationES                       from './i18n/locales/es/organization.json';
import sponsorES                            from './i18n/locales/es/sponsor.json';
import { AppModalCtx }                      from './contexts/AppModalContext';

TimeAgo.addDefaultLocale(es);
TimeAgo.addLocale(en);

i18n
  .use(initReactI18next)
  .use(detector)
  .init({
    fallbackLng: 'es',
    resources: {
      es: {
        common: commonES,
        public: publicES,
        admin: adminES,
        organization: organizationES,
        sponsor: sponsorES,
      },
      en: {
        common: commonEN,
        public: publicEN,
        admin: adminEN,
        organization: organizationEN,
        sponsor: sponsorEN,
      },
    },
    ns: 'common',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useAppDispatch();
  const [mdl, ctx] = Modal.useModal();

  const location = useLocation();
  const nav = useNavigate();

  const { appLoader } = useAppSelector(({ ui }) => ui);
  const { hasError } = useAppSelector(({ auth }) => auth);

  const { keycloakUserInfo } = useAppSelector((state) => state.auth);
  const { data: userInfo } = useGetProfileQuery(undefined, { skip: keycloakUserInfo === null });

  const appModalCtxProps = useMemo(() => ({ modalFn: mdl }), [mdl]);

  useEffect(() => {
    if (keycloakUserInfo === null && !!keycloak.authenticated) {
      keycloak.loadUserInfo().then((userInfoPayload) => {
        dispatch(setKeycloakUserInfo(userInfoPayload as KeycloakUserInfo));
      });
    }
  }, [dispatch, keycloakUserInfo, keycloak, keycloak.authenticated]);

  useEffect(() => {
    if (userInfo !== undefined) {
      dispatch(setUserInfo(userInfo));
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (hasError) nav(APP_PATHS.errors.main);
  }, [hasError, nav]);

  useEffect(() => {
    Modal.destroyAll();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  useEffect(() => {
    const localStorageCurrency = localStorage.getItem(CURRENCY_STORAGE_KEY);
    if (localStorageCurrency) {
      const fetchCurrency = async () => {
        const rate = await getConvertedRate(lowerCase(localStorageCurrency));
        dispatch(setCurrency({
          code: localStorageCurrency,
          rate: rate ?? 1,
        }));
      };
      fetchCurrency().catch();
    }
  }, [dispatch]);

  return (
    <div className='app'>
      { ctx }

      <AppModalCtx.Provider value={ appModalCtxProps }>
        <>
          { ((!initialized && !hasError) || appLoader) ? <AppLoader /> : null }
          { (initialized || hasError) && <AppRouter /> }
        </>
      </AppModalCtx.Provider>
    </div>
  );
}

export default App;
