import { configureStore } from '@reduxjs/toolkit';
import fundEducationApi   from '../../adapters/apis/fundEducation.api';
import authReducer        from './features/authSlice';
import orgReducer         from './features/orgSlice';
import uiReducer          from './features/uiSlice';

const appStore = configureStore({
  reducer: {
    [fundEducationApi.reducerPath]: fundEducationApi.reducer,
    auth: authReducer,
    ui: uiReducer,
    org: orgReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(fundEducationApi.middleware),
});

export type RootState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;

export default appStore;
