import {
  Button, Checkbox, Col, Form, Input, notification, Row, Select,
} from 'antd';
import { useForm }                                         from 'antd/lib/form/Form';
import React, { FC, useEffect, useState }                  from 'react';
import { Trans, useTranslation }                           from 'react-i18next';
import { useCheckEmailMutation, useCreateAccountMutation } from '../../adapters/services/signup.service';
import { useAppDispatch }                                  from '../../core/hooks/store';
import useCrudNotificationResponse                         from '../../core/hooks/useCrudNotificationResponse';
import { ApiSignupInfo }                                   from '../../core/interfaces/api/dto/ApiSignupInfo';
import setFormErrors                                       from '../../core/shared/utils/setFormErrors';
import { setLoader }                                       from '../../core/store/features/uiSlice';
import './index.less';

const { Item } = Form;

interface Props {
  onSuccess: () => void;
}

const SignupForm: FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = useForm<ApiSignupInfo>();

  const [emailExists, setEmailExists] = useState<boolean>();

  const [checkEmail, {
    isLoading: isChecking,
    error: checkError,
  }] = useCheckEmailMutation();

  const [createAccount, {
    isLoading: isCreating,
    isSuccess: created,
    error: createError,
  }] = useCreateAccountMutation();

  const userTypes = [
    {
      label: t('public:sign_up.form.user_type.sponsor'),
      value: 'SPO',
    },
    {
      label: t('public:sign_up.form.user_type.organization'),
      value: 'ORG',
    },
  ];

  useEffect(() => {
    if (checkError !== undefined) {
      if ('status' in checkError && checkError.status === 422) {
        setFormErrors(form, checkError);
      }
    }
  }, [checkError, form]);

  useCrudNotificationResponse({
    isSuccess: created,
    error: createError,
    onClose: onSuccess,
    successNotification: {
      message: t('public:sign_up.success.title'),
      description: t('public:sign_up.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isChecking || isCreating));
  }, [dispatch, isChecking, isCreating]);

  const handleFinish = async (fields: ApiSignupInfo) => {
    if (emailExists === undefined) {
      // Step 1: Check if email already exists in sso
      const resp = await checkEmail(fields).unwrap();
      setEmailExists(resp);
    } else {
      // Step 2: Create account
      if (!form.getFieldValue('termsAccepted')) {
        notification.warn({ message: t('public:sign_up.form.terms_accepted.required') });
        return;
      }
      await createAccount(fields).unwrap();
    }
  };

  return (
    <div className='signup'>
      <h1>{ t('public:sign_up.title') }</h1>
      <Form
        layout='vertical'
        preserve={ false }
        form={ form }
        onFinish={ handleFinish }
      >
        <Row gutter={[16, 0]}>
          {
            emailExists && (
              <Col span={ 24 }>
                <p>{ t('public:sign_up.existing_account') }</p>
              </Col>
            )
          }
          <Col span={24}>
            <Item
              label={ t('public:sign_up.form.email.label') }
              name='email'
              rules={ [
                {
                  required: true,
                  message: t('public:sign_up.form.email.required'),
                },
              ] }
            >
              <Input placeholder={ t('public:sign_up.form.email.placeholder') } disabled={ emailExists !== undefined } />
            </Item>
          </Col>
          {
            emailExists !== undefined && (
              <>
                <Col span={24}>
                  <Item
                    label={ t('public:sign_up.form.first_name.label') }
                    name='firstName'
                    rules={ [
                      {
                        required: true,
                        message: t('public:sign_up.form.first_name.required'),
                      },
                    ] }
                  >
                    <Input placeholder={ t('public:sign_up.form.first_name.placeholder') } />
                  </Item>
                </Col>
                <Col span={24}>
                  <Item
                    label={ t('public:sign_up.form.last_name.label') }
                    name='lastName'
                    rules={ [
                      {
                        required: true,
                        message: t('public:sign_up.form.last_name.required'),
                      },
                    ] }
                  >
                    <Input placeholder={ t('public:sign_up.form.last_name.placeholder') } />
                  </Item>
                </Col>
                { !emailExists && (
                  <Col span={24}>
                    <Item
                      label={ t('public:sign_up.form.password.label') }
                      name='password'
                      rules={ [
                        {
                          required: true,
                          message: t('public:sign_up.form.password.required'),
                        },
                        {
                          min: 8,
                          message: t('profile.form.new_password.min'),
                        },
                      ] }
                    >
                      <Input.Password placeholder={ t('public:sign_up.form.password.placeholder') } />
                    </Item>
                  </Col>
                ) }
                <Col span={ 24 }>
                  <Item
                    name='userType'
                    label={ t('public:sign_up.form.user_type.label') }
                    rules={ [
                      { required: true, message: t('public:sign_up.form.user_type.required') },
                    ] }
                  >
                    <Select
                      options={ userTypes?.map(({ value, label }) => ({ value, label }))}
                    />
                  </Item>
                </Col>
                <Col span={24}>
                  <Item
                    name='termsAccepted'
                    valuePropName='checked'
                  >
                    <Checkbox>
                      <Trans i18nKey='public:sign_up.form.terms_accepted.label'>
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        I accept the <Button className='tc-btn' type='link'>terms and conditions</Button>.
                      </Trans>
                    </Checkbox>
                  </Item>
                </Col>
              </>
            )
          }
          <Col span={24}>
            <Item>
              <Button
                block
                type='primary'
                size='large'
                htmlType='submit'
              >
                { t('common:next') }
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SignupForm;
