/* eslint-disable consistent-return */
import { RcFile }        from 'antd/lib/upload/interface';
import { ValidatorRule } from 'rc-field-form/lib/interface';
import { TFunction }     from 'react-i18next';
import getImageSize      from '../../../core/shared/utils/getImageSize';

type EnsureIsValidImageType = (t: TFunction) => ValidatorRule;
type ValidateMinimumImageSizeType =
  (t: TFunction, minWidth: number, minHeight: number) => ValidatorRule;
type ValidateImageSizeType =
  (t: TFunction, imageWidth: number, imageHeight: number) => ValidatorRule;
type ValidateImageAspectRatioType =
  (t: TFunction, ratioWidth: number, ratioHeight: number) => ValidatorRule;

type ImageType = RcFile | undefined | string;

export const ensureIsValidImage: EnsureIsValidImageType = (t) => ({
  validator: async (_, image: ImageType): Promise<Error | undefined> => {
    if (image === undefined) return Promise.reject(new Error(t('image_field.invalid_file')));
  },
});

export const validateMinimumImageSize: ValidateMinimumImageSizeType = (
  t,
  minWidth,
  minHeight,
) => ({
  validator: async (_, image: ImageType): Promise<Error | undefined> => {
    if (typeof image === 'object' && image != null) {
      const { width, height } = await getImageSize(image);

      if (width < minWidth || height < minHeight) {
        return Promise.reject(
          new Error(t('image_field.minimum_size', { minWidth, minHeight })),
        );
      }
    }
  },
});

export const validateImageSize: ValidateImageSizeType = (
  t,
  imageWidth,
  imageHeight,
) => ({
  validator: async (_, image: ImageType): Promise<Error | undefined> => {
    if (typeof image === 'object' && image != null) {
      const { width, height } = await getImageSize(image);

      if (width !== imageWidth || height !== imageHeight) {
        return Promise.reject(
          new Error(t('image_field.image_size', { imageWidth, imageHeight })),
        );
      }
    }
  },
});

export const validateImageAspectRatio: ValidateImageAspectRatioType = (
  t,
  ratioWidth,
  ratioHeight,
) => ({
  validator: async (_, image: ImageType): Promise<Error | undefined> => {
    if (typeof image === 'object' && image != null) {
      const { width, height } = await getImageSize(image);
      const imageAspectRatio = width / height;

      const requiredRatio = ratioWidth / ratioHeight;
      const minAspectRatio = Math.floor(requiredRatio * 10) / 10;
      const maxAspectRatio = Math.ceil(requiredRatio * 10) / 10;

      if (imageAspectRatio < minAspectRatio || imageAspectRatio > maxAspectRatio) {
        return Promise.reject(
          new Error(t('image_field.aspect_ratio', { ratioWidth, ratioHeight })),
        );
      }
    }
  },
});
