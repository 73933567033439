import { SIGNUP_URIS }   from '../../core/constants/api.uris.constants';
import { ApiEmail }      from '../../core/interfaces/api/dto/ApiEmail';
import { ApiSignupInfo } from '../../core/interfaces/api/dto/ApiSignupInfo';
import fundEducationApi  from '../apis/fundEducation.api';

export const signupApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    checkEmail: build.mutation<boolean, ApiEmail>({
      query: (body) => ({
        url: SIGNUP_URIS.emailCheck,
        method: 'POST',
        body,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createAccount: build.mutation<any, ApiSignupInfo>({
      query: (body) => ({
        url: SIGNUP_URIS.signup,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useCheckEmailMutation,
  useCreateAccountMutation,
} = signupApi;
