import {
  Button, Col, Form, FormInstance, Input, InputNumber, Row, Switch,
}                                           from 'antd';
import * as React         from 'react';
import { useEffect }      from 'react';
import { useTranslation } from 'react-i18next';
import ApiExample         from '../../../../core/interfaces/api/ApiExample';

const { Item } = Form;

export type ExampleFields = {
  name: string;
  price: number;
  sequential: boolean;
}

export type ExamplesCallback = (field: ExampleFields, entity?: ApiExample) => void;

type Props = {
  entity?: ApiExample;
  onFinish: ExamplesCallback;
  form: FormInstance<ExampleFields>;
}

const initialValues: ExampleFields = {
  name: '',
  price: 1,
  sequential: false,
};

const ExampleForm: React.FC<Props> = ({
  entity, form, onFinish,
}) => {
  const { t } = useTranslation(['common', 'admin']);

  useEffect(() => {
    if (entity !== undefined) {
      form.setFieldsValue({
        name: entity.name,
        price: entity.price,
        sequential: entity.sequential,
      });
    }
  }, [entity, form]);

  return (
    <Form<ExampleFields>
      layout='vertical'
      name='frmExample'
      requiredMark={ false }
      form={ form }
      initialValues={ initialValues }
      preserve={ false }
      onFinish={ (v) => onFinish(v, entity) }
    >
      <Row gutter={ [16, 0] }>
        <Col span={ 12 }>
          <Item
            label={ t('admin:example.title') }
            name='name'
            rules={ [
              {
                required: true,
                message: t('admin:example.validation.title.required'),
              },
            ] }
          >
            <Input name='name' placeholder='Ingrese un título' />
          </Item>
        </Col>
        <Col span={ 12 }>
          <Item
            label={ t('admin:example.sequential') }
            name='sequential'
            valuePropName='checked'
          >
            <Switch />
          </Item>
        </Col>
        <Col span={ 12 }>
          <Item
            label={ t('admin:example.price') }
            name='price'
            rules={ [
              {
                required: true,
                message: t('admin:example.validation.price.required'),
              },
            ] }
          >
            <InputNumber
              min={ 0 }
              max={ 100 }
              precision={ 2 }
              step={ 0.01 }
            />
          </Item>
        </Col>
      </Row>

      <Item>
        <Button
          block
          type='primary'
          size='large'
          htmlType='submit'
        >
          { t('common:save') }
        </Button>
      </Item>
    </Form>
  );
};

export default ExampleForm;
