import React, { useEffect, useState }           from 'react';
import {
  matchRoutes, Navigate, Outlet, useLocation,
} from 'react-router-dom';
import { useGetOrganizationPetitionQuery } from '../../../adapters/services/organizations.service';
import APP_PATHS                           from '../../constants/routes.constants';
import { useAppDispatch, useAppSelector }  from '../../hooks/store';
import { PetitionStatusEnum }              from '../../interfaces/api/ApiOrganizationPetition';
import { setPetitionStatus }               from '../../store/features/orgSlice';

const OrganizationMiddleware: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { petitionStatus } = useAppSelector((state) => state.org);
  const [isInWizard, setIsInWizard] = useState<boolean>(false);
  const [isInInstitutions, setIsInInstitutions] = useState<boolean>(false);
  const [output, setOutput] = useState<React.ReactElement>(<Outlet />);

  const { data } = useGetOrganizationPetitionQuery();

  useEffect(() => {
    if (data !== undefined) {
      const { petitionStatusId } = data;
      dispatch(setPetitionStatus({
        notCreated: petitionStatusId === undefined,
        incomplete: petitionStatusId === PetitionStatusEnum.INCOMPLETE,
        pending: petitionStatusId === PetitionStatusEnum.PENDING,
        approved: petitionStatusId === PetitionStatusEnum.APPROVED,
        rejected: petitionStatusId === PetitionStatusEnum.REJECTED,
      }));
    }
  }, [data, setPetitionStatus]);

  useEffect(() => {
    const match = matchRoutes([{ path: APP_PATHS.organization.join }], location);
    setIsInWizard(match !== null);
  }, [location, setIsInWizard]);

  useEffect(() => {
    const match = matchRoutes([{ path: APP_PATHS.organization.institutions }], location);
    setIsInInstitutions(match !== null);
  }, [location, setIsInInstitutions]);

  useEffect(() => {
    if (isInWizard && (petitionStatus.pending || petitionStatus.approved)) {
      setOutput(<Navigate to={ APP_PATHS.organization.institutions } />);
    } else if (!isInWizard
      && (petitionStatus.notCreated || petitionStatus.incomplete || petitionStatus.rejected)
    ) {
      setOutput(<Navigate to={ APP_PATHS.organization.join } />);
    } else if (!isInWizard && !isInInstitutions
      && (petitionStatus.notCreated || petitionStatus.incomplete
        || petitionStatus.pending || petitionStatus.rejected
      )) {
      setOutput(<Navigate to={ APP_PATHS.organization.institutions } />);
    } else {
      setOutput(<Outlet />);
    }
  }, [petitionStatus, isInWizard, isInInstitutions]);

  return output;
};

export default OrganizationMiddleware;
