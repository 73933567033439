import React, { useEffect }                      from 'react';
import { useForm }                               from 'antd/lib/form/Form';
import { useTranslation }                        from 'react-i18next';
import {
  Button, Card, Col, Form, Input, Row, Select,
}                                                                            from 'antd';
import {
  generatePath, useNavigate, useParams,
}                                                                            from 'react-router-dom';
import { GoBackButton }                                                      from '../../../../components/buttons';
import { ensureIsValidImage }                                                from '../../../../components/fields/ImageField/ImageValidators';
import { buildInstitutionFormData, InstitutionFields, InstitutionsCallback } from './helpers';
import useInstitutionEffects                                                 from './useInstitutionEffects';
import { useGetCountriesQuery }                                              from '../../../../adapters/services/countries.service';
import { useGetInstitutionQuery }                                            from '../../../../adapters/services/institutions.service';
import ImageField                                                            from '../../../../components/fields/ImageField';
import APP_PATHS                                                             from '../../../constants/routes.constants';

import './index.less';

const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;

const initialValues: InstitutionFields = {
  name: '',
  description: '',
  imageUrl: undefined,
  countryId: '',
  images: [],
};

const ManageInstitutionPage = () => {
  const { t } = useTranslation();
  const { institutionId } = useParams();
  const navigate = useNavigate();

  const onClose = () => {
    navigate(APP_PATHS.organization.institutions);
  };

  const [form] = useForm<InstitutionFields>();

  const { data: institution } = useGetInstitutionQuery(
    institutionId,
    { skip: institutionId === undefined },
  );
  const { data: countries } = useGetCountriesQuery();

  const { createInstitution, updateInstitution } = useInstitutionEffects({ t, form, onClose });

  const handleFinish: InstitutionsCallback = (fields) => {
    const formData = buildInstitutionFormData(fields);
    if (institution === undefined) {
      createInstitution(formData);
    } else {
      for (let i = 0; i < 6; i += 1) {
        const imageId = form.getFieldValue('images')[i].id;
        formData.append(`images[${i}][id]`, imageId);
      }
      updateInstitution({
        id: institution.id,
        body: formData,
      });
    }
  };

  useEffect(() => {
    if (institution !== undefined) {
      form.setFieldsValue({
        name: institution.name,
        description: institution.description,
        imageUrl: institution.imageUrl,
        countryId: institution.countryId,
        images: institution.images.map((image) => ({
          id: image.id,
          url: image.url,
        })),
      });
    }
  }, [institution, form]);

  const goBack = () => navigate(institutionId === undefined
    ? APP_PATHS.organization.institutions
    : generatePath(APP_PATHS.organization.institution, { institutionId }));

  return (
    <Row justify='center'>
      <Col xs={ 24 } md={ 20 } xl={ 14 }>
        <GoBackButton onClick={ goBack } />
        <Card className='card'>
          <div className='text-h2 mb-8'>
            {
              institutionId === undefined
                ? t('organization:institution.create.title')
                : t('organization:institution.update.title')
            }
          </div>
          <div className='card-body'>
            <Form<InstitutionFields>
              autoComplete='off'
              form={ form }
              layout='vertical'
              name='frmInstitution'
              requiredMark={ false }
              initialValues={ initialValues }
              onFinish={ (v) => handleFinish(v) }
            >
              <Row gutter={ [16, 8] }>
                <Col span={24}>
                  <Item
                    label={ t('organization:institution.form.name.label') }
                    name='name'
                    className='text-body-bold'
                    rules={[
                      {
                        required: true,
                        message: t('organization:institution.form.name.required'),
                      },
                    ]}
                  >
                    <Input />
                  </Item>
                </Col>
                <Col span={24}>
                  <Item
                    label={ t('organization:institution.form.logo.label') }
                    name='imageUrl'
                    className='text-body-bold'
                    rules={[
                      {
                        required: true,
                        message: t('organization:institution.form.logo.required'),
                      },
                    ]}
                  >
                    <ImageField />
                  </Item>
                </Col>
                <Col span={24}>
                  <Item
                    label={ t('organization:institution.form.description.label') }
                    name='description'
                    className='text-body-bold'
                    rules={[
                      {
                        required: true,
                        message: t('organization:institution.form.description.required'),
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Item>
                </Col>
                <Col span={24}>
                  <Item
                    label={ t('organization:institution.form.country.label') }
                    name='countryId'
                    className='text-body-bold'
                    rules={[
                      {
                        required: true,
                        message: t('organization:institution.form.country.required'),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={ t('organization:institution.form.country.placeholder') }
                      filterOption={
                        (input, option) => (option?.children as unknown as string)
                          .toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {
                        countries?.map((country) => (
                          <Option key={country.id} value={country.id}>
                            { country.name }
                          </Option>
                        ))
                      }
                    </Select>
                  </Item>
                </Col>
                <Col span={24}>
                  <div
                    className='text-body-bold'
                  >
                    <p>{t('organization:institution.form.images.label')}</p>
                    <Row gutter={[16, 16]}>
                      <Col className='gutter-row' xs={24} md={12} xl={8}>
                        <Item
                          name={['images', 0, 'url']}
                          rules={ [
                            {
                              required: true,
                              message: t('organization:institution.form.images.required'),
                            },
                            ensureIsValidImage(t),
                          ] }
                        >
                          <ImageField />
                        </Item>
                      </Col>
                      <Col className='gutter-row' xs={24} md={12} xl={8}>
                        <Item
                          name={['images', 1, 'url']}
                          rules={ [
                            {
                              required: true,
                              message: t('organization:institution.form.images.required'),
                            },
                            ensureIsValidImage(t),
                          ] }
                        >
                          <ImageField />
                        </Item>
                      </Col>
                      <Col className='gutter-row' xs={24} md={12} xl={8}>
                        <Item
                          name={['images', 2, 'url']}
                          rules={ [
                            {
                              required: true,
                              message: t('organization:institution.form.images.required'),
                            },
                            ensureIsValidImage(t),
                          ] }
                        >
                          <ImageField />
                        </Item>
                      </Col>
                      <Col className='gutter-row' xs={24} md={12} xl={8}>
                        <Item
                          name={['images', 3, 'url']}
                          rules={ [
                            {
                              required: true,
                              message: t('organization:institution.form.images.required'),
                            },
                            ensureIsValidImage(t),
                          ] }
                        >
                          <ImageField />
                        </Item>
                      </Col>
                      <Col className='gutter-row' xs={24} md={12} xl={8}>
                        <Item
                          name={['images', 4, 'url']}
                          rules={ [
                            {
                              required: true,
                              message: t('organization:institution.form.images.required'),
                            },
                            ensureIsValidImage(t),
                          ] }
                        >
                          <ImageField />
                        </Item>
                      </Col>
                      <Col className='gutter-row' xs={24} md={12} xl={8}>
                        <Item
                          name={['images', 5, 'url']}
                          rules={ [
                            {
                              required: true,
                              message: t('organization:institution.form.images.required'),
                            },
                            ensureIsValidImage(t),
                          ] }
                        >
                          <ImageField />
                        </Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={24}>
                  <Button type='primary' block htmlType='submit'>
                    {
                      institutionId === undefined
                        ? t('organization:institution.create.save')
                        : t('organization:institution.update.modify')
                    }
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default ManageInstitutionPage;
