import React                 from 'react';
import { useTranslation }    from 'react-i18next';
import { NeedHelpComponent } from '../../../../../../components/NeedHelpComponent';

import './index.less';

export const HomeStepsToRegister: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='home-steps'>

      <NeedHelpComponent />

      <div className='container mx-auto'>
        <div className='home-steps steps--title text-h3 text-center mb-8'>
          { t('public:steps_to_register.how_it_works.title') }
        </div>

        <div className='home-steps steps--container'>
          <div className='text-center'>
            <div className='text-h4 text-primary mb-2'>
              { t('public:steps_to_register.how_it_works.step_1.title') }
            </div>
            <div className='text-body-bold text-black-surface'>
              { t('public:steps_to_register.how_it_works.step_1.subtitle') }
            </div>
            <div className='text-body1 text-gray'>
              { t('public:steps_to_register.how_it_works.step_1.description') }
            </div>
          </div>
          <div className=' text-center'>
            <div className='text-h4 text-primary mb-2'>
              { t('public:steps_to_register.how_it_works.step_2.title') }
            </div>
            <div className='text-body-bold text-black-surface'>
              { t('public:steps_to_register.how_it_works.step_2.subtitle') }
            </div>
            <div className='text-body1 text-gray'>
              { t('public:steps_to_register.how_it_works.step_2.description') }
            </div>
          </div>
          <div className=' text-center'>
            <div className='text-h4 text-primary mb-2'>
              { t('public:steps_to_register.how_it_works.step_3.title') }
            </div>
            <div className='text-body-bold text-black-surface'>
              { t('public:steps_to_register.how_it_works.step_3.subtitle') }
            </div>
            <div className='text-body1 text-gray'>
              { t('public:steps_to_register.how_it_works.step_3.description') }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
