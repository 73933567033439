import { generatePath }                     from 'react-router-dom';
import { BLOG_TAGS_TAG, LIST_ID_TAG }       from '../../core/constants/api.tags.constants';
import { BLOG_TAGS_URIS }                   from '../../core/constants/api.uris.constants';
import ApiBlogTag, { CreateBlogTagPayload } from '../../core/interfaces/api/ApiBlogTag';
import fundEducationApi                     from '../apis/fundEducation.api';

export const blogTagsApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getBlogTags: build.query<ApiBlogTag[], void>({
      query: () => BLOG_TAGS_URIS.root,
      providesTags: (result) => (
        result
          ? [
            ...result.map(({ id }) => ({ type: BLOG_TAGS_TAG, id } as const)),
            { type: BLOG_TAGS_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: BLOG_TAGS_TAG, id: LIST_ID_TAG }]
      ),
    }),
    createBlogTag: build.mutation<null, CreateBlogTagPayload>({
      query: (body) => ({
        url: BLOG_TAGS_URIS.root,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: BLOG_TAGS_TAG, id: LIST_ID_TAG }],
    }),
    deleteBlogTag: build.mutation<null, string>({
      query: (id) => ({
        url: generatePath(BLOG_TAGS_URIS.action, { id }),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [{ type: BLOG_TAGS_TAG, id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetBlogTagsQuery,
  useCreateBlogTagMutation,
  useDeleteBlogTagMutation,
} = blogTagsApi;
