import { QuestionCircleOutlined }                                  from '@ant-design/icons';
import {
  Button, Col, Divider, Form, FormInstance, Input, Row, Tooltip,
}                                                     from 'antd';
import { IMask, MaskedInput }                         from 'antd-mask-input';
import { MaskType }                                   from 'antd-mask-input/build/main/lib/MaskedInput';
import React                                          from 'react';
import { Trans, useTranslation }                      from 'react-i18next';
import { useGetChargedAmountQuery }                   from '../../../../../../adapters/services/donations.service';
import { AmericanExpressImg, MasterCardImg, VisaImg } from '../../../../../../components/assets/Images';
import { GoBackButton }                               from '../../../../../../components/buttons';
import TitleImage                                     from '../../../../../../components/projects/TitleImage';
import { DonationFields }                             from '../../../../../interfaces/api/ApiDonation';
import { ApiProject }                                 from '../../../../../interfaces/api/ApiProject';
import './index.less';

const { Item } = Form;

type Props = {
  project: ApiProject | undefined;
  form: FormInstance<DonationFields>;
  onPrev: () => void;
}

export const CreditCardPanel: React.FC<Props> = ({ project, form, onPrev }) => {
  const { t } = useTranslation(['common', 'sponsor']);

  const { data: chargedAmountInfo, isLoading, isFetching } = useGetChargedAmountQuery(form?.getFieldValue('amount'));

  const feeRate = (chargedAmountInfo?.feeRate ?? 0) * 100;
  const amount = Number(chargedAmountInfo?.amount ?? 0).toFixed(2);

  const maskExp = React.useMemo<MaskType>(() => [
    {
      mask: 'MONTH/00',
      blocks: {
        MONTH: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
      },
    },
  ], []);

  return (
    <Row id='donation-credit-card-form' gutter={[16, 24]}>
      <Col span={24}>
        <GoBackButton onClick={onPrev}>
          { t('common:go_back') }
        </GoBackButton>
      </Col>
      <Col span={24}>
        <p className='text-green-bold text-center'>
          { t('sponsor:donations.disclaimers.security') }
        </p>
      </Col>
      <Col span={24}>
        <TitleImage
          title={project?.title}
          imageUrl={project?.imageUrl}
        />
      </Col>
      <Col span={24}>
        <h3 id='amount-text'>
          <span>{ t('sponsor:donations.support_amount') }</span>
          <span>
            {
              (isLoading || isFetching) && t('sponsor:donations.loading')
            }

            {
              chargedAmountInfo !== undefined && (
                <>
                  $
                  { Number(chargedAmountInfo.chargedAmount).toFixed(2) }
                  <Tooltip
                    className='support-tooltip'
                    title={(
                      <Trans i18nKey='sponsor:donations.disclaimers.fee' feeRate={ feeRate } amount={ amount }>
                        {/* eslint-disable */}
                        Fund Education applies a fee of <strong>{{ feeRate }}</strong> of service to your $<strong>{{ amount }}</strong> donation.
                      </Trans>
                    )}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              )
            }
          </span>
        </h3>
        <Divider />
      </Col>
      <Col span={24}>
        <p className='text-bold'>
          { t('sponsor:donations.credit_cards') }
        </p>
        <div className='credit-card-container'>
          <VisaImg />
        </div>
        <div className='credit-card-container'>
          <MasterCardImg />
        </div>
        <div className='credit-card-container'>
          <AmericanExpressImg />
        </div>
      </Col>
      <Col span={24}>
        <Item
          label={ t('sponsor:donations.form.card_number.label') }
          name='cardNumber'
          rules={ [
            {
              required: true,
              message: t('sponsor:donations.form.card_number.required'),
            },
          ] }
        >
          <MaskedInput
            name='cardNumber'
            mask='0000 0000 0000 0000'
            placeholder={ t('sponsor:donations.form.card_number.placeholder') }
          />
        </Item>
      </Col>
      <Col span={12}>
        <Item
          label={ t('sponsor:donations.form.expiration_date.label') }
          name='expirationDate'
          rules={ [
            {
              required: true,
              message: t('sponsor:donations.form.expiration_date.required'),
            },
          ] }
        >
          <MaskedInput
            mask={ maskExp }
            name='expirationDate'
            placeholder={ t('sponsor:donations.form.expiration_date.placeholder') }
          />
        </Item>
      </Col>
      <Col span={12}>
        <Item
          label={ t('sponsor:donations.form.cvv.label') }
          name='cvv'
          rules={ [
            {
              required: true,
              message: t('sponsor:donations.form.cvv.required'),
            },
          ] }
        >
          <MaskedInput
            name='cvv'
            mask='000'
            placeholder={ t('sponsor:donations.form.cvv.placeholder') }
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={ t('sponsor:donations.form.card_holder.label') }
          name='cardHolder'
          rules={ [
            {
              required: true,
              message: t('sponsor:donations.form.card_holder.required'),
            },
          ] }
        >
          <Input
            name='cardHolder'
            placeholder={ t('sponsor:donations.form.card_holder.placeholder') }
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={ t('sponsor:donations.form.email.label') }
          name='email'
          rules={ [
            {
              required: true,
              message: t('sponsor:donations.form.email.required'),
            },
            {
              type: 'email',
              message: t('sponsor:donations.form.email.email'),
            },
          ] }
        >
          <Input
            name='email'
            placeholder={ t('sponsor:donations.form.email.placeholder') }
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item>
          <Button
            block
            type='primary'
            size='large'
            htmlType='submit'
          >
            { t('sponsor:donations.save') }
          </Button>
        </Item>
      </Col>
    </Row>
  );
};
