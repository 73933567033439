import { Form, Select }   from 'antd';
import * as React         from 'react';
import { useTranslation } from 'react-i18next';
import { ApiStudent }     from '../../../interfaces/api/ApiStudent';

const { Item } = Form;

type Props = {
  students: ApiStudent[];
  selectStudent: (studentId: string) => void;
};

const StudentSelector: React.FC<Props> = ({ students, selectStudent }) => {
  const { t } = useTranslation('organization');

  return (
    <Form layout='vertical'>
      <Item label={ t('organization:projects.form.students.search.label') }>
        <Select
          placeholder={ t('organization:projects.form.students.search.placeholder') }
          getPopupContainer={ (trigger) => trigger.parentNode }
          options={ students.map((student) => ({
            value: student.id,
            label: `${student.firstName} ${student.lastName}`,
          }))}
          onChange={ selectStudent }
        />
      </Item>
    </Form>
  );
};

export default StudentSelector;
