import React, {
  useCallback, useEffect, useState,
}                         from 'react';
import { Col, Menu, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { MenuItemType }   from '../../core/constants/menu.constants';
import MenuLink           from './MenuLink';
import './style.less';

type Props = {
  items: MenuItemType[];
  selectedKey?: number;
}

type AntMenuItemType = {
  key: string;
  label: React.ReactNode;
}

const MenuBar = ({ items, selectedKey = 0 }: Props) => {
  const { t, i18n: { language } } = useTranslation();
  const [currentKey, setCurrentKey] = useState(selectedKey);
  const [menuItems, setMenuItems] = useState<AntMenuItemType[]>([]);

  const getMenuItems = useCallback((): AntMenuItemType[] => {
    const newMenuItems: AntMenuItemType[] = [];
    items.forEach((item, key) => newMenuItems.push(
      {
        key: key.toString(),
        label: <MenuLink
          title={ t(item.i18nTitle) }
          to={ item.route }
          onMatch={ () => setCurrentKey(key) }
        />,
      },
    ));
    return newMenuItems;
  }, [items, setCurrentKey, t]);

  useEffect(
    () => setMenuItems(getMenuItems()),
    [language, items, setMenuItems, getMenuItems],
  );

  return (
    <Row className='menu-bar-row'>
      <Col span={ 24 }>
        <Menu
          mode='horizontal'
          className='menu-bar'
          selectedKeys={ [currentKey.toString()] }
          items={ menuItems }
        />
      </Col>
    </Row>
  );
};

export default MenuBar;
