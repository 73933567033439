import { Button }      from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import React           from 'react';
import './index.less';

export const OutlinedButton: React.FC<ButtonProps> = (props) => {
  const { children, className } = props;
  return (
    <Button
      block
      ghost
      { ...props }
      className={ `outlined-btn ${className ?? ''}` }
    >
      { children }
    </Button>
  );
};
