import { LIST_ID_TAG, PLATFORM_COUNT_PUBLIC_TAG } from '../../core/constants/api.tags.constants';
import { HOME_URIS }                              from '../../core/constants/api.uris.constants';
import { ApiPlatformCount }                       from '../../core/interfaces/api/dto/ApiPlatformCount';
import fundEducationApi                           from '../apis/fundEducation.api';

export const homeApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getPlatformCount: build.query<ApiPlatformCount, void>({
      query: () => HOME_URIS.platformCount,
      providesTags: () => (
        [{ type: PLATFORM_COUNT_PUBLIC_TAG, id: LIST_ID_TAG }]
      ),
    }),
  }),
  overrideExisting: true,
});

export const { useGetPlatformCountQuery } = homeApi;
