import React                   from 'react';
import { Select, SelectProps } from 'antd';
import { ChevronDown }         from 'react-feather';

import './index.less';

export const CustomSelect: React.FC<SelectProps> = (props) => {
  const { children } = props;

  return (
    <Select { ...props } className='custom-select' suffixIcon={ <ChevronDown /> }>
      { children }
    </Select>
  );
};
