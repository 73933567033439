/* eslint-disable max-len */
import React                 from 'react';
import {
  Navigate, Route, Routes,
}                                       from 'react-router-dom';
import AppError                         from '../../components/errors/AppError';
import NotAllowed                       from '../../components/errors/NotAllowed';
import NotFound                         from '../../components/errors/NotFound';
import APP_PATHS                        from '../constants/routes.constants';
import { ADMIN, ORGANIZATION, SPONSOR } from '../hooks/useGetRoles';
import ListBlogEntriesPage              from '../pages/admin/ListBlogEntriesPage';
import ListDonationsPage                from '../pages/admin/ListDonationsPage';
import ListExamplesPage                 from '../pages/admin/ListExamplesPage';
import ListGradesPage                   from '../pages/admin/ListGradesPage';
import ListOrganizationPetitionsPage    from '../pages/admin/ListOrganizationPetitionsPage';
import ListOrganizationsPage            from '../pages/admin/ListOrganizationsPage';
import ListProjectCategoryTypesPage     from '../pages/admin/ListProjectCategoryTypesPage';
import ListUsersPage                    from '../pages/admin/ListUsersPage';
import ListUsersByTypePage              from '../pages/admin/ListUsersByTypePage';
import ManageGradePage                  from '../pages/admin/ManageGradePage';
import AdminLayout                      from '../pages/layouts/admin.layout';
import MainLayout                       from '../pages/layouts/main.layout';
import OrganizationProjectsLayout       from '../pages/layouts/organization/organization-projects.layout';
import OrganizationLayout               from '../pages/layouts/organization/organization.layout';
import PublicLayout                     from '../pages/layouts/public.layout';
import SimplePublicLayout               from '../pages/layouts/simple-public.layout';
import SponsorLayout                    from '../pages/layouts/sponsor.layout';
import ListReportCardsPage              from '../pages/organization/ListReportCardsPage';
import ManageProjectPage                from '../pages/organization/ManageProjectPage';
import ManageUpdatePage                 from '../pages/organization/ManageUpdatePage';
import ManageInstitutionPage            from '../pages/organization/ManageInstitutionPage';
import EditOrganizationPage             from '../pages/organization/EditOrganizationPage';
import JoinWizardPage                   from '../pages/organization/JoinWizardPage';
import ListInstitutionsPage             from '../pages/organization/ListInstitutionsPage';
import ProjectListPage                  from '../pages/organization/ProjectListPage';
import BlogPage                         from '../pages/public/BlogPage';
import BlogEntryPage                    from '../pages/public/BlogEntryPage';
import DiscoverPage                     from '../pages/public/DiscoverPage';
import HomePublicPage                   from '../pages/public/HomePage';
import InstitutionProfilePage           from '../pages/shared/InstitutionProfilePage';
import ProjectDetailPage                from '../pages/shared/ProjectDetailPage';
import UserProfilePage                  from '../pages/shared/UserProfilePage';
import DonationFormPage                 from '../pages/public/DonationFormPage';
import DonationThanksPage               from '../pages/public/DonationThanksPage';
import SponsorHomePage                  from '../pages/sponsor/SponsorHomePage';
import AuthOrPublicMiddleware           from './middlewares/AuthOrPublicMiddleware';
import OrganizationMiddleware           from './middlewares/OrganizationMiddleware';
import AuthMiddleware                   from './middlewares/AuthMiddleware';

function AppRouter() {
  return (
    <Routes>
      <Route element={ <SimplePublicLayout /> }>
        <Route path={ APP_PATHS.home } element={ <HomePublicPage /> } />
        <Route path={ APP_PATHS.public.blog.see } element={ <BlogEntryPage /> } />
        <Route path='/' element={ <Navigate to={ APP_PATHS.home } /> } />
      </Route>
      <Route element={ <MainLayout /> }>
        <Route element={ <PublicLayout />}>
          <Route path={ APP_PATHS.public.discover } element={ <DiscoverPage /> } />
          <Route path={ APP_PATHS.public.blog.root } element={ <BlogPage /> } />
          <Route path={ APP_PATHS.public.projectDetail } element={ <ProjectDetailPage /> } />
          <Route path={ APP_PATHS.shared.profile } element={ <UserProfilePage /> } />

          <Route element={ <AuthOrPublicMiddleware role={ SPONSOR } /> }>
            <Route path={ APP_PATHS.public.donationForm } element={ <DonationFormPage /> } />
            <Route path={ APP_PATHS.public.donationThanks } element={ <DonationThanksPage /> } />
          </Route>
        </Route>

        <Route element={ <AdminLayout /> }>
          <Route element={ <AuthMiddleware role={ ADMIN } /> }>
            <Route path={ APP_PATHS.admin.root } element={ <ListBlogEntriesPage /> } />
            <Route path={ APP_PATHS.admin.paymentGateways } element={ <ListExamplesPage /> } />
            <Route path={ APP_PATHS.admin.blogEntries } element={ <ListBlogEntriesPage /> } />
            <Route path={ APP_PATHS.admin.petitions } element={<ListOrganizationPetitionsPage />} />
            <Route path={ APP_PATHS.admin.users } element={ <ListUsersPage /> } />
            <Route path={ APP_PATHS.admin.organizations } element={ <ListOrganizationsPage /> } />
            <Route path={ APP_PATHS.admin.usersType } element={ <ListUsersByTypePage /> } />
            <Route path={ APP_PATHS.admin.projectCategoryTypes } element={ <ListProjectCategoryTypesPage /> } />
            <Route path={ APP_PATHS.admin.grades.root } element={ <ListGradesPage /> } />
            <Route path={ APP_PATHS.admin.grades.create } element={ <ManageGradePage /> } />
            <Route path={ APP_PATHS.admin.grades.edit } element={ <ManageGradePage /> } />
            <Route path={ APP_PATHS.admin.donations } element={ <ListDonationsPage /> } />
          </Route>
        </Route>

        <Route element={ <AuthMiddleware role={ ORGANIZATION } /> }>
          <Route element={ <OrganizationMiddleware />}>
            <Route path={ APP_PATHS.organization.join } element={ <JoinWizardPage /> } />
            <Route element={ <OrganizationLayout /> }>
              <Route path={ APP_PATHS.organization.edit } element={ <EditOrganizationPage /> } />
              <Route path={ APP_PATHS.organization.institutions } element={ <ListInstitutionsPage /> } />
              <Route path={ APP_PATHS.organization.createInstitution } element={ <ManageInstitutionPage /> } />
              <Route path={ APP_PATHS.organization.editInstitution } element={ <ManageInstitutionPage /> } />
              <Route path={ APP_PATHS.organization.institution } element={ <InstitutionProfilePage editable /> } />
              <Route path={ APP_PATHS.organization.newStudentProject } element={ <ManageProjectPage newStudent /> } />
              <Route path={ APP_PATHS.organization.newInstitutionalProject } element={ <ManageProjectPage newInstitutional /> } />
              <Route path={ APP_PATHS.organization.projectRoot } element={ <ProjectDetailPage editable showGrades /> } />

              {/* Redirections */}
              <Route path={ APP_PATHS.organization.root } element={ <Navigate to={ APP_PATHS.organization.institutions } /> } />
              <Route path={ APP_PATHS.organization.projectList } element={ <ProjectListPage /> } />
            </Route>
            <Route path={ APP_PATHS.organization.projects.detail } element={ <OrganizationProjectsLayout /> }>
              <Route path={ APP_PATHS.organization.projects.edit } element={ <ManageProjectPage /> } />
              <Route path={ APP_PATHS.organization.projects.updates } element={ <ManageUpdatePage /> } />
              <Route path={ APP_PATHS.organization.projects.updatesEdit } element={ <ManageUpdatePage /> } />
              <Route path={ APP_PATHS.organization.projects.scores } element={ <ListReportCardsPage /> } />
            </Route>
          </Route>
        </Route>

        <Route element={ <AuthMiddleware role={ SPONSOR } /> }>
          <Route element={ <SponsorLayout /> }>
            <Route path={ APP_PATHS.sponsor.home } element={ <SponsorHomePage /> } />
            <Route path={ APP_PATHS.sponsor.sponsorProjectDetail } element={ <ProjectDetailPage showGrades /> } />

            {/* Redirections */}
            <Route path={ APP_PATHS.sponsor.projectList } element={ <SponsorHomePage /> } />
          </Route>
        </Route>

        <Route path={ APP_PATHS.errors.main } element={ <AppError /> } />
        <Route path={ APP_PATHS.errors.not_allowed } element={ <NotAllowed /> } />
        <Route path={ APP_PATHS.errors.not_found } element={ <NotFound /> } />
      </Route>

      <Route path='*' element={ <Navigate to={ APP_PATHS.errors.not_found } /> } />
    </Routes>
  );
}

export default AppRouter;
