import Keycloak                                                  from 'keycloak-js';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_HOST_URL, KEYCLOAK_REALM } from '../constants/keycloak.constants';

const keycloakConfig: Keycloak.KeycloakConfig = {
  url: KEYCLOAK_HOST_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
};

const appKeycloakInstance: Keycloak.KeycloakInstance = Keycloak(keycloakConfig);

export default appKeycloakInstance;
