import { PlusOutlined }             from '@ant-design/icons';
import {
  Button, Col, Empty, Modal, Row,
}                                                    from 'antd';
import React, { useEffect }                          from 'react';
import { useTranslation }                            from 'react-i18next';
import { generatePath, Link, useNavigate }           from 'react-router-dom';
import { useDeleteGradeMutation, useGetGradesQuery } from '../../../../adapters/services/grades.service';
import EntityTable                                   from '../../../../components/EntityTable';
import { ActionTuple }                               from '../../../../components/EntityTable/types';
import APP_PATHS                                     from '../../../constants/routes.constants';
import { useAppDispatch }                            from '../../../hooks/store';
import useCrudNotificationResponse                   from '../../../hooks/useCrudNotificationResponse';
import usePagination                                 from '../../../hooks/usePagination';
import { ApiGrade }                                  from '../../../interfaces/api/ApiGrade';
import { defaultConfirmProps }                       from '../../../shared/defaults/defaultModalProps';
import { setLoader }                                 from '../../../store/features/uiSlice';
import columns                                       from './columns';

const perPage = 10;

const ListGradesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { page, setQueryPage } = usePagination();
  const { data, isLoading, isFetching } = useGetGradesQuery({ page, perPage });

  const [deleteGrade, {
    isLoading: isDeleting,
    isSuccess: deleted,
    error: deleteError,
  }] = useDeleteGradeMutation();

  useCrudNotificationResponse({ isSuccess: deleted, error: deleteError });

  useEffect(() => {
    dispatch(setLoader(isDeleting));
  }, [dispatch, isDeleting]);

  const onEdit = ({ id }: ApiGrade) => {
    navigate(generatePath(APP_PATHS.admin.grades.edit, { gradeId: id }));
  };

  const onDelete = (grade: ApiGrade) => Modal.info({
    ...defaultConfirmProps,
    title: t('admin:grades.delete'),
    width: '40%',
    onOk: () => deleteGrade(grade),
  });

  const actions: ActionTuple<ApiGrade>[] = [
    ['edit', onEdit],
    ['delete', onDelete],
  ];

  return (
    <Row gutter={ [16, 32] }>
      <Col span={ 24 }>
        <h1>{ t('admin:grades.title') }</h1>
      </Col>
      <Col span={24}>
        <Link to={ APP_PATHS.admin.grades.create }>
          <Button
            icon={ <PlusOutlined /> }
            size='large'
            type='primary'
          >
            { t('admin:grades.actions.new') }
          </Button>
        </Link>
      </Col>
      <Col span={24}>
        <EntityTable
          loading={ isLoading || isFetching }
          data={ data }
          columns={ columns }
          actions={ actions }
          rowKey='id'
          paginated
          paginationData={ {
            onChange: setQueryPage,
            current: page,
            total: data?.total,
          } }
        />
        {
          !isFetching && data?.data.length === 0 && (
            <Empty description={ t('admin:grades.not_found') } />
          )
        }
      </Col>
    </Row>
  );
};

export default ListGradesPage;
