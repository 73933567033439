import { useKeycloak }         from '@react-keycloak/web';
import _                       from 'lodash';
import { useEffect, useState } from 'react';
import { KEYCLOAK_CLIENT_ID }  from '../constants/keycloak.constants';

export const ADMIN = 'ADMIN';
export const ORGANIZATION = 'ORGANIZATION';
export const SPONSOR = 'SPONSOR';

export type UserRoleType = 'ADMIN' | 'ORGANIZATION' | 'SPONSOR';

type GetRolesType = () => {
  role: UserRoleType | null;
  roles: UserRoleType[];
};

const rolesHierarchy: UserRoleType[] = [ADMIN, ORGANIZATION, SPONSOR];

const useGetRoles: GetRolesType = () => {
  const { keycloak } = useKeycloak();
  const [roles, setRoles] = useState<UserRoleType[]>([]);
  const [firstRole, setFirstRole] = useState<UserRoleType | null>(null);

  useEffect(() => {
    const token = keycloak.tokenParsed;

    if (token !== undefined && token.resource_access) {
      // eslint-disable-next-line no-prototype-builtins
      if (token.resource_access.hasOwnProperty(KEYCLOAK_CLIENT_ID)) {
        const resourceRoles = token.resource_access[KEYCLOAK_CLIENT_ID].roles;
        setRoles(resourceRoles as UserRoleType[]);
      }
    }
  }, [keycloak]);

  useEffect(() => {
    if (roles.length > 0) {
      const sorted = _.cloneDeep(roles).sort(
        (a, b) => rolesHierarchy.indexOf(a) - rolesHierarchy.indexOf(b),
      );
      const auxFirst = sorted[0];

      if (auxFirst !== undefined) setFirstRole(auxFirst);
    }
  }, [roles]);

  return { role: firstRole, roles };
};

export default useGetRoles;
