import React                                from 'react';
import { lowerCase }                        from 'lodash';
import { Select }                           from 'antd';
import getConvertedRate                     from '../../../adapters/third-party/currency.service';
import CURRENCIES, { CURRENCY_STORAGE_KEY } from '../../../core/constants/currencies.constants';
import { useAppDispatch, useAppSelector }   from '../../../core/hooks/store';
import { setCurrency }                      from '../../../core/store/features/uiSlice';
import { CustomSelect }                     from '../../forms/shared/CustomSelect';

const { Option } = Select;

export const CurrencySelector = () => {
  const dispatch = useAppDispatch();
  const { currency } = useAppSelector((state) => state.ui);

  const onChange = async (value: string) => {
    const rate = await getConvertedRate(lowerCase(value));
    dispatch(setCurrency({
      code: value,
      rate: rate ?? 1,
    }));
    localStorage.setItem(CURRENCY_STORAGE_KEY, value);
  };

  return (
    <CustomSelect
      showSearch
      onChange={ onChange }
      value={ currency.code }
    >
      {
        CURRENCIES.map((code) => (
          <Option key={ code } value={ code }>{ code }</Option>
        ))
      }
    </CustomSelect>
  );
};
