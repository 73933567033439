import { FormInstance }                                        from 'antd';
import { TFunction }                                           from 'i18next';
import { useEffect }                                           from 'react';
import {
  useCreateBlogEntryMutation, useDeleteBlogEntryMutation, usePublishBlogEntryMutation,
  useUnpublishBlogEntryMutation, useUpdateBlogEntryMutation,
}                                  from '../../../../adapters/services/blogEntries.service';
import { BlogEntryFields }         from '../../../../components/forms/admin/BlogEntryForm/form';
import { useAppDispatch }          from '../../../hooks/store';
import useCrudNotificationResponse from '../../../hooks/useCrudNotificationResponse';
import ApiBlogEntry                from '../../../interfaces/api/ApiBlogEntry';
import { setLoader }               from '../../../store/features/uiSlice';

type Props = {
  t: TFunction;
  form: FormInstance<BlogEntryFields>;
  onClose: () => void;
}

type ReturnType = {
  createEntry: (arg: FormData) => void;
  updateEntry: (arg: {id: string; body: FormData}) => void;
  deleteEntry: (arg: ApiBlogEntry) => void;
  publishEntry: (arg: ApiBlogEntry) => void;
  unpublishEntry: (arg: ApiBlogEntry) => void;
}

type BlogEntriesEffectsType = (props: Props) => ReturnType

const useBlogEntriesEffects: BlogEntriesEffectsType = ({ form, onClose, t }) => {
  const dispatch = useAppDispatch();

  const [createEntry, {
    isLoading: isCreating,
    isSuccess: created,
    error: createError,
  }] = useCreateBlogEntryMutation();

  const [updateEntry, {
    isLoading: isUpdating,
    isSuccess: updated,
    error: updateError,
  }] = useUpdateBlogEntryMutation();

  const [deleteEntry, {
    isLoading: isDeleting,
    isSuccess: deleted,
    error: deleteError,
  }] = useDeleteBlogEntryMutation();

  const [publishEntry, {
    isLoading: isPublishing,
    isSuccess: published,
    error: publishError,
  }] = usePublishBlogEntryMutation();

  const [unpublishEntry, {
    isLoading: isUnpublishing,
    isSuccess: unpublished,
    error: unpublishError,
  }] = useUnpublishBlogEntryMutation();

  useCrudNotificationResponse({
    isSuccess: created, error: createError, form, onClose,
  });
  useCrudNotificationResponse({
    isSuccess: updated, error: updateError, form, onClose,
  });
  useCrudNotificationResponse({
    isSuccess: deleted,
    error: deleteError,
    successNotification: {
      message: t('admin:blog_entries.actions.delete.success.title'),
      description: t('admin:blog_entries.actions.delete.success.description'),
    },
  });
  useCrudNotificationResponse({
    isSuccess: published,
    error: publishError,
    successNotification: {
      message: t('admin:blog_entries.actions.publish.success.title'),
      description: t('admin:blog_entries.actions.publish.success.description'),
    },
  });
  useCrudNotificationResponse({
    isSuccess: unpublished,
    error: unpublishError,
    successNotification: {
      message: t('admin:blog_entries.actions.unpublish.success.title'),
      description: t('admin:blog_entries.actions.unpublish.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isCreating || isUpdating || isDeleting || isPublishing || isUnpublishing));
  }, [dispatch, isCreating, isUpdating, isDeleting, isPublishing, isUnpublishing]);

  return {
    createEntry, updateEntry, deleteEntry, publishEntry, unpublishEntry,
  };
};

export default useBlogEntriesEffects;
