import { ItemType }                               from 'antd/lib/menu/hooks/useItems';
import React, { useContext, useEffect, useState } from 'react';
import { LogoutOutlined, MenuOutlined }           from '@ant-design/icons';
import { useKeycloak }                            from '@react-keycloak/web';
import {
  Button, Dropdown, Image, Menu,
}                                    from 'antd';
import { useTranslation }            from 'react-i18next';
import { Link, useNavigate }         from 'react-router-dom';
import { HashLink }                  from 'react-router-hash-link';
import { AppModalCtx }               from '../../../contexts/AppModalContext';
import { FundLogo }                  from '../../../core/constants/assets.constants';
import HOME_ROUTES                   from '../../../core/constants/homeRoutes.constants';
import { REDIRECT_PATHS }            from '../../../core/constants/keycloak.constants';
import APP_PATHS, { APP_HASHES }     from '../../../core/constants/routes.constants';
import { useAppSelector }            from '../../../core/hooks/store';
import useGetRoles, { UserRoleType } from '../../../core/hooks/useGetRoles';
import { defaultInfoProps }          from '../../../core/shared/defaults/defaultModalProps';
import { UserAvatar }                from '../../profile';
import SignupForm                    from '../../SignupForm';
import './index.less';

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const { keycloak } = useKeycloak();
  const { role } = useGetRoles();
  const navigate = useNavigate();
  const { modalFn } = useContext(AppModalCtx);
  const { keycloakUserInfo } = useAppSelector(({ auth }) => auth);

  const [name, setName] = useState<string | null>(null);
  const [userMenuItems, setUserMenuItems] = useState<ItemType[]>();

  useEffect(() => {
    if (keycloakUserInfo != null) {
      setName((keycloakUserInfo.given_name).toUpperCase());
    }
  }, [keycloakUserInfo]);

  const getNavigationTarget = (roleName: UserRoleType): string => (
    HOME_ROUTES[roleName] !== undefined
      ? HOME_ROUTES[roleName]
      : APP_PATHS.errors.not_allowed
  );

  const showSingUp = () => {
    const mdl = modalFn.info({
      ...defaultInfoProps,
      width: '50%',
      content: <SignupForm onSuccess={ () => {
        mdl.destroy();
        keycloak.login({ redirectUri: REDIRECT_PATHS.login });
      } }
      />,
    });
  };

  const logout = () => {
    keycloak.logout({ redirectUri: REDIRECT_PATHS.logout });
  };

  const menuItems = [
    {
      key: '1',
      label: (
        <Link to={APP_PATHS.public.discover}>
          {' '}
          { t('menu.discover') }
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={ APP_PATHS.public.blog.root }>
          { t('menu.blog') }
        </Link>
      ),
    },
    {
      key: '3',
      label: (
        <HashLink to={APP_PATHS.home + APP_HASHES.howItWorks} smooth>
          { t('menu.how_it_works') }
        </HashLink>
      ),
    },
    ...keycloakUserInfo === null
      ? [{
        key: '4',
        label: (
          <Button type='link' className='text-uppercase' onClick={ showSingUp }>
            { t('menu.join_us') }
          </Button>
        ),
      }]
      : [],
  ];

  useEffect(() => {
    let i = 1;
    const items: ItemType[] = [];

    items.push({
      key: i,
      label: t('profile.title'),
      onClick: () => navigate(APP_PATHS.shared.profile),
    });

    if (!!keycloak.authenticated && role !== null) {
      i += 1;
      items.push({
        key: i,
        label: role,
        onClick: () => navigate(getNavigationTarget(role)),
      });
    }

    i += 1;
    items.push({
      key: i,
      icon: <LogoutOutlined />,
      label: t('logout'),
      onClick: logout,
    });

    setUserMenuItems(items);
  }, [keycloak, role, i18n.language, setUserMenuItems]);

  return (
    <nav className='auth-nav-bar container d-flex align-center mx-auto'>
      <div className='auth-nav-bar__logo'>
        <Link to={APP_PATHS.home} className='text-primary text-h3 d-none lg-d-block'>Fundraiser</Link>
        <Link to={APP_PATHS.home} className='d-block lg-d-none'>
          <Image
            height={40}
            preview={false}
            src={FundLogo.default}
          />
        </Link>
      </div>
      <div className='auth-nav-bar__menu'>
        <Menu
          mode='horizontal'
          items={menuItems}
          selectable={false}
          className='text-uppercase'
        />
      </div>
      <div className='auth-nav-bar__menu-actions d-block lg-d-none'>
        { name === null && (
          <Button
            type='link'
            className='auth-nav-bar__menu-actions--login text-uppercase'
            onClick={ () => keycloak.login({ redirectUri: REDIRECT_PATHS.login }) }
            block
          >
            { t('login') }
          </Button>
        )}

        { name !== null && (
          <Dropdown
            className='nav-user'
            overlay={ <Menu items={ userMenuItems } /> }
            placement='bottom'
            arrow
          >
            <Button type='link'>
              <UserAvatar size={32} />
            </Button>
          </Dropdown>
        ) }
      </div>
      <div className='auth-nav__menu--mobile d-none lg-d-block'>
        <Button className='text-primary' type='text' icon={<MenuOutlined />} size='large' />
      </div>
    </nav>
  );
};

export default NavBar;
