import { generatePath }                                                   from 'react-router-dom';
import { EXAMPLES_TAG, LIST_ID_TAG }                                      from '../../core/constants/api.tags.constants';
import { EXAMPLES_URIS }                                                  from '../../core/constants/api.uris.constants';
import ApiExample, { CreateExamplePayloadType, UpdateExamplePayloadType } from '../../core/interfaces/api/ApiExample';
import fundEducationApi                                                   from '../apis/fundEducation.api';

export const exampleApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getExamples: build.query<ApiExample[], void>({
      query: () => EXAMPLES_URIS.root,
      providesTags: (result) => (
        result
          ? [
            ...result.map(({ id }) => ({ type: EXAMPLES_TAG, id } as const)),
            { type: EXAMPLES_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: EXAMPLES_TAG, id: LIST_ID_TAG }]
      ),
    }),
    createExample: build.mutation<null, CreateExamplePayloadType>({
      query: (body) => ({
        url: EXAMPLES_URIS.root,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: EXAMPLES_TAG, id: LIST_ID_TAG }],
    }),
    updateExample: build.mutation<null, UpdateExamplePayloadType>({
      query: ({ id, ...payload }) => ({
        url: generatePath(EXAMPLES_URIS.action, { id }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: EXAMPLES_TAG, id }],
    }),
    deleteExample: build.mutation<null, ApiExample>({
      query: (payload) => ({
        url: generatePath(EXAMPLES_URIS.action, { id: payload.id }),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: EXAMPLES_TAG, id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetExamplesQuery,
  useCreateExampleMutation,
  useUpdateExampleMutation,
  useDeleteExampleMutation,
} = exampleApi;
