import React, {
  FC, useEffect, useRef, useState,
}                    from 'react';
import { Rule }      from 'rc-field-form/lib/interface';
import { TFunction } from 'react-i18next';
import ReactQuill    from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.less';

export type WYSIWYGValueType = {
  html: string | undefined;
  text: string | undefined;
} | undefined;

type WYSIWYGFieldProps = {
  value?: WYSIWYGValueType;
  onChange?: (value: WYSIWYGValueType) => void;
  showCount?: boolean;
  maxLength?: number;
  disabled?: boolean;
  placeholder?: string;
}

export const maxLengthWYSIWYGValidator: (t: TFunction, maxText: number) => Rule = (t, maxText) => ({
  // eslint-disable-next-line consistent-return
  validator: async (_, value: WYSIWYGValueType): Promise<Error | undefined> => {
    if (value?.text === undefined || value.text.length === 1) {
      return Promise.reject(new Error(t('wysiwyg.no_text')));
    }
    if (value.text.length > maxText + 1) {
      return Promise.reject(new Error(t('wysiwyg.max_chars', { maxText })));
    }
  },
});

/*
* Removes trailing newlines from a html string
* Always use this on a value provided by this component before sending to server
*/
export const sanitizeBody = (body: string): string => {
  let sanitizedBody = body;
  while (sanitizedBody.endsWith('<p><br></p>')) {
    sanitizedBody = sanitizedBody.slice(0, -11);
  }
  return sanitizedBody;
};

const WYSIWYGField: FC<WYSIWYGFieldProps> = ({
  value: propValue,
  onChange,
  disabled,
  showCount,
  maxLength,
  placeholder,
}) => {
  const [htmlValue, setHtmlValue] = useState(propValue?.html);
  const [textCount, setTextCount] = useState(0);

  const ref = useRef<ReactQuill>(null);

  const handleChange = (value: string, editor: ReactQuill.UnprivilegedEditor) => {
    // This is somewhat of a hacky solution to a bug that prevented from inserting newlines
    // at the end of the editor
    let newLineableValue = value;
    if (value && value.length > 11 && value.endsWith('<p><br></p>')) {
      newLineableValue = `${value}<p><br></p>`;
    }
    // End of hack 🧐

    setHtmlValue(newLineableValue);
    setTextCount(editor.getLength() - 1);

    if (onChange) onChange({ html: newLineableValue, text: editor.getText().trim() });
  };

  useEffect(() => {
    if (ref.current !== null && htmlValue !== undefined) {
      const editor = ref.current.getEditor();
      const unpriviledged = ref.current.makeUnprivilegedEditor(editor);

      if (propValue?.html !== undefined && propValue?.text === undefined) {
        handleChange(propValue.html, unpriviledged);
      }
    }

    if (htmlValue === undefined) {
      setHtmlValue(propValue?.html);
    }
  }, [htmlValue, propValue]);

  return (
    <div className='custom-wysiwyg-field'>
      <ReactQuill
        ref={ ref }
        theme='snow'
        readOnly={ disabled }
        placeholder={ placeholder }
        modules={ {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
          ],
        } }
        preserveWhitespace
        value={ htmlValue }
        onChange={ (v, _, __, editor) => handleChange(v, editor) }
      />

      { showCount && (
        <div className='counter'>
          { textCount }
          {' '}
          { maxLength !== undefined && maxLength > 0 ? `/${maxLength}` : null }
        </div>
      ) }
    </div>
  );
};

export default WYSIWYGField;
