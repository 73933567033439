import React, { useEffect }                     from 'react';
import { useForm }                              from 'antd/lib/form/Form';
import { useTranslation }                       from 'react-i18next';
import {
  Button, Card, Col, Form, Input, Modal, Row,
}                                                              from 'antd';
import { useNavigate }                                         from 'react-router-dom';
import { useUpdatePasswordMutation, useUpdateProfileMutation } from '../../../../adapters/services/profile.service';
import { GoBackButton, OutlinedButton }                        from '../../../../components/buttons';
import { validateMinimumImageSize }                            from '../../../../components/fields/ImageField/ImageValidators';
import { useAppDispatch, useAppSelector }                      from '../../../hooks/store';
import useCrudNotificationResponse                             from '../../../hooks/useCrudNotificationResponse';
import { defaultInfoProps }                                    from '../../../shared/defaults/defaultModalProps';
import { setLoader }                                           from '../../../store/features/uiSlice';
import ChangePasswordForm, { ChangePasswordFields }            from './components/ChangePasswordForm';
import {
  buildProfileFormData,
  UserProfileFields,
}                                                              from './helpers';
import ImageField from '../../../../components/fields/ImageField';

const { Item } = Form;

const initialValues: UserProfileFields = {
  firstName: '',
  lastName: '',
  imageUrl: undefined,
};

const UserProfilePage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = useForm<UserProfileFields>();
  const [passwordForm] = useForm<ChangePasswordFields>();

  const { user } = useAppSelector((state) => state.auth);

  const [updateProfile, {
    isLoading: isUpdating,
    isSuccess: updated,
    error: updateError,
  }] = useUpdateProfileMutation();

  const [updatePassword, {
    isLoading: isUpdatingPass,
    isSuccess: updatedPass,
    error: updatePassError,
  }] = useUpdatePasswordMutation();

  useCrudNotificationResponse({
    isSuccess: updated,
    error: updateError,
    form,
    successNotification: {
      message: t('profile.update.success.title'),
      description: t('profile.update.success.message'),
    },
  });

  useCrudNotificationResponse({
    isSuccess: updatedPass,
    error: updatePassError,
    form: passwordForm,
    successNotification: {
      message: t('profile.password_change.success.title'),
      description: t('profile.password_change.success.message'),
    },
    errorNotification: {
      message: t('profile.password_change.error.title'),
      description: t('profile.password_change.error.message'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isUpdating || isUpdatingPass));
  }, [dispatch, isUpdating, isUpdatingPass]);

  const handleFinish = (fields: UserProfileFields) => {
    const formData = buildProfileFormData(fields);
    updateProfile(formData);
  };

  useEffect(() => {
    if (user != null) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        imageUrl: user.imageUrl,
      });
    }
  }, [user, form]);

  const openPasswordForm = () => {
    Modal.info({
      ...defaultInfoProps,
      title: t('profile.password_change.title'),
      width: '40%',
      content: <ChangePasswordForm
        form={ passwordForm }
        onFinish={ updatePassword }
      />,
    });
  };

  return (
    <Row justify='center'>
      <Col xs={ 24 } md={ 20 } xl={ 10 }>
        <GoBackButton onClick={() => navigate(-1)} />
        <h1 className='text-center text-primary'>
          { t('profile.configuration') }
        </h1>
        <Card loading={ user === null }>
          <Form<UserProfileFields>
            autoComplete='off'
            form={ form }
            layout='vertical'
            name='frmProfile'
            requiredMark={ false }
            initialValues={ initialValues }
            onFinish={ (v) => handleFinish(v) }
          >
            <Row gutter={ [16, 8] }>
              <Col span={24}>
                <Item
                  label={ t('profile.form.photo.label') }
                  name='imageUrl'
                  className='text-body-bold'
                  rules={ [
                    validateMinimumImageSize(t, 400, 400),
                  ] }
                >
                  <ImageField />
                </Item>
                <p className='text center _16'>
                  { t('profile.form.photo.size') }
                </p>
              </Col>
              <Col span={24}>
                <Item
                  label={ t('profile.form.first_name.label') }
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: t('profile.form.first_name.required'),
                    },
                  ]}
                >
                  <Input />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={ t('profile.form.last_name.label') }
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      message: t('profile.form.last_name.required'),
                    },
                  ]}
                >
                  <Input />
                </Item>
              </Col>
              <Col span={24}>
                <Button
                  block
                  type='primary'
                  htmlType='submit'
                >
                  { t('save') }
                </Button>
              </Col>
              <Col span={24}>
                <OutlinedButton onClick={openPasswordForm}>
                  { t('profile.password_change.title') }
                </OutlinedButton>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserProfilePage;
