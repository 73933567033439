import { FormInstance }           from 'antd';
import { TFunction }              from 'i18next';
import { useEffect }              from 'react';
import {
  useCreateInstitutionMutation,
  useUpdateInstitutionMutation,
}                         from '../../../../adapters/services/institutions.service';
import { useAppDispatch }          from '../../../hooks/store';
import useCrudNotificationResponse from '../../../hooks/useCrudNotificationResponse';
import { setLoader }               from '../../../store/features/uiSlice';
import { InstitutionFields }       from './helpers';

type Props = {
  t: TFunction;
  form: FormInstance<InstitutionFields>;
  onClose: () => void;
}

type ReturnType = {
  createInstitution: (arg: FormData) => void;
  updateInstitution: (arg: { id: string; body: FormData }) => void;
}

type InstitutionEffectsType = (props: Props) => ReturnType;

const useInstitutionEffects: InstitutionEffectsType = ({ form, onClose, t }) => {
  const dispatch = useAppDispatch();

  const [createInstitution, {
    isLoading: isCreating,
    isSuccess: created,
    error: createError,
  }] = useCreateInstitutionMutation();

  const [updateInstitution, {
    isLoading: isUpdating,
    isSuccess: updated,
    error: updateError,
  }] = useUpdateInstitutionMutation();

  useCrudNotificationResponse({
    isSuccess: created, error: createError, form, onClose,
  });

  useCrudNotificationResponse({
    isSuccess: updated,
    error: updateError,
    form,
    onClose,
    successNotification: {
      message: t('organization:institution.update.success.title'),
      description: t('organization:institution.update.success.message'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isCreating || isUpdating));
  }, [dispatch, isCreating, isUpdating]);

  return {
    createInstitution, updateInstitution,
  };
};

export default useInstitutionEffects;
