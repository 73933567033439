import {
  Avatar, Col, Pagination, Row,
} from 'antd';
import React                    from 'react';
import { useTranslation }       from 'react-i18next';
import { useGetDonationsQuery } from '../../../../../../adapters/services/donations.service';
import { DonationHeartImg }     from '../../../../../../components/assets/Images';
import { LocalizedTimeAgo }     from '../../../../../../components/l10n';
import useConvertCurrency       from '../../../../../hooks/useConvertCurrency';
import usePagination            from '../../../../../hooks/usePagination';
import { ApiProject }           from '../../../../../interfaces/api/ApiProject';
import './index.less';

type Props = {
  project: ApiProject;
}

const perPage = 2;

export const DonationPanel: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const convert = useConvertCurrency();

  const { page, setQueryPage } = usePagination('donationPage');

  const { data: donations } = useGetDonationsQuery({
    projectId: project.id,
    page,
    perPage,
  });

  return (
    <Row gutter={[0, 24]} id='donation-panel'>
      {
        donations !== undefined && (
          <>
            <Col span={24}>
              <h4 className='text-gray d-flex justify-space-between'>
                <span>{ t('projects.donations.times') }</span>
                <span>{ donations.total }</span>
              </h4>
            </Col>
            {
              donations.data.map((donation) => (
                <Col span={24} className='donation-card' key={donation.id}>
                  <Row className='donation-container-row'>
                    <Col>
                      {
                        (donation.anonymous
                          || donation.sponsor === null
                          || donation.sponsor.imageUrl === null
                        ) && (
                          <Avatar className='donation-avatar'>
                            <DonationHeartImg />
                          </Avatar>
                        )
                      }
                      {
                        (!donation.anonymous
                          && donation.sponsor !== null
                          && donation.sponsor.imageUrl !== null
                        ) && (
                          <Avatar className='donation-avatar' src={donation.sponsor.imageUrl} />
                        )
                      }
                    </Col>
                    <Col className='donation-title'>
                      <p className='text-bold'>
                        { donation.anonymous || donation.sponsor === null
                          ? t('projects.donations.anonymous')
                          : `${donation.sponsor.firstName} ${donation.sponsor.lastName}`}
                      </p>
                      <b>{ t('projects.donations.donated') }</b>
                      { ' ' }
                      <b className='text-primary'>{ convert(donation.amount) }</b>
                      <span className='text-gray'>
                        { ' · ' }
                        <LocalizedTimeAgo date={donation.createdAt} />
                      </span>
                    </Col>
                  </Row>
                  <p className='donation-message'>
                    { donation.message }
                  </p>
                </Col>
              ))
            }
            {
              donations.total > 0 && (
                <Col className='d-flex justify-center' span={24}>
                  <Pagination
                    size='small'
                    showLessItems
                    current={page}
                    hideOnSinglePage
                    pageSize={perPage}
                    onChange={setQueryPage}
                    total={donations.total}
                  />
                </Col>
              )
            }
          </>
        )
      }
    </Row>
  );
};
