import React               from 'react';
import {
  Col, Form, Input, Row,
}                         from 'antd';
import { useTranslation } from 'react-i18next';
import { ApiInstitution } from '../../../../core/interfaces/api/ApiInstitution';

const { Item } = Form;
const { TextArea } = Input;

type Props = {
  entity: ApiInstitution;
}

const InstitutionForm: React.FC<Props> = ({
  entity,
}) => {
  const { t } = useTranslation('common');

  return (
    <Form
      layout='vertical'
      preserve={false}
    >
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Item
            label={ t('common:institution.form.name') }
          >
            <Input
              value={ entity.name }
            />
          </Item>
        </Col>
        <Col span={24}>
          <Item
            label={ t('common:institution.form.description') }
          >
            <TextArea
              rows={4}
              value={ entity.description }
            />
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

export default InstitutionForm;
