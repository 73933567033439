import {
  Button, Card, Col, Empty, Modal, Pagination, Row, Segmented,
}                                                      from 'antd';
import { range }                                                             from 'lodash';
import React, { useEffect, useState }                                        from 'react';
import { Check, Eye, Trash2 }                                                from 'react-feather';
import { TFunction, useTranslation }                                         from 'react-i18next';
import { generatePath, useSearchParams }                                     from 'react-router-dom';
import { OutlinedItem }                                                      from '../../../../components/containers';
import { FILES_URIS }                                                        from '../../../constants/api.uris.constants';
import { useAppDispatch }                                                    from '../../../hooks/store';
import useCrudNotificationResponse                                           from '../../../hooks/useCrudNotificationResponse';
import useDownloadFile                                                       from '../../../hooks/useDownloadFile';
import usePagination                                                         from '../../../hooks/usePagination';
import { ApiOrganizationPetition, PetitionStatusEnum }                       from '../../../interfaces/api/ApiOrganizationPetition';
import { ApiPetitionDocument }                                               from '../../../interfaces/api/ApiPetitionDocument';
import defaultCardColProps                                                   from '../../../shared/defaults/defaultCardColProps';
import { defaultConfirmProps, defaultInfoProps }                             from '../../../shared/defaults/defaultModalProps';
import { setLoader }                                                         from '../../../store/features/uiSlice';
import {
  OrganizationPetitionFilters, useApproveOrganizationPetitionMutation,
  useGetOrganizationPetitionsQuery, useRejectOrganizationPetitionMutation,
}                                                      from '../../../../adapters/services/organizationPetitions.service';
import OrganizationPetitionForm
  from '../../../../components/forms/admin/OrganizationPetitionForm/form';

const queryParamToInt = (queryStatus: string | null): number | undefined => (
  queryStatus !== null ? parseInt(queryStatus, 10) : undefined
);

const buildFilters = (queryStatus: string | null): OrganizationPetitionFilters => ({
  status: queryParamToInt(queryStatus),
});

const getTitle = (t: TFunction, value?: string): string => {
  if (value === PetitionStatusEnum.PENDING.toString()) {
    return t('admin:petitions.pending.title');
  } if (value === PetitionStatusEnum.APPROVED.toString()) {
    return t('admin:petitions.approved.title');
  } if (value === PetitionStatusEnum.REJECTED.toString()) {
    return t('admin:petitions.rejected.title');
  }
  return '';
};

const ListOrganizationPetitionsPage = () => {
  const { t } = useTranslation(['common', 'admin']);
  const dispatch = useAppDispatch();

  const { page, setQueryPage } = usePagination();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryStatus = searchParams.get('status');
  const [filters, setFilters] = useState<OrganizationPetitionFilters>(buildFilters(queryStatus));
  const [title, setTitle] = useState<string>(
    getTitle(t, queryStatus ?? PetitionStatusEnum.PENDING.toString()),
  );

  const downloadFile = useDownloadFile();

  const { data, isLoading, isFetching } = useGetOrganizationPetitionsQuery({ page, filters });

  const [approvePetition, {
    isLoading: isApproving,
    isSuccess: approved,
    error: approveError,
  }] = useApproveOrganizationPetitionMutation();

  const [rejectPetition, {
    isLoading: isRejecting,
    isSuccess: rejected,
    error: rejectError,
  }] = useRejectOrganizationPetitionMutation();

  useCrudNotificationResponse({
    isSuccess: approved,
    error: approveError,
    successNotification: {
      message: t('admin:petitions.actions.approve.success.title'),
      description: t('admin:petitions.actions.approve.success.description'),
    },
  });
  useCrudNotificationResponse({
    isSuccess: rejected,
    error: rejectError,
    successNotification: {
      message: t('admin:petitions.actions.reject.success.title'),
      description: t('admin:petitions.actions.reject.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isApproving || isRejecting));
  }, [dispatch, isApproving, isRejecting]);

  useEffect(() => {
    if (queryStatus === null) {
      searchParams.set('status', '2');
    }
    setFilters(buildFilters(queryStatus));
  }, [queryStatus]);

  const onFilter = (currentState: string | null, paramName: string, value?: string) => {
    if (currentState !== value) {
      searchParams.delete('page');
    }

    if (value !== undefined && value.length !== 0) {
      searchParams.set(paramName, value);
    } else {
      searchParams.delete(paramName);
    }

    setSearchParams(searchParams);

    setTitle(getTitle(t, value));
  };

  const onDownload = (document: ApiPetitionDocument) => {
    const downloadUrl = generatePath(FILES_URIS.petitionDocuments, { id: document.id });
    downloadFile(downloadUrl, document.name);
  };

  const onView = (petition: ApiOrganizationPetition) => {
    Modal.info({
      ...defaultInfoProps,
      title: t('admin:petitions.form.title'),
      width: '65%',
      content: <OrganizationPetitionForm
        entity={ petition }
        onDownload={ onDownload }
      />,
    });
  };

  const onApprove = ({ id }: ApiOrganizationPetition) => Modal.confirm({
    ...defaultConfirmProps,
    title: t('admin:petitions.actions.approve.confirm'),
    onOk: () => approvePetition(id),
  });

  const onReject = ({ id }: ApiOrganizationPetition) => Modal.confirm({
    ...defaultConfirmProps,
    title: t('admin:petitions.actions.reject.confirm'),
    onOk: () => rejectPetition(id),
  });

  const statusOptions = [
    {
      label: t('admin:petitions.pending.menu'),
      value: 2,
    },
    {
      label: t('admin:petitions.approved.menu'),
      value: 3,
    },
    {
      label: t('admin:petitions.rejected.menu'),
      value: 4,
    },
  ];

  return (
    <Row gutter={[16, 16]} justify='center'>
      <Col { ...defaultCardColProps }>
        <Segmented
          block
          options={ statusOptions }
          defaultValue={ queryParamToInt(queryStatus) }
          onChange={(value) => onFilter(queryStatus, 'status', value.toString())}
        />
      </Col>
      <Col { ...defaultCardColProps }>
        <Card>
          <h1>{ title }</h1>
          {
            isLoading || isFetching
              ? range(0, 10).map((key) => (
                <Col span={24} key={ key }>
                  <Card loading />
                </Col>
              ))
              : data?.data.map((petition) => (
                <OutlinedItem
                  key={ petition.id }
                  description={ petition.organization.name }
                  actions={(
                    <>
                      <Button
                        shape='circle'
                        size='large'
                        onClick={ () => onView(petition) }
                      >
                        <Eye color='var(--fund-education-primary-color)' />
                      </Button>
                      {
                        queryStatus !== PetitionStatusEnum.APPROVED.toString()
                        && (
                          <Button
                            shape='circle'
                            size='large'
                            onClick={ () => onApprove(petition) }
                          >
                            <Check color='var(--gray)' />
                          </Button>
                        )
                      }
                      {
                        queryStatus !== PetitionStatusEnum.REJECTED.toString()
                        && (
                        <Button
                          shape='circle'
                          size='large'
                          onClick={ () => onReject(petition) }
                        >
                          <Trash2 color='var(--gray)' />
                        </Button>
                        )
                      }
                    </>
                  )}
                />
              ))
          }
          {
            data?.data.length !== 0
            && (
              <Col span={24} className='text-center'>
                <Pagination
                  onChange={ setQueryPage }
                  current={ page }
                  pageSize={ 12 }
                  total={ data?.total }
                />
              </Col>
            )
          }
          { !isFetching && data?.data.length === 0 && <Empty description={ t('admin:petitions.not_found') } /> }
        </Card>
      </Col>
    </Row>
  );
};

export default ListOrganizationPetitionsPage;
