import {
  Button, Col, Form, message, Row, Upload, UploadProps,
}                                                       from 'antd';
import _                                       from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { File, Trash2, Upload as UploadIcon }  from 'react-feather';
import { useTranslation }                      from 'react-i18next';
import { generatePath }                        from 'react-router-dom';
import { FILES_URIS }                          from '../../../../core/constants/api.uris.constants';
import { useAppDispatch }                      from '../../../../core/hooks/store';
import useCrudNotificationResponse             from '../../../../core/hooks/useCrudNotificationResponse';
import useDownloadFile                         from '../../../../core/hooks/useDownloadFile';
import { ApiOrganizationPetition }             from '../../../../core/interfaces/api/ApiOrganizationPetition';
import { UploadableDocument }                  from '../../../../core/interfaces/api/ApiPetitionDocument';
import { setLoader }                           from '../../../../core/store/features/uiSlice';
import { useUploadPetitionDocumentsMutation }  from '../../../../adapters/services/organizations.service';
import { OutlinedButton }                      from '../../../buttons';
import { buildPetitionDocumentsFormData }      from './helpers';
import './style.less';

const { Item } = Form;

type Props = {
  entity?: ApiOrganizationPetition;
  onSuccess: () => void;
}

const UploadPetitionDocumentsForm: React.FC<Props> = ({ entity, onSuccess }) => {
  const { t } = useTranslation('organization');
  const dispatch = useAppDispatch();
  const downloadFile = useDownloadFile();

  const [documents, setDocuments] = useState<UploadableDocument[]>([]);

  const [upload, {
    isLoading,
    isSuccess,
    error,
  }] = useUploadPetitionDocumentsMutation();

  useCrudNotificationResponse({
    isSuccess,
    error,
    onClose: onSuccess,
    successNotification: {
      message: t('wizard.step3.actions.upload.success.title'),
      description: t('wizard.step3.actions.upload.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (entity !== undefined && entity.documents !== undefined) {
      setDocuments(entity.documents);
    }
  }, [entity]);

  const onDownload = (document: UploadableDocument) => {
    const downloadUrl = generatePath(FILES_URIS.petitionDocuments, { id: document.id });
    downloadFile(downloadUrl, document.name);
  };

  const removeDocument = (document: UploadableDocument) => {
    const clonedDocuments = _.cloneDeep(documents);

    clonedDocuments.forEach((item, index) => {
      if (item.name === document.name) clonedDocuments.splice(index, 1);
    });

    setDocuments(clonedDocuments);
  };

  const uploadProps: UploadProps = useMemo(() => ({
    maxCount: 1,
    showUploadList: false,
    beforeUpload: () => false,
    onChange: (info) => {
      if (documents.find(({ name }) => name === info.file.name)) {
        message.warn(t('wizard.step3.form.repeated_name'));
      } else {
        const newDocument: UploadableDocument = {
          name: info.fileList[0].name,
          file: info.fileList[0].originFileObj,
        };
        setDocuments([...documents, newDocument]);
      }
    },
  }), [documents, setDocuments]);

  const handleFinish = () => {
    const formData = buildPetitionDocumentsFormData(documents);
    upload(formData);
  };

  return (
    <Form
      layout='vertical'
      name='frmPetitionDocuments'
      requiredMark={ false }
      className='wizard-form'
      onFinish={ handleFinish }
    >
      <Row gutter={ [16, 0] }>
        <Col span={ 24 } className='text-center'>
          <h4>{ t('wizard.step3.form.title') }</h4>
        </Col>
        <Col span={ 24 } className='wizard-step-description'>
          { t('wizard.step3.form.description') }
        </Col>
        {
          documents.map((document) => (
            <Col span={ 24 } key={ document.name }>
              <OutlinedButton
                className={`document-btn mb-1 ${document.id === undefined ? 'not-uploaded' : ''}`}
                onClick={ () => onDownload(document) }
              >
                <File />
                {document.name}
              </OutlinedButton>
              <Button
                type='text'
                onClick={ () => removeDocument(document) }
              >
                <Trash2 color='var(--gray)' />
              </Button>
            </Col>
          ))
        }
        <Col span={ 24 }>
          <Item className='document-upload'>
            <Upload {...uploadProps}>
              <OutlinedButton>
                <UploadIcon />
                { t('wizard.step3.form.upload') }
              </OutlinedButton>
            </Upload>
          </Item>
        </Col>
      </Row>
      <Item>
        <Button
          block
          type='primary'
          size='large'
          htmlType='submit'
          disabled={ documents.length === 0 }
        >
          { t('wizard.step3.form.submit') }
        </Button>
      </Item>
    </Form>
  );
};

export default UploadPetitionDocumentsForm;
