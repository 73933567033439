import React                       from 'react';
import { useGetOrganizationQuery } from '../../../../adapters/services/organizations.service';
import CreateOrganizationForm      from '../../../../components/forms/organization/WizardForms/step1';

const EditOrganizationPage = () => {
  const { data } = useGetOrganizationQuery();

  return <CreateOrganizationForm entity={data} />;
};

export default EditOrganizationPage;
