import { Col, Row }         from 'antd';
import React, { ReactNode } from 'react';
import './index.less';

type Props = {
  description: ReactNode;
  actions: ReactNode;
}

export const OutlinedItem: React.FC<Props> = ({ description, actions }) => (
  <Row className='outlined-item'>
    <Col xs={14} md={18} lg={19} span={20} className='description'>
      { description }
    </Col>
    <Col xs={10} md={6} lg={5} span={4} className='actions'>
      { actions }
    </Col>
  </Row>
);
