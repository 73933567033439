import React, { useEffect }              from 'react';
import {
  Link, To, useMatch, useResolvedPath,
} from 'react-router-dom';

type Props = {
  title: React.ReactElement | string;
  to: To;
  onMatch: () => void;
}

const MenuLink: React.FC<Props> = ({ title, to, onMatch }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  useEffect(() => {
    if (match) {
      onMatch();
    }
  }, [match, onMatch]);

  return (
    <>
      { title }
      <Link to={ to } />
    </>
  );
};

export default MenuLink;
