import { generatePath }                             from 'react-router-dom';
import { LIST_ID_TAG, ORGANIZATION_TAG, USERS_TAG } from '../../core/constants/api.tags.constants';
import { ORGANIZATION_URIS }                        from '../../core/constants/api.uris.constants';
import { ApiOrganization }                          from '../../core/interfaces/api/ApiOrganization';
import { ApiOrganizationPetition }                  from '../../core/interfaces/api/ApiOrganizationPetition';
import ListData                                     from '../../core/interfaces/general/ListData';
import PageableInputType                            from '../../core/shared/utils/pageableInputType';
import fundEducationApi                             from '../apis/fundEducation.api';

export const organizationApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizations:
      build.query<ListData<ApiOrganization>, PageableInputType>({
        query: ({ page = 1, perPage = 10 }) => ({
          url: `${generatePath(ORGANIZATION_URIS.root)}?page=${page}&perPage=${perPage}`,
          method: 'GET',
        }),
        providesTags: (result) => (
          result
            ? [
              ...result.data.map(({ id }) => ({ type: ORGANIZATION_TAG, id } as const)),
              { type: ORGANIZATION_TAG, id: LIST_ID_TAG },
            ]
            : [{ type: ORGANIZATION_TAG, id: LIST_ID_TAG }]),
      }),
    getOrganization:
      build.query<ApiOrganization, void>({
        query: () => ORGANIZATION_URIS.organization,
        providesTags: () => [{ type: ORGANIZATION_TAG }],
      }),
    getOrganizationPetition:
      build.query<ApiOrganizationPetition, void>({
        query: () => ORGANIZATION_URIS.petition,
        providesTags: () => [{ type: ORGANIZATION_TAG }],
      }),
    createOrganization: build.mutation<null, FormData>({
      query: (body) => ({
        url: ORGANIZATION_URIS.organization,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: ORGANIZATION_TAG }],
    }),
    updateOrganization: build.mutation<null, FormData>({
      query: (body) => {
        body.append('_method', 'PUT');
        return {
          url: ORGANIZATION_URIS.organization,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: ORGANIZATION_TAG }],
    }),
    deleteOrganizationAndResponsible: build.mutation<null, string>({
      query: (id) => ({
        url: generatePath(ORGANIZATION_URIS.delete, { id }),
        method: 'DELETE',
      }),
      invalidatesTags: () => [
        { type: ORGANIZATION_TAG, id: LIST_ID_TAG },
        { type: USERS_TAG },
      ],
    }),
    updatePetitionMessage: build.mutation<null, { message: string }>({
      query: (body) => ({
        url: ORGANIZATION_URIS.message,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: ORGANIZATION_TAG }],
    }),
    uploadPetitionDocuments: build.mutation<null, FormData>({
      query: (body) => {
        body.append('_method', 'PUT');
        return {
          url: ORGANIZATION_URIS.documents,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: ORGANIZATION_TAG }],
    }),
    completePetition: build.mutation<null, null>({
      query: () => ({
        url: ORGANIZATION_URIS.complete,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: ORGANIZATION_TAG }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useGetOrganizationPetitionQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useUpdatePetitionMessageMutation,
  useUploadPetitionDocumentsMutation,
  useDeleteOrganizationAndResponsibleMutation,
  useCompletePetitionMutation,
} = organizationApi;
