import { UploadableDocument }   from '../../../../core/interfaces/api/ApiPetitionDocument';
import { ImageFieldValuesType } from '../../../fields/ImageField';

export type OrganizationFields = {
  name: string;
  description: string;
  location: string;
  imageUrl: ImageFieldValuesType;
}

export const buildOrganizationFormData = (values: OrganizationFields) => {
  const frmData = new FormData();

  const {
    description, imageUrl, location, name,
  } = values;

  if (imageUrl !== undefined && typeof imageUrl !== 'string') {
    frmData.append('image', imageUrl);
  }
  frmData.append('name', name);
  frmData.append('description', description);
  frmData.append('location', location);

  return frmData;
};

export const buildPetitionDocumentsFormData = (documents: UploadableDocument[]) => {
  const frmData = new FormData();

  documents.forEach((document, key) => {
    const {
      file, id, name,
    } = document;

    frmData.append(`documents[${key}][name]`, name);
    if (id !== undefined) frmData.append(`documents[${key}][id]`, id);
    if (file !== undefined) frmData.append(`documents[${key}][file]`, file);
  });

  return frmData;
};
