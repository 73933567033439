import { Col, Row }       from 'antd';
import React              from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

interface Props {
  name: string;
  handleOnView: () => void;
  handleOnDelete: () => void;
}

const PanelHeader = (props: Props) => {
  const { t } = useTranslation(['common', 'admin']);

  const { name, handleOnView, handleOnDelete } = props;

  const onView = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleOnView();
  };

  const onDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleOnDelete();
  };

  return (
    <div>
      <div className='text-h4 text-primary'>{ name }</div>
      <Row gutter={[16, 0]}>
        <Col className='gutter-row'>
          <button
            type='button'
            className='btn-text-custom btn-text-custom-primary'
            onClick={ (event) => onView(event) }
          >
            { t('admin:organization.actions.see_responsible') }
          </button>
        </Col>
        <Col className='gutter-row'>
          <button type='button' className='btn-text-custom btn-text-custom-primary'>
            { t('admin:user.actions.configure_gateway') }
          </button>
        </Col>
        <Col className='gutter-row'>
          <button
            type='button'
            className='btn-text-custom btn-text-custom-secondary'
            onClick={ (event) => onDelete(event) }
          >
            { t('common:delete') }
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default PanelHeader;
