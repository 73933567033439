import React from 'react';
import {
  AmericanExpressPng, ConfettiGif,
  DonationHeartPng,
  InstitutionalProjectPng,
  MasterCardPng,
  StudentProjectPng,
  VisaPng,
} from '../../../core/constants/assets.constants';

type Props = {
  id?: string;
  className?: string;
}

export const InstitutionalProjectImg: React.FC<Props> = (props) => (
  <img
    alt='institutional-project'
    src={ InstitutionalProjectPng }
    { ...props }
  />
);
export const StudentProjectImg: React.FC<Props> = (props) => (
  <img
    alt='student-project'
    src={ StudentProjectPng }
    { ...props }
  />
);
export const DonationHeartImg: React.FC<Props> = (props) => (
  <img
    alt='donation-heart'
    src={ DonationHeartPng }
    { ...props }
  />
);
export const VisaImg: React.FC<Props> = (props) => (
  <img
    alt='visa'
    src={ VisaPng }
    { ...props }
  />
);
export const MasterCardImg: React.FC<Props> = (props) => (
  <img
    alt='mastercard'
    src={ MasterCardPng }
    { ...props }
  />
);
export const AmericanExpressImg: React.FC<Props> = (props) => (
  <img
    alt='americanexpress'
    src={ AmericanExpressPng }
    { ...props }
  />
);
export const ConfettiImg: React.FC<Props> = (props) => (
  <img
    alt='confetti'
    src={ ConfettiGif }
    { ...props }
  />
);
