import { generatePath } from 'react-router-dom';
import {
  PROJECT_UPDATES_LIST_TAG,
  PROJECT_UPDATE_TAG,
} from '../../core/constants/api.tags.constants';
import { PROJECT_UPDATES_URIS, PROJECTS_URIS } from '../../core/constants/api.uris.constants';
import { ApiProjectUpdate }                    from '../../core/interfaces/api/ApiProjectUpdate';
import ListData                                from '../../core/interfaces/general/ListData';
import PageableInputType                       from '../../core/shared/utils/pageableInputType';
import fundEducationApi                        from '../apis/fundEducation.api';

type GetProjectUpdatesInputType = {
  projectId: string;
} & PageableInputType;

export const projectsUpdatesApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectUpdates: build.query<ListData<ApiProjectUpdate>, GetProjectUpdatesInputType>({
      query: ({
        projectId,
        page = 1,
        perPage = 5,
      }) => `${generatePath(PROJECTS_URIS.updates, { projectId })}?page=${page}&perPage=${perPage}`,
      providesTags: (result, _error, { projectId }) => (
        result
          ? [
            ...result.data.map(({ id }) => ({ type: PROJECT_UPDATE_TAG, id } as const)),
            { type: PROJECT_UPDATES_LIST_TAG, id: projectId },
          ]
          : [{ type: PROJECT_UPDATES_LIST_TAG, id: projectId }]
      ),
    }),
    getProjectUpdate: build.query<ApiProjectUpdate, string | undefined>({
      query: (id) => generatePath(PROJECT_UPDATES_URIS.action, { id }),
      providesTags: (_result, _error, id) => [{ type: PROJECT_UPDATE_TAG, id }],
    }),
    createProjectUpdate: build.mutation<null, FormData>({
      query: (body) => ({
        url: PROJECT_UPDATES_URIS.root,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, _error, body) => [
        { type: PROJECT_UPDATES_LIST_TAG, id: body.get('project_id')?.toString() },
      ],
    }),
    updateProjectUpdate: build.mutation<null, { id: string; body: FormData }>({
      query: ({ id, body }) => {
        body.append('_method', 'PUT');

        return {
          url: generatePath(PROJECT_UPDATES_URIS.action, { id }),
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: PROJECT_UPDATE_TAG, id },
      ],
    }),
    deleteProjectUpdate: build.mutation<null, string>({
      query: (id) => ({
        url: generatePath(PROJECT_UPDATES_URIS.action, { id }),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [{ type: PROJECT_UPDATE_TAG, id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetProjectUpdatesQuery,
  useGetProjectUpdateQuery,
  useCreateProjectUpdateMutation,
  useUpdateProjectUpdateMutation,
  useDeleteProjectUpdateMutation,
} = projectsUpdatesApi;
