import { CloseOutlined }  from '@ant-design/icons';
import { ModalFuncProps } from 'antd/lib/modal';
import * as React         from 'react';

export const defaultInfoProps: ModalFuncProps = {
  maskClosable: true,
  icon: null,
  width: '70%',
  okButtonProps: {
    type: 'link',
    shape: 'circle',
    className: 'cross-close-button',
  },
  okText: <CloseOutlined style={ { fontSize: '20px', color: '#5B8DEF' } } />,
};

export const defaultConfirmProps: ModalFuncProps = {
  maskClosable: true,
  icon: null,
  okButtonProps: { shape: 'round' },
  cancelButtonProps: { shape: 'round' },
};
