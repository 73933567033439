import React, { useState }   from 'react';
import { useTranslation }    from 'react-i18next';
import { DefaultOptionType } from 'antd/lib/select';
import { CustomSelect }      from '../../forms/shared/CustomSelect';

const generateOptions = (engLabel: string, espLabel: string): DefaultOptionType[] => [{
  value: 'en',
  label: engLabel,
},
{
  value: 'es',
  label: espLabel,
}];

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const [options, setOptions] = useState<DefaultOptionType[]>(generateOptions(t('english'), t('spanish')));
  const [selectedValue, setSelectedValue] = useState<string>(i18n.language.substring(0, 2));

  const handleLanguageChange = (lng: string): void => {
    i18n.changeLanguage(lng).then(() => {
      setOptions(generateOptions(t('english'), t('spanish')));
      setSelectedValue(lng);
    });
  };

  return (
    <CustomSelect
      options={ options }
      defaultValue={ i18n.language.substring(0, 2) }
      value={ selectedValue }
      onSelect={ handleLanguageChange }
    />
  );
};
