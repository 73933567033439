import { useKeycloak }                              from '@react-keycloak/web';
import React, { useState }                          from 'react';
import {
  Col, Row, Radio, InputNumber, Form,
  Checkbox, Tooltip, Input, Button, FormInstance,
}                                from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { UserAvatar }            from '../../../../../../components/profile';
import { CategoryProgress }      from '../../../../../../components/projects/CategoryProgress';
import useDBTranslation          from '../../../../../hooks/useDBTranslation';
import { DonationFields }        from '../../../../../interfaces/api/ApiDonation';
import { ApiProject }            from '../../../../../interfaces/api/ApiProject';
import { maxNumber, minNumber }  from '../../../../../shared/utils/formValidators';
import './index.less';

const { Item } = Form;
const { TextArea } = Input;

type Props = {
  project: ApiProject | undefined;
  form: FormInstance<DonationFields>;
  onNext: () => void;
}

const minAmount = 0.01;

export const DonationGeneralDataPanel: React.FC<Props> = ({ project, form, onNext }) => {
  const { t } = useTranslation(['common', 'sponsor']);
  const tDB = useDBTranslation();
  const { keycloak } = useKeycloak();

  const getSelectedCategoryRemainder = () => {
    const selectedCategory = project?.categories.find(({ id }) => id === form.getFieldValue('projectCategoryId'));
    if (selectedCategory !== undefined) {
      return selectedCategory.goal - selectedCategory.raised;
    }
    return 50000;
  };

  const [maxAmount, setMaxAmount] = useState(getSelectedCategoryRemainder());

  const onRadioChange = () => setMaxAmount(getSelectedCategoryRemainder());

  const nextStep = () => form.validateFields(['projectCategoryId', 'amount', 'message']).then(() => onNext()).catch(() => { /* do nothing */ });

  return (
    <Row gutter={[0, 24]} id='donation-general-form'>
      <Col span={24}>
        <h2>{ t('common:projects.categories.title') }</h2>
      </Col>
      {
        project?.categories.map((category) => (
          <Col span={24} key={category.id}>
            <CategoryProgress
              showHeader
              category={category}
            />
          </Col>
        ))
      }
      <Col span={24}>
        <b>{ t('common:projects.continuous_donation_disclaimer') }</b>
      </Col>
      <Col span={24}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <h4>{ t('sponsor:donations.title') }</h4>
            <p>{ t('sponsor:donations.select') }</p>
          </Col>
          <Col span={24}>
            <Item
              name='projectCategoryId'
              rules={ [
                {
                  required: true,
                  message: t('sponsor:donations.form.category.required'),
                },
              ] }
            >
              <Radio.Group
                optionType='button'
                buttonStyle='solid'
                onChange={onRadioChange}
              >
                <Row gutter={[20, 20]} justify='center'>
                  {
                    project?.categories.map((category) => (
                      <Col
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        key={category.id}
                        className='donation-radio-col'
                      >
                        <Radio
                          value={category.id}
                          disabled={
                            parseFloat(category.raised as unknown as string)
                            >= parseFloat(category.goal as unknown as string)
                          }
                        >
                          { tDB(category.type, 'name') }
                        </Radio>
                      </Col>
                    ))
                  }
                </Row>
              </Radio.Group>
            </Item>
          </Col>
          <Col span={24}>
            <Row
              id='donation-amount-panel'
              align='middle'
              justify='center'
            >
              <Col xs={24} sm={8} md={4} lg={4}>
                <Item
                  name='amount'
                  className='amount-input'
                  rules={ [
                    {
                      required: true,
                      message: t('sponsor:donations.form.donated_amount.required'),
                    },
                    {
                      validator: minNumber(minAmount, t('sponsor:donations.form.donated_amount.min')),
                    },
                    {
                      validator: maxNumber(maxAmount, t('sponsor:donations.form.donated_amount.max')),
                    },
                  ] }
                >
                  <InputNumber
                    type='number'
                    min={ minAmount }
                    max={ maxAmount }
                    precision={ 2 }
                    step={ 0.01 }
                  />
                </Item>
              </Col>
              <Col xs={24} sm={16} md={10} lg={12}>
                <span className='amount-label'>
                  { t('sponsor:donations.form.donated_amount.title') }
                </span>
              </Col>
              <Col xs={24} sm={24} md={10} lg={8}>
                {
                  keycloak.authenticated && (
                    <Tooltip
                      color='white'
                      id='reminderTooltip'
                      title={(
                        <>
                          <span>
                            { t('sponsor:donations.form.donation_reminder.tooltip.title') }
                          </span>
                          <p>
                            { t('sponsor:donations.form.donation_reminder.tooltip.description') }
                          </p>
                        </>
                      )}
                    >
                      {/* This wrapping prevents the firing of a findDOMNode deprecation error */}
                      <div>
                        <Item
                          name='reminderEnabled'
                          valuePropName='checked'
                          className='reminder-checkbox'
                        >
                          <Checkbox>
                            { t('sponsor:donations.form.donation_reminder.title') }
                          </Checkbox>
                        </Item>
                      </div>
                    </Tooltip>
                  )
                }
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <span className='currency-disclaimer'>
              { t('sponsor:donations.disclaimers.currency') }
            </span>
          </Col>
          <Col span={24}>
            <h4>
              { t('sponsor:donations.form.message.title') }
            </h4>
          </Col>
          <Col span={24}>
            <div className='donation-message'>
              <UserAvatar size={32} />
              <Item
                name='message'
                rules={ [
                  {
                    required: true,
                    message: t('sponsor:donations.form.message.required'),
                  },
                ] }
              >
                <TextArea
                  rows={4}
                  showCount
                  maxLength={200}
                  placeholder={ t('sponsor:donations.form.message.placeholder') }
                />
              </Item>
            </div>
          </Col>
          {
            keycloak.authenticated && (
              <Col span={ 24 }>
                <Item
                  name='anonymous'
                  valuePropName='checked'
                >
                  <Checkbox>
                    { t('sponsor:donations.form.anonymous.title') }
                  </Checkbox>
                </Item>
              </Col>
            )
          }
          <Col span={24} className='disclaimers-container'>
            <span>
              <Trans i18nKey='sponsor:donations.disclaimers.terms'>
                { /* eslint-disable */ }
                Please read the <strong>Terms of Use</strong> and <strong>Privacy Policy</strong> before supporting.
              </Trans>
            </span>
            <span>
              { t('sponsor:donations.disclaimers.agreement') }
            </span>
            <span>
              { t('sponsor:donations.disclaimers.refunds') }
            </span>
          </Col>
          <Col span={24}>
            <Button
              block
              type='primary'
              onClick={nextStep}
            >
              { t('sponsor:donations.next') }
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
