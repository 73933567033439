import {
  Button,
  Col, DatePicker, Form, FormInstance, Input, Modal, Row, Select,
}                                        from 'antd';
import moment                            from 'moment';
import * as React                        from 'react';
import { Search }                        from 'react-feather';
import { useTranslation }                from 'react-i18next';
import { useParams }                     from 'react-router-dom';
import { useGetOrganizationGradesQuery } from '../../../../adapters/services/grades.service';
import { useGetAvailableStudentsQuery }  from '../../../../adapters/services/students.service';
import { defaultInfoProps }              from '../../../shared/defaults/defaultModalProps';
import { ProjectFields }                 from './helpers';
import StudentSelector                   from './StudentSelector';

const { Item } = Form;

type Props = {
  isNew?: boolean;
  form: FormInstance<ProjectFields>;
};

const StudentFormItems: React.FC<Props> = ({ isNew, form }) => {
  const { t } = useTranslation(['common', 'organization']);
  const { institutionId } = useParams();

  const { data: students } = useGetAvailableStudentsQuery(
    institutionId,
    { skip: institutionId === undefined },
  );

  const { data: grades } = useGetOrganizationGradesQuery(
    institutionId,
    { skip: institutionId === undefined },
  );

  const selectStudent = (studentId: string) => {
    const student = students?.find(({ id }) => id === studentId);
    if (student !== undefined) {
      form.setFieldsValue({
        student: {
          id: student.id,
          firstName: student.firstName,
          lastName: student.lastName,
          birthdate: moment(student.birthdate, 'YYYY-MM-DD'),
          gradeId: student.gradeId,
        },
      });
    }
    Modal.destroyAll();
  };

  const onSearchUser = () => {
    Modal.info({
      ...defaultInfoProps,
      title: t('organization:projects.form.students.search.title'),
      width: '65%',
      content: <StudentSelector
        selectStudent={ selectStudent }
        students={ students ?? [] }
      />,
    });
  };

  return (
    <Row gutter={ [0, 24] }>
      {
        isNew && (
          <Button
            block
            className='search-btn'
            icon={ <Search /> }
            onClick={ onSearchUser }
            disabled={ students === undefined || students.length === 0 }
          >
            { t('organization:projects.form.students.search.button') }
          </Button>
        )
      }
      <Col span={ 24 }>
        <Item
          label={ t('organization:projects.form.student.first_name.label') }
          name={ ['student', 'firstName'] }
          rules={ [
            {
              required: true,
              message: t('organization:projects.form.student.first_name.required'),
            },
          ] }
        >
          <Input
            name='student.firstName'
            placeholder={ t('organization:projects.form.student.first_name.placeholder') }
          />
        </Item>
      </Col>
      <Col span={ 24 }>
        <Item
          label={ t('organization:projects.form.student.last_name.label') }
          name={ ['student', 'lastName'] }
          rules={ [
            {
              required: true,
              message: t('organization:projects.form.student.last_name.required'),
            },
          ] }
        >
          <Input
            name='student.lastName'
            placeholder={ t('organization:projects.form.student.last_name.placeholder') }
          />
        </Item>
      </Col>
      <Col span={ 24 }>
        <Item
          label={ t('organization:projects.form.student.birthdate.label') }
          name={ ['student', 'birthdate'] }
          rules={ [
            {
              required: true,
              message: t('organization:projects.form.student.birthdate.required'),
            },
          ] }
        >
          <DatePicker
            name='student.birthdate'
            disabledDate={ (d) => !d || d.isAfter(moment()) }
          />
        </Item>
      </Col>
      <Col span={ 24 }>
        <Item
          name={ ['student', 'gradeId'] }
          label={ t('organization:projects.form.student.grade.label') }
          rules={ [
            { required: true, message: t('organization:projects.form.student.grade.required') },
          ] }
        >
          <Select
            placeholder={ t('organization:projects.form.student.grade.placeholder') }
            options={ grades?.map(({ id, name }) => ({ value: id, label: name }))}
          />
        </Item>
      </Col>
    </Row>
  );
};

export default StudentFormItems;
