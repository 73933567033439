import React                         from 'react';
import {
  Button, Col, Row, Skeleton, Tag,
} from 'antd';
import parse                                    from 'html-react-parser';
import { ArrowLeft }                            from 'react-feather';
import { useTranslation }                       from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  useGetBlogEntriesRelatedQuery,
  useGetBlogEntryQuery,
}                                               from '../../../../adapters/services/blogEntriesPublic.service';
import { LocalizedTimeAgo } from '../../../../components/l10n';
import LoadingCardRow       from '../../../../components/LoadingCardRow';
import APP_PATHS            from '../../../constants/routes.constants';
import { BlogEntryCard }    from '../BlogPage/components/BlogEntryCard';

import './index.less';

const BlogEntryPage = () => {
  const { t } = useTranslation();
  const { blogEntryId } = useParams();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(`${APP_PATHS.public.blog.root}`);
  };

  const { data: blogEntry, isLoading, isFetching } = useGetBlogEntryQuery(blogEntryId);
  const {
    data: blogEntriesRelated,
    isLoading: relatedLoading,
  } = useGetBlogEntriesRelatedQuery(blogEntryId);

  return (
    <div className='blog-entry-page container mx-auto pt-10 w-100'>
      <Button type='primary' onClick={ goBack } className='d-flex justify-space-around align-center mb-4'>
        <ArrowLeft />
        <span className='ml-2'>{ t('go_back') }</span>
      </Button>
      <Skeleton loading={isLoading || isFetching}>
        <h1 className='text-h1 text-primary mb-4'>{ blogEntry?.title }</h1>
        <p className='text-body-bold text-gray-3 mb-8'>
          { blogEntry?.publishedAt !== undefined && (
            <LocalizedTimeAgo date={ blogEntry.publishedAt } />) }
        </p>
        <img src={ blogEntry?.imageUrl } alt={ blogEntry?.title } className='blog-entry-page--image' />
        {
          blogEntry?.tags?.map((tag) => (
            <Tag key={tag.id} className='blog-entry-page--tag'>{ tag.name }</Tag>
          ))
        }
        <p className='text-justify mb-16'>
          { blogEntry?.body !== undefined && (parse(blogEntry.body)) }
        </p>
      </Skeleton>

      {
        blogEntriesRelated?.length !== 0 && (
          <p className='text-h1 text-primary text-center mb-8'>
            { t('public:blog.related') }
          </p>
        )
      }

      <Row gutter={ [24, 24] } className='mb-16'>
        {
        relatedLoading
          ? <LoadingCardRow count={ 1 } />
          : blogEntriesRelated?.map((entry) => (
            <Col className='gutter-row d-flex' xs={ 24 } md={ 12 } xl={ 6 } key={ entry.id }>
              <BlogEntryCard
                key={ entry.id }
                blogEntry={ entry }
                to={ generatePath(`${APP_PATHS.public.blog.see}`, { blogEntryId: entry.id }) }
                textTruncateLines={ 4 }
              />
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

export default BlogEntryPage;
