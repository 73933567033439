import { Layout }                    from 'antd';
import React                         from 'react';
import { Outlet }                    from 'react-router-dom';
import Breadcrumbs                   from '../../../components/layouts/Breadcrumbs';
import { SPONSOR_BREADCRUMBS_ITEMS } from '../../../components/layouts/Breadcrumbs/breadcrumbs.constants';

const { Content } = Layout;

const SponsorLayout: React.FC = () => (
  <div className='container mx-auto pt-10 w-100'>
    <Content>
      <Breadcrumbs items={ SPONSOR_BREADCRUMBS_ITEMS } />
      <Outlet />
    </Content>
  </div>
);

export default SponsorLayout;
