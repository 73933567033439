import { AuthClientEvent }  from '@react-keycloak/core/lib/types';
import store                from '../../store';
import { setKeycloakError } from '../../store/features/authSlice';

// eslint-disable-next-line import/prefer-default-export
export const handleInitError = (eventType?: AuthClientEvent) => {
  if (eventType && eventType.toLowerCase().indexOf('error') !== -1) {
    const { dispatch } = store;
    dispatch(setKeycloakError(eventType));
  }
};
