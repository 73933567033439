import { useSearchParams } from 'react-router-dom';

type onFilterType = (currentState: string | null, paramName: string, value?: string) => void;

type FilterSearchType = () => {
  searchParams: URLSearchParams;
  onFilter: onFilterType;
};

export const useFilterSearchParams: FilterSearchType = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onFilter: onFilterType = (currentState, paramName, value) => {
    if (currentState !== value) {
      searchParams.delete('page');
    }

    if (value !== undefined && value.length !== 0) {
      searchParams.set(paramName, value);
    } else {
      searchParams.delete(paramName);
    }

    setSearchParams(searchParams);
  };

  return { searchParams, onFilter };
};
