import { generatePath }                      from 'react-router-dom';
import {
  LATEST_REPORT_TAG,
  LIST_ID_TAG, PROJECTS_TAG, STUDENTS_TAG,
} from '../../core/constants/api.tags.constants';
import { PROJECTS_URIS }            from '../../core/constants/api.uris.constants';
import { ApiProject }               from '../../core/interfaces/api/ApiProject';
import { ApiStudentSchoolInfo }     from '../../core/interfaces/api/dto/ApiStudentSchoolInfo';
import ListData                     from '../../core/interfaces/general/ListData';
import PageableInputType            from '../../core/shared/utils/pageableInputType';
import serializeObjectToQueryParams from '../../core/shared/utils/serializeObjectToQueryParams';
import fundEducationApi             from '../apis/fundEducation.api';

export type ProjectTypeFilter = 'STUDENT' | 'INSTITUTIONAL';

export type ProjectFilters = {
  title?: string;
  institution?: string;
  type?: ProjectTypeFilter;
  status?: 'ACTIVE' | 'FINISHED';
  order?: 'DATE' | 'RANDOM';
  include?: 'DELETED';
}

type GetProjectsInputType = {
  filters?: ProjectFilters;
} & PageableInputType;

export const projectsApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedProjects: build.query<ListData<ApiProject>, GetProjectsInputType>({
      query: ({
        page = 1,
        perPage = 9,
        filters = {},
      }) => `${PROJECTS_URIS.publicRoot}?page=${page}&perPage=${perPage}${serializeObjectToQueryParams(filters)}`,
      providesTags: (result) => (
        result
          ? [
            ...result.data.map(({ id }) => ({ type: PROJECTS_TAG, id } as const)),
            { type: PROJECTS_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: PROJECTS_TAG, id: LIST_ID_TAG }]
      ),
    }),
    getProject: build.query<ApiProject, string | undefined>({
      query: (id) => generatePath(PROJECTS_URIS.publicAction, { id }),
      providesTags: (_result, _error, id) => [{ type: PROJECTS_TAG, id }],
    }),
    getStudentSchoolInfo: build.query<ApiStudentSchoolInfo, string>({
      query: (id) => generatePath(PROJECTS_URIS.schoolInfo, { projectId: id }),
      providesTags: (_result, _error, id) => [
        { type: PROJECTS_TAG, id }, { type: LATEST_REPORT_TAG, id },
      ],
    }),
    createProject: build.mutation<null, FormData>({
      query: (body) => ({
        url: PROJECTS_URIS.organizationRoot,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: PROJECTS_TAG, id: LIST_ID_TAG }, { type: STUDENTS_TAG }],
    }),
    updateProject: build.mutation<null, { id: string; body: FormData }>({
      query: ({ id, body }) => {
        body.append('_method', 'PUT');

        return {
          url: generatePath(PROJECTS_URIS.organizationAction, { id }),
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: PROJECTS_TAG, id },
        { type: STUDENTS_TAG },
      ],
    }),
    deleteProject: build.mutation<null, { id: string; body: FormData }>({
      query: ({ id, body }) => {
        body.append('_method', 'DELETE');

        return {
          url: generatePath(PROJECTS_URIS.organizationAction, { id }),
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: PROJECTS_TAG, id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPaginatedProjectsQuery,
  useGetProjectQuery,
  useGetStudentSchoolInfoQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = projectsApi;
