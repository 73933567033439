import React, { useEffect }                           from 'react';
import { useNavigate }                                from 'react-router-dom';
import { useTranslation }                             from 'react-i18next';
import {
  Button, Card, Col, Empty, Modal, Pagination, Row,
}                                                from 'antd';
import { ArrowLeftOutlined }                                               from '@ant-design/icons';
import {
  useGetOrganizationsQuery, useDeleteOrganizationAndResponsibleMutation,
}                                                from '../../../../adapters/services/organizations.service';
import UserForm                                  from '../../../../components/forms/admin/UserForm/form';
import OrganizationCollapse                      from '../../../../components/Users/OrganizationCollapse';
import APP_PATHS                                 from '../../../constants/routes.constants';
import { useAppDispatch }                        from '../../../hooks/store';
import useCrudNotificationResponse               from '../../../hooks/useCrudNotificationResponse';
import usePagination                             from '../../../hooks/usePagination';
import { ApiUser }                               from '../../../interfaces/api/ApiUser';
import defaultCardColProps                       from '../../../shared/defaults/defaultCardColProps';
import { defaultConfirmProps, defaultInfoProps } from '../../../shared/defaults/defaultModalProps';
import { setLoader }                             from '../../../store/features/uiSlice';

import './index.less';

const ListOrganizationsPage = () => {
  const { t } = useTranslation(['common', 'admin']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { page, setQueryPage } = usePagination();

  const { data, isLoading, isFetching } = useGetOrganizationsQuery({ page });

  const [deleteOrganizationAndResponsible, {
    isLoading: deletingOrganization,
    isSuccess: deletedOrganization,
    error: organizationError,
  }] = useDeleteOrganizationAndResponsibleMutation();

  useCrudNotificationResponse({
    isSuccess: deletedOrganization,
    error: organizationError,
    successNotification: {
      message: t('admin:organization.actions.delete.success.title'),
      description: t('admin:organization.actions.delete.success.description'),
    },
  });

  const onViewResponsible = (user: ApiUser) => {
    Modal.info({
      ...defaultInfoProps,
      width: '50%',
      title: t('admin:organization.actions.see_responsible'),
      content: <UserForm entity={user} />,
    });
  };

  const onDeleteResponsible = ({ id }: ApiUser) => {
    Modal.confirm({
      ...defaultConfirmProps,
      title: t('admin:organization.actions.delete.confirm'),
      onOk: () => deleteOrganizationAndResponsible(id),
      width: '50%',
    });
  };

  useEffect(() => {
    dispatch(setLoader(deletingOrganization));
  }, [dispatch, deletingOrganization]);

  return (
    <Row justify='center'>
      <Col { ...defaultCardColProps }>
        <Button className='mb-4' type='primary' icon={<ArrowLeftOutlined />} onClick={() => navigate(APP_PATHS.admin.users)}>
          <span className='text-body-bold'>
            { t('common:go_back') }
          </span>
        </Button>
        <Card loading={(isLoading || isFetching)}>
          <div className='text-h1'>
            { t('admin:user.organizations') }
          </div>

          {
            data !== undefined && (data.data.map((organization) => (
              <OrganizationCollapse
                key={organization.id}
                data={organization}
                handleOnDeleteResponsible={ () => onDeleteResponsible(organization.user) }
                handleOnViewResponsible={ () => onViewResponsible(organization.user) }
              />
            )))
          }
          {
            data?.data.length !== 0
            && (
              <div className='d-flex justify-center mt-4'>
                <Pagination
                  onChange={setQueryPage}
                  current={ page }
                  pageSize={ 10 }
                  total={ data?.total }
                />
              </div>
            )
          }
          { !isFetching && data?.data.length === 0 && <Empty description={ t('admin:organization.not_found_organizations') } /> }
        </Card>
      </Col>
    </Row>
  );
};

export default ListOrganizationsPage;
