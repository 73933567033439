import { Modal }                     from 'antd';
import { createContext, useContext } from 'react';

type ModalFunctionsType = ReturnType<typeof Modal.useModal>[0];

interface AppModalContextInterface {
  modalFn: ModalFunctionsType;
}

const initialCtxValue: AppModalContextInterface = {
  modalFn: {} as ModalFunctionsType,
};

export const AppModalCtx = createContext<AppModalContextInterface>(initialCtxValue);
export const useAppModalContext = () => useContext(AppModalCtx);
