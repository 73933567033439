import { ImageFieldValuesType } from '../../../../components/fields/ImageField';
import { ApiInstitution }       from '../../../interfaces/api/ApiInstitution';
import { ApiInstitutionImage }  from '../../../interfaces/api/ApiInstitutionImage';

export type InstitutionFields = {
  name: string;
  description: string;
  imageUrl: ImageFieldValuesType;
  countryId: string;
  images: ApiInstitutionImage[];
};

export type InstitutionsCallback = (filed: InstitutionFields, entity?: ApiInstitution) => void;

export const buildInstitutionFormData = (values: InstitutionFields) => {
  const frmData = new FormData();

  const {
    name, description, imageUrl, countryId, images,
  } = values;

  if (imageUrl !== undefined && typeof imageUrl !== 'string') {
    frmData.append('image_url', imageUrl);
  }
  frmData.append('name', name);
  frmData.append('description', description);
  frmData.append('country_id', countryId);

  images.forEach((image, key) => {
    const { id, url } = image;
    if (id !== undefined) {
      frmData.append(`images[${key}][id]`, id);
    }
    if (url !== undefined) frmData.append(`images[${key}][url]`, url);
  });

  return frmData;
};
