import {
  Col, Empty, Pagination, Row, Switch,
}                           from 'antd';
import React                                          from 'react';
import { useTranslation }                             from 'react-i18next';
import { generatePath }                               from 'react-router-dom';
import {
  useGetDonatedProjectsQuery,
  useSetReminderMutation, useUnsetReminderMutation,
}                                        from '../../../../adapters/services/sponsorProjects.service';
import LoadingCardRow              from '../../../../components/LoadingCardRow';
import { ProfileCard }             from '../../../../components/profile';
import ProjectCard                 from '../../../../components/projects/ProjectCard';
import APP_PATHS                   from '../../../constants/routes.constants';
import useCrudNotificationResponse from '../../../hooks/useCrudNotificationResponse';
import usePagination               from '../../../hooks/usePagination';
import { ApiSponsorProject }       from '../../../interfaces/api/ApiProject';
import { defaultPagination }       from '../../../shared/defaults/pagination';
import './index.less';

const perPage = 6;

const SponsorHomePage = () => {
  const { t } = useTranslation(['common', 'sponsor']);
  const { page, setQueryPage } = usePagination();

  const { data: projects, isLoading, isFetching } = useGetDonatedProjectsQuery({ page, perPage });
  const [setReminder, {
    isLoading: isSetting,
    isSuccess: set,
    error: setError,
  }] = useSetReminderMutation();

  const [unsetReminder, {
    isLoading: isUnsetting,
    isSuccess: unset,
    error: unsetError,
  }] = useUnsetReminderMutation();

  useCrudNotificationResponse({
    isSuccess: set,
    error: setError,
    successNotification: {
      message: t('sponsor:projects.reminders.set.success.title'),
      description: t('sponsor:projects.reminders.set.success.description'),
    },
  });
  useCrudNotificationResponse({
    isSuccess: unset,
    error: unsetError,
    successNotification: {
      message: t('sponsor:projects.reminders.unset.success.title'),
      description: t('sponsor:projects.reminders.unset.success.description'),
    },
  });

  const buildLink = (projectId: string) => generatePath(
    APP_PATHS.sponsor.sponsorProjectDetail,
    { projectId },
  );

  const toggleReminder = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    project: ApiSponsorProject,
  ): void => {
    event.stopPropagation();
    if (project.notificationsEnabled) {
      unsetReminder(project.id);
    } else {
      setReminder(project.id);
    }
  };

  return (
    <div id='sponsor-home'>
      <ProfileCard />
      <h1 id='sponsor-projects-title' className='text-primary text-center'>
        { t('sponsor:projects.title') }
      </h1>
      <Row gutter={[24, 32]}>
        {
          (isLoading || isFetching) && <LoadingCardRow count={ 3 } />
        }
        {
          projects?.total === 0
            ? <Col span={24}><Empty description={ t('common:projects.empty') } /></Col>
            : (
              <>
                {projects?.data.map((project) => (
                  <Col
                    xs={ 24 }
                    sm={ 12 }
                    xl={ 8 }
                    key={ project.id }
                  >
                    <ProjectCard project={project} to={buildLink(project.id)} animation>
                      <div className='switch-container'>
                        {
                          !project.finished && project.statusId === 1 && (
                            <>
                              <Switch
                                className='project-switch'
                                checked={project.notificationsEnabled}
                                loading={ isSetting || isUnsetting }
                                onChange={ (_checked, event) => toggleReminder(event, project) }
                              />
                              {' '}
                              <span className='project-switch-label'>
                                {
                                  project.notificationsEnabled
                                    ? t('sponsor:projects.reminders.set.label')
                                    : t('sponsor:projects.reminders.unset.label')
                                }
                              </span>
                            </>
                          )
                        }
                      </div>
                    </ProjectCard>
                  </Col>

                ))}
                <Col span={ 24 } className='text-center'>
                  <Pagination
                    pageSize={ perPage }
                    onChange={ setQueryPage }
                    current={ page }
                    total={ projects?.total }
                    { ...defaultPagination }
                  />
                </Col>
              </>
            )
        }
      </Row>
    </div>
  );
};

export default SponsorHomePage;
