import { sanitizeBody }    from '../../../../components/fields/WYGIWYSField';
import { BlogEntryFields } from '../../../../components/forms/admin/BlogEntryForm/form';

const buildBlogEntryFormData = (values: BlogEntryFields) => {
  const frmData = new FormData();

  const {
    body, imageUrl, tagsIds, title, typeId,
  } = values;

  if (imageUrl !== undefined && typeof imageUrl !== 'string') {
    frmData.append('image', imageUrl);
  }
  frmData.append('title', title);
  frmData.append('body', sanitizeBody(body?.html as string));
  frmData.append('type_id', typeId.toString());
  tagsIds.forEach((c) => frmData.append('tags_ids[]', c));

  return frmData;
};

export default buildBlogEntryFormData;
