import { useTranslation } from 'react-i18next';

type DBTranslationFunction = (entity: object | undefined, property: string) => string;

const useDBTranslation = (): DBTranslationFunction => {
  const { i18n } = useTranslation();

  return (entity, property) => {
    type ObjectKey = keyof typeof entity;
    const lang = i18n.language.substring(0, 2);
    if (entity === undefined) {
      return '';
    }
    if (lang === 'en') {
      return entity[`${property}En` as ObjectKey];
    }
    return entity[property as ObjectKey];
  };
};

export default useDBTranslation;
