import { ImageFieldValuesType } from '../../../../components/fields/ImageField';
import { ApiProjectUpdate }     from '../../../interfaces/api/ApiProjectUpdate';

export type ProjectUpdatesFields = {
  title: string;
  description: string;
  imageUrl: ImageFieldValuesType;
  projectId: string;
};

export type ProjectUpdatesCallback = (
  filled: ProjectUpdatesFields, entity?: ApiProjectUpdate
) => void;

export const buildProjectUpdateFormData = (values: ProjectUpdatesFields) => {
  const frmData = new FormData();

  const {
    title, description, imageUrl,
  } = values;

  if (imageUrl !== undefined && typeof imageUrl !== 'string') {
    frmData.append('image_url', imageUrl);
  }
  frmData.append('title', title);
  frmData.append('description', description);

  return frmData;
};
