import {
  Button, Card, Col, Row,
}                                     from 'antd';
import React, { useEffect, useState }           from 'react';
import { useTranslation }                       from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useGetSponsorCountQuery }              from '../../../../../../adapters/services/donations.service';
import APP_PATHS                                from '../../../../../constants/routes.constants';
import useGetRoles                              from '../../../../../hooks/useGetRoles';
import { ApiProject }                           from '../../../../../interfaces/api/ApiProject';
import { CategoryProgress }                     from '../../../../../../components/projects/CategoryProgress';

type Props = {
  project: ApiProject;
  canDonate: boolean;
}

export const ProjectCategoriesSummary: React.FC<Props> = ({ project, canDonate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { role: localRole } = useGetRoles();

  const { data } = useGetSponsorCountQuery(project.id);

  const [donateValid, setDonateValid] = useState(false);

  useEffect(() => {
    setDonateValid(canDonate && localRole !== 'ADMIN' && localRole !== 'ORGANIZATION');
  }, [canDonate, localRole]);

  const goToDonate = () => navigate(generatePath(APP_PATHS.public.donationForm, params));

  return (
    <Card>
      <h2>{ t('projects.categories.title') }</h2>
      <p className='text-gray'>
        <b>{ data?.count ?? 0 }</b>
        { ' ' }
        { t('sponsors') }
      </p>
      <Row gutter={[0, 24]}>
        {
          project.categories.map((category) => (
            <Col span={24} key={category.id}>
              <CategoryProgress
                showHeader
                category={category}
              />
            </Col>
          ))
        }
      </Row>
      {
        donateValid && (
          <Button
            block
            type='primary'
            className='mt-5'
            onClick={ goToDonate }
          >
            { t('projects.donate') }
          </Button>
        )
      }
    </Card>
  );
};
