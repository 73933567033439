import APP_PATHS from './routes.constants';

export const KEYCLOAK_HOST_URL = process.env.REACT_APP_KEYCLOACK_HOST;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM ?? 'master';
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOACK_CLIENT_ID as string;

const { origin } = window.location;
export const REDIRECT_PATHS = {
  login: origin + APP_PATHS.home,
  logout: origin + APP_PATHS.home,
};
