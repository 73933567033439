import { Card, Col } from 'antd';
import { range }     from 'lodash';
import React         from 'react';
import './index.less';

type Props = {
  count: 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;
}

const LoadingCardRow: React.FC<Props> = ({ count }) => (
  <>
    {
      range(0, count).map((key) => (
        <Col span={24 / count} key={ key } className='loading-col'>
          <Card loading />
        </Col>
      ))
    }
  </>
);

export default LoadingCardRow;
