import {
  useCallback, useEffect, useState,
}                                              from 'react';
import { SerializedError }                     from '@reduxjs/toolkit';
import { FetchBaseQueryError }                 from '@reduxjs/toolkit/query';
import { FormInstance, Modal, notification }   from 'antd';
import { ArgsProps }                           from 'antd/lib/notification';
import { useTranslation }                      from 'react-i18next';
import setFormErrors, { LaravelErrorResponse } from '../shared/utils/setFormErrors';

type Props = {
  form?: FormInstance;
  isSuccess: boolean;
  successNotification?: ArgsProps;
  errorNotification?: ArgsProps;
  error: FetchBaseQueryError | SerializedError | undefined;
  onClose?: () => void;
}

type CrudNotificationResponseType = (props: Props) => void;

const useCrudNotificationResponse: CrudNotificationResponseType = ({
  form,
  isSuccess,
  error,
  successNotification,
  errorNotification,
  onClose,
}) => {
  const { t, i18n: { language } } = useTranslation();
  const buildDefaultSuccess = useCallback((): ArgsProps => ({
    message: t('operation.success.title'),
    description: t('operation.success.description'),
  }), [t, language]);
  const buildDefaultError = useCallback((): ArgsProps => ({
    message: t('operation.error.title'),
    description: t('operation.error.description'),
  }), [t, language]);
  const [defaultSuccess, setDefaultSuccess] = useState<ArgsProps>(buildDefaultSuccess);
  const [defaultError, setDefaultError] = useState<ArgsProps>(buildDefaultError);

  useEffect(() => {
    setDefaultSuccess(buildDefaultSuccess);
    setDefaultError(buildDefaultError);
  }, [language]);

  useEffect(() => {
    if (isSuccess) {
      notification.success(successNotification ?? defaultSuccess);
      Modal.destroyAll();
      if (onClose !== undefined) {
        onClose();
      }
    }
  }, [form, isSuccess]);

  useEffect(() => {
    if (error !== undefined) {
      if ('status' in error && error.status === 409) {
        notification.warn({
          ...(errorNotification ?? defaultError),
          description: (error.data as LaravelErrorResponse).message,
        });
      } else {
        notification.warn(errorNotification ?? defaultError);
      }
      if (form !== undefined && 'status' in error && error.status === 422) {
        setFormErrors(form, error);
      }
    }
  }, [error, form]);
};

export default useCrudNotificationResponse;
