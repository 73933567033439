import ApiBlogTag  from './ApiBlogTag';
import ApiBlogType from './ApiBlogType';
import { ApiUser } from './ApiUser';

export enum BlogStatusEnum {
  DRAFT = 1,
  PUBLISHED
}

export enum BlogTypeEnum {
  POST = 1,
  HOME = 2
}

interface ApiBlogEntry {
  id: string;
  title: string;
  body: string;
  imageUrl: string;
  publishedAt?: string;
  authorId: string;
  author: ApiUser;
  typeId: number;
  type: ApiBlogType;
  blogStatusId: BlogStatusEnum;
  tags?: ApiBlogTag[];
}

export default ApiBlogEntry;
