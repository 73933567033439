import {
  Col, Empty, Pagination, Row,
}                                     from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { generatePath }               from 'react-router-dom';
import {
  BlogEntriesFilters,
  useGetBlogEntriesByTypeQuery,
}                                     from '../../../../adapters/services/blogEntriesPublic.service';
import { SearchInput }           from '../../../../components/inputs';
import APP_PATHS                 from '../../../constants/routes.constants';
import { useFilterSearchParams } from '../../../hooks/useFilterSearchParams';
import usePagination             from '../../../hooks/usePagination';
import { BlogTypeEnum }          from '../../../interfaces/api/ApiBlogEntry';
import { defaultPagination }     from '../../../shared/defaults/pagination';
import { BlogEntryCard }         from './components/BlogEntryCard';

const perPage = 9;
const { POST } = BlogTypeEnum;

const buildFilters = (
  queryTitle: string | null,
): BlogEntriesFilters => ({
  title: queryTitle ?? undefined,
});

const BlogPage = () => {
  const { t } = useTranslation();

  const { page, setQueryPage } = usePagination();
  const { searchParams, onFilter } = useFilterSearchParams();
  const queryTitle = searchParams.get('title');
  const [filters, setFilters] = useState<BlogEntriesFilters>(buildFilters(queryTitle));

  const buildLink = (blogEntryId: string) => generatePath(
    APP_PATHS.public.blog.see,
    { blogEntryId },
  );

  const {
    data: blogEntries,
    isLoading,
    isFetching,
  } = useGetBlogEntriesByTypeQuery({
    typeId: POST.toString(), perPage, page, filters,
  });

  useEffect(() => {
    setFilters(buildFilters(queryTitle));
  }, [queryTitle]);

  return (
    <Row gutter={ [24, 32] }>
      <Col span={24}>
        <h1 className='text-primary text-center mb-0'>{ t('public:blog.title') }</h1>
      </Col>
      <Col span={24}>
        <SearchInput
          loading={ isLoading || isFetching }
          defaultValue={ queryTitle ?? undefined }
          onSearch={(value) => onFilter(queryTitle, 'title', value)}
        />
      </Col>
      {
        blogEntries?.total === 0 && <Col span={24}><Empty description={ t('blogs.empty') } /></Col>
      }
      {
        blogEntries?.data.map((blogEntry) => (
          <Col xs={24} sm={12} xl={8} className='d-flex' key={blogEntry.id}>
            <BlogEntryCard
              key={blogEntry.id}
              blogEntry={blogEntry}
              to={buildLink(blogEntry.id)}
              textTruncateLines={3}
            />
          </Col>
        ))
      }
      <Col span={ 24 } className='text-center'>
        <Pagination
          size='small'
          showLessItems
          pageSize={ perPage }
          onChange={ setQueryPage }
          current={ page }
          total={ blogEntries?.total }
          { ...defaultPagination }
        />
      </Col>
    </Row>
  );
};

export default BlogPage;
