import { Button }      from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import React           from 'react';
import { Edit }        from 'react-feather';
import './index.less';

export const EditCircleButton: React.FC<ButtonProps> = (props) => {
  const { className } = props;
  return (
    <Button
      type='link'
      shape='circle'
      { ...props }
      className={ `edit-circle-btn ${className ?? ''}` }
    >
      <Edit />
    </Button>
  );
};
