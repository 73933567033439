import { Layout }                  from 'antd';
import React                       from 'react';
import { Outlet }                  from 'react-router-dom';
import { ADMIN_BREADCRUMBS_ITEMS } from '../../../components/layouts/Breadcrumbs/breadcrumbs.constants';
import MenuBar                     from '../../../components/MenuBar';
import { MENU_ITEMS }              from '../../constants/menu.constants';
import Breadcrumb                  from '../../../components/layouts/Breadcrumbs';

const { Content } = Layout;

const AdminLayout: React.FC = () => (
  <>
    <MenuBar items={ MENU_ITEMS.ADMIN } />
    <div className='container mx-auto pt-10 w-100'>
      <Content>
        <Breadcrumb items={ ADMIN_BREADCRUMBS_ITEMS } />
        <Outlet />
      </Content>
    </div>
  </>
);

export default AdminLayout;
