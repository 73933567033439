import { Button }         from 'antd';
import React              from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

export const NeedHelpComponent = () => {
  const { t } = useTranslation();

  return (
    <div className='help-info'>
      <h1>{ t('institution.projects.help.title') }</h1>
      <Button type='primary'>
        { t('institution.projects.help.action') }
      </Button>
    </div>
  );
};
