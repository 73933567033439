import { useSelector } from 'react-redux';
import { API_URL }     from '../constants/api.constants';
import { RootState }   from '../store';

type DownloadFileType = () => (uri: string, fileName: string) => void;

const useDownloadFile: DownloadFileType = () => {
  const token = useSelector((state: RootState) => state.auth.token);

  function downloadFile(uri: string, fileName: string): void {
    fetch(
      `${API_URL}${uri}`,
      { headers: new Headers({ Authorization: `Bearer ${token}` }) },
    )
      .then((res) => {
        if (res.status !== 401) {
          res.blob().then((data) => {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(data);
            a.download = fileName;
            a.click();
            a.parentNode?.removeChild(a);
          });
        }
      });
  }

  return downloadFile;
};

export default useDownloadFile;
