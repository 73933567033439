import {
  Col, Modal, notification, Row,
} from 'antd';
import Search                         from 'antd/es/input/Search';
import React, { useEffect, useState } from 'react';
import { DollarSign }                 from 'react-feather';
import { useTranslation }             from 'react-i18next';
import {
  DonationFilters,
  useGetDonationsAdminQuery,
  useRefundDonationMutation,
}                                     from '../../../../adapters/services/donations.service';
import EntityTable                 from '../../../../components/EntityTable';
import { ActionTuple }             from '../../../../components/EntityTable/types';
import { useAppDispatch }          from '../../../hooks/store';
import useCrudNotificationResponse from '../../../hooks/useCrudNotificationResponse';
import usePagination               from '../../../hooks/usePagination';
import { ApiDonation }             from '../../../interfaces/api/ApiDonation';
import { defaultConfirmProps }     from '../../../shared/defaults/defaultModalProps';
import { setLoader }               from '../../../store/features/uiSlice';
import columns                     from './columns';

const perPage = 10;

const ListDonationsPage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [filters, setFilters] = useState<DonationFilters>({});

  const { page, setQueryPage } = usePagination();
  const { data, isLoading, isFetching } = useGetDonationsAdminQuery({ filters, page, perPage });

  const [refundDonation, {
    isLoading: isRefunding,
    isSuccess: refunded,
    error: refundError,
  }] = useRefundDonationMutation();

  useCrudNotificationResponse({
    isSuccess: refunded,
    error: refundError,
    successNotification: {
      message: t('admin:donations.actions.refund.success.title'),
      description: t('admin:donations.actions.refund.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isRefunding));
  }, [dispatch, isRefunding]);

  const onRefund = (donation: ApiDonation) => {
    if (donation.refundId !== null) {
      notification.warn({
        message: t('admin:donations.actions.refund.not_allowed'),
      });
      return;
    }

    Modal.confirm({
      ...defaultConfirmProps,
      title: t('admin:donations.actions.refund.confirm'),
      width: '40%',
      onOk: () => refundDonation(donation.id),
    });
  };

  const actions: ActionTuple<ApiDonation>[] = [
    ['custom', {
      callback: onRefund,
      props: {
        icon: <DollarSign />,
        title: t('admin:donations.actions.refund.action'),
      },
    }],
  ];

  const onSearch = (value: string) => {
    if (value === '') {
      setFilters({});
    } else {
      setFilters({ search: value });
    }
  };

  return (
    <Row gutter={ [16, 32] }>
      <Col span={ 24 }>
        <h1>{ t('admin:donations.title') }</h1>
      </Col>
      <Col span={ 24 }>
        <Search
          allowClear
          placeholder={ t('admin:donations.search') }
          value={ filters.search ?? '' }
          onChange={ (e) => onSearch(e.target.value) }
        />
      </Col>
      <Col span={24}>
        <EntityTable
          loading={ isLoading || isFetching }
          data={ data }
          columns={ columns }
          actions={ actions }
          rowKey='id'
          paginated
          paginationData={ {
            onChange: setQueryPage,
            current: page,
            total: data?.total,
          } }
        />
      </Col>
    </Row>
  );
};

export default ListDonationsPage;
