import React               from 'react';
import {
  Col, Form, Input, Row,
}                                  from 'antd';
import { File }                    from 'react-feather';
import { useTranslation }          from 'react-i18next';
import { ApiOrganizationPetition } from '../../../../core/interfaces/api/ApiOrganizationPetition';
import { ApiPetitionDocument }     from '../../../../core/interfaces/api/ApiPetitionDocument';
import { OutlinedButton }          from '../../../buttons';
import './style.less';

const { Item } = Form;
const { TextArea } = Input;

type Props = {
  entity: ApiOrganizationPetition;
  onDownload: (document: ApiPetitionDocument) => void;
}

const OrganizationPetitionForm: React.FC<Props> = ({
  entity,
  onDownload,
}) => {
  const { t } = useTranslation(['common', 'admin']);

  return (
    <Form
      id='petition-visualization'
      layout='vertical'
      preserve={ false }
    >
      <Row gutter={ [16, 0] }>
        <Col span={ 24 }>
          <Item
            label={ t('admin:petitions.form.firstName') }
          >
            <Input
              value={ entity.organization.user.firstName }
            />
          </Item>
        </Col>
        <Col span={ 24 }>
          <Item
            label={ t('admin:petitions.form.lastName') }
          >
            <Input
              value={ entity.organization.user.lastName }
            />
          </Item>
        </Col>
        <Col span={ 24 }>
          <Item
            label={ t('admin:petitions.form.organizationName') }
          >
            <Input
              value={ entity.organization.name }
            />
          </Item>
        </Col>
        <Col span={ 24 }>
          <Item
            label={ t('admin:petitions.form.email') }
          >
            <Input
              value={ entity.organization.user.email }
            />
          </Item>
        </Col>
        <Col span={ 24 }>
          <Item
            label={ t('admin:petitions.form.message') }
          >
            <TextArea
              value={ entity.message }
            />
          </Item>
        </Col>
        <Col span={ 24 }>
          <Item
            label={ t('admin:petitions.form.documents') }
          >
            {
                entity.documents.map((document) => (
                  <OutlinedButton
                    key={ document.id }
                    className='mb-1'
                    onClick={ () => onDownload(document) }
                  >
                    <File />
                    { document.name }
                  </OutlinedButton>
                ))
              }
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OrganizationPetitionForm;
