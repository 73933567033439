import React                  from 'react';
import { useTranslation }     from 'react-i18next';
import { Link }               from 'react-router-dom';
import useBreadcrumbs         from 'use-react-router-breadcrumbs';
import { BreadcrumbItemType } from './breadcrumbs.constants';

type Props = {
  items: BreadcrumbItemType[];
}

const Breadcrumbs: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation();
  const breadcrumbs = useBreadcrumbs(
    items.map((route) => ({
      path: route.path,
      breadcrumb: typeof route.breadcrumb === 'string'
        ? t(route.breadcrumb) : route.breadcrumb,
    })),
  );

  return (
    <div className='mb-5'>
      {
        breadcrumbs.map(({ match, breadcrumb }) => (
          <span key={match.pathname}>
            { ' / ' }
            <Link to={match.pathname}>{ breadcrumb }</Link>
          </span>
        ))
      }
    </div>
  );
};

export default Breadcrumbs;
