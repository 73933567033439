// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line
const UUID_REGEX = '([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})';

export const APP_HASHES = {
  howItWorks: '#how-it-works',
};

const APP_PATHS = {
  home: '/home',

  admin: {
    root: '/admin',
    paymentGateways: '/admin/payment-gateways',
    blogEntries: '/admin/blog-entries',
    /* schoolsReports: '/admin/schools-reports', */
    users: '/admin/users',
    organizations: '/admin/users/organizations',
    usersType: '/admin/users/:typeId',
    petitions: '/admin/petitions',
    projectCategoryTypes: '/admin/projects-category-types',
    grades: {
      root: '/grades',
      create: '/grades/create',
      edit: '/grades/:gradeId',
    },
    donations: '/admin/donations',
  },

  organization: {
    root: '/organization',
    join: '/organization/join',
    edit: '/organization/edit',
    institutions: '/organization/institutions',
    createInstitution: '/organization/institutions/create',
    editInstitution: '/organization/institutions/:institutionId/edit',
    institution: '/organization/institutions/:institutionId',
    projectList: '/organization/institutions/:institutionId/projects',
    newStudentProject: '/organization/institutions/:institutionId/projects/student',
    newInstitutionalProject: '/organization/institutions/:institutionId/projects/institutional',
    projectRoot: '/organization/institutions/:institutionId/projects/:projectId',
    projects: {
      detail: '/organization/institutions/:institutionId/projects/:projectId/detail',
      edit: 'edit',
      updates: 'updates',
      updatesEdit: 'updates/:updateId',
      scores: 'scores',
    },
  },

  sponsor: {
    home: '/sponsor',
    projectList: '/sponsor/projects',
    sponsorProjectDetail: '/sponsor/projects/:projectId',
  },

  public: {
    discover: '/discover',
    blog: {
      root: '/blog',
      see: '/blog/entry/:blogEntryId',
    },
    projectDetail: '/projects/:projectId',
    donationForm: '/projects/:projectId/donate',
    donationThanks: '/donations/:donationId',
  },

  shared: {
    profile: '/profile',
  },

  errors: {
    main: '/error',
    not_allowed: '/not-allowed',
    not_found: '/not-found',
  },
};

export default APP_PATHS;
