/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UiState, { CurrencyType }      from '../../interfaces/store/states/UiState';

const initialState: UiState = {
  appLoader: false,
  currency: {
    code: 'USD',
    rate: 1,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.appLoader = action.payload;
    },
    setCurrency: (state, action: PayloadAction<CurrencyType>) => {
      state.currency = action.payload;
    },
  },
});

export const { setLoader, setCurrency } = uiSlice.actions;

const uiReducer = uiSlice.reducer;
export default uiReducer;
