export const EXAMPLES_TAG = 'EXAMPLES';
export const BLOG_ENTRIES_TAG = 'BLOG_ENTRIES';
export const BLOG_TYPES_TAG = 'BLOG_TYPES';
export const BLOG_TAGS_TAG = 'BLOG_TAG';
export const ORGANIZATION_PETITIONS_TAG = 'ORGANIZATION_PETITIONS';
export const INSTITUTIONS_TAG = 'INSTITUTIONS';
export const COUNTRIES_TAG = 'COUNTRIES';
export const PROJECTS_TAG = 'PROJECTS';
export const PROJECT_UPDATES_LIST_TAG = 'PROJECT_UPDATES_LIST';
export const PROJECT_UPDATE_TAG = 'PROJECT_UPDATE';
export const PROJECT_CATEGORY_TYPES_TAG = 'PROJECT_CATEGORY_TYPES';
export const GRADES_TAG = 'GRADES';
export const STUDENTS_TAG = 'STUDENTS';
export const DONATIONS_TAG = 'DONATIONS';
export const CHARGED_AMOUNT_TAG = 'CHARGED_AMOUNT';
export const DONATIONS_ADMIN_TAG = 'DONATIONS_ADMIN';
export const LATEST_REPORT_TAG = 'LATEST_REPORT';
export const REPORT_CARDS_TAG = 'REPORT_CARDS';

export const BLOG_ENTRIES_PUBLIC_TAG = 'BLOG_ENTRIES_PUBLIC';
export const BLOG_ENTRY_RELATED_PUBLIC_TAG = 'BLOG_ENTRY_RELATED_PUBLIC';
export const PLATFORM_COUNT_PUBLIC_TAG = 'PLATFORM_COUNT_PUBLIC';

export const ORGANIZATION_TAG = 'ORGANIZATION';
export const USERS_TAG = 'USERS';
export const PROFILE_TAG = 'PROFILE';
export const SPONSOR_PROJECTS_TAG = 'SPONSOR_PROJECTS';

const API_TAGS = [
  EXAMPLES_TAG, BLOG_ENTRIES_TAG, BLOG_TYPES_TAG, BLOG_TAGS_TAG, BLOG_ENTRIES_PUBLIC_TAG,
  ORGANIZATION_PETITIONS_TAG, COUNTRIES_TAG, USERS_TAG, ORGANIZATION_TAG,
  INSTITUTIONS_TAG, PROJECTS_TAG, PROJECT_UPDATES_LIST_TAG, PROJECT_UPDATE_TAG, PROFILE_TAG,
  PROJECT_CATEGORY_TYPES_TAG, GRADES_TAG, STUDENTS_TAG, DONATIONS_TAG, DONATIONS_ADMIN_TAG,
  LATEST_REPORT_TAG, REPORT_CARDS_TAG, SPONSOR_PROJECTS_TAG, CHARGED_AMOUNT_TAG,
];

export const LIST_ID_TAG = 'LIST';

export default API_TAGS;
