import { UserRoleType } from '../hooks/useGetRoles';
import APP_PATHS        from './routes.constants';

const HOME_ROUTES: Record<UserRoleType, string> = {
  ADMIN: APP_PATHS.admin.blogEntries,
  ORGANIZATION: APP_PATHS.organization.institutions,
  SPONSOR: APP_PATHS.sponsor.home,
};

export default HOME_ROUTES;
