import { FormInstance }             from 'antd';
import { TFunction }                from 'i18next';
import { useEffect }                from 'react';
import {
  useCreateProjectUpdateMutation,
  useUpdateProjectUpdateMutation,
} from '../../../../adapters/services/projectsUpdates.services';
import { useAppDispatch }          from '../../../hooks/store';
import useCrudNotificationResponse from '../../../hooks/useCrudNotificationResponse';
import { setLoader }               from '../../../store/features/uiSlice';
import { ProjectUpdatesFields }    from './helpers';

type Props = {
  t: TFunction;
  form: FormInstance<ProjectUpdatesFields>;
  onClose: () => void;
}

type ReturnType = {
  createProjectUpdate: (arg: FormData) => void;
  updateProjectUpdate: (arg: { id: string; body: FormData }) => void;
}

type ProjectUpdatesEffectsType = (props: Props) => ReturnType;

const useProjectUpdatesEffects: ProjectUpdatesEffectsType = ({ form, onClose, t }) => {
  const dispatch = useAppDispatch();

  const [createProjectUpdate, {
    isLoading: isCreating,
    isSuccess: created,
    error: createError,
  }] = useCreateProjectUpdateMutation();

  const [updateProjectUpdate, {
    isLoading: isUpdating,
    isSuccess: updated,
    error: updateError,
  }] = useUpdateProjectUpdateMutation();

  useCrudNotificationResponse({
    isSuccess: created, error: createError, form, onClose,
  });

  useCrudNotificationResponse({
    isSuccess: updated,
    error: updateError,
    form,
    onClose,
    successNotification: {
      message: t('organization:institution.projects.updates.update.success.title'),
      description: t('organization:institution.projects.updates.update.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isCreating || isUpdating));
  }, [dispatch, isCreating, isUpdating]);

  return {
    createProjectUpdate, updateProjectUpdate,
  };
};

export default useProjectUpdatesEffects;
