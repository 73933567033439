import { generatePath }                  from 'react-router-dom';
import { INSTITUTIONS_TAG, LIST_ID_TAG } from '../../core/constants/api.tags.constants';
import { INSTITUTIONS_URIS }             from '../../core/constants/api.uris.constants';
import { ApiInstitution }                from '../../core/interfaces/api/ApiInstitution';
import fundEducationApi                  from '../apis/fundEducation.api';

export const institutionApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getInstitutions:
      build.query<ApiInstitution[], void>({
        query: () => INSTITUTIONS_URIS.root,
        providesTags: () => [{ type: INSTITUTIONS_TAG, id: LIST_ID_TAG }],
      }),
    getInstitution:
      build.query<ApiInstitution, string | undefined>({
        query: (id) => generatePath(INSTITUTIONS_URIS.find, { id }),
        providesTags: (_, __, id) => [{ type: INSTITUTIONS_TAG, id }],
      }),
    createInstitution: build.mutation<null, FormData>({
      query: (body) => ({
        url: INSTITUTIONS_URIS.root,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: INSTITUTIONS_TAG, id: LIST_ID_TAG }],
    }),
    updateInstitution: build.mutation<null, { id: string; body: FormData }>({
      query: ({ id, body }) => {
        body.append('_method', 'PUT');

        return {
          url: generatePath(INSTITUTIONS_URIS.action, { id }),
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: INSTITUTIONS_TAG, id },
        { type: INSTITUTIONS_TAG, id: LIST_ID_TAG },
      ],
    }),
    deleteInstitution: build.mutation<null, string>({
      query: (id) => ({
        url: generatePath(INSTITUTIONS_URIS.action, { id }),
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: INSTITUTIONS_TAG, id: LIST_ID_TAG }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetInstitutionsQuery,
  useGetInstitutionQuery,
  useCreateInstitutionMutation,
  useUpdateInstitutionMutation,
  useDeleteInstitutionMutation,
} = institutionApi;
