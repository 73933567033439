import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FormInstance }        from 'antd';

interface DataField {
  name: number;
  key: number;
  fieldKey: number;
}

export type LaravelErrorResponse = {
  message: string;
  errors: object;
}

const setFormErrors = (form: FormInstance, payload: FetchBaseQueryError) => {
  if (payload.data !== undefined) {
    const errorData = payload.data as LaravelErrorResponse;
    if (errorData.errors !== undefined) {
      const fields: DataField[] = [];

      Object.entries(errorData.errors).forEach(([key, value]) => {
        const errors = [value as string];
        const data = {
          name: key.split('.').map(
            (k) => (Number.isNaN(parseInt(k, 10)) ? k : parseInt(k, 10)),
          ),
          errors,
        };

        fields.push(data as unknown as DataField);
      });

      form.setFields(fields);
    }
  }
};

export default setFormErrors;
