import { Form, Select }       from 'antd';
import * as React             from 'react';
import { useTranslation }     from 'react-i18next';
import useDBTranslation       from '../../../hooks/useDBTranslation';
import ApiProjectCategoryType from '../../../interfaces/api/ApiProjectCategoryType';

const { Item } = Form;

type Props = {
  types: ApiProjectCategoryType[];
  selectType: (typeId: string) => void;
};

const CategorySelector: React.FC<Props> = ({ types, selectType }) => {
  const { t } = useTranslation('organization');
  const tDB = useDBTranslation();

  return (
    <Form layout='vertical'>
      <Item label={ t('organization:projects.form.categories.types.label') }>
        <Select
          placeholder={ t('organization:projects.form.categories.types.placeholder') }
          getPopupContainer={ (trigger) => trigger.parentNode }
          options={ types.map((type) => ({ value: type.id, label: tDB(type, 'name') }))}
          onChange={ selectType }
        />
      </Item>
    </Form>
  );
};

export default CategorySelector;
