import { FetchBaseQueryError }                    from '@reduxjs/toolkit/query';
import { BaseQueryFn, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL }                                from '../constants/api.constants';
import { keysToCamel, keysToSnake }               from '../shared/utils/caseAdapters';
import InitialStoreType                           from '../store/InitialStoreType';

const RawBaseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Accept', 'application/json');

    const { token } = (getState() as InitialStoreType).auth;

    if (token !== null) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const ApiBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
  > = async (args, api, extraOptions = {}) => {
    const argsClone = args;
    if (typeof args !== 'string' && typeof argsClone !== 'string' && !(args.body instanceof FormData)) {
      argsClone.body = keysToSnake(args.body);
    }

    const result = await RawBaseQuery(argsClone, api, extraOptions);
    if (result.data) {
      result.data = keysToCamel(result.data);
    }
    return result;
  };

export default ApiBaseQuery;
