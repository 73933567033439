import { Content }                                             from 'antd/lib/layout/layout';
import React, { useEffect }                                    from 'react';
import {
  generatePath, Outlet, useLocation, useNavigate, useParams,
}                                         from 'react-router-dom';
import { useGetProjectQuery }             from '../../../../adapters/services/projects.service';
import Breadcrumbs                        from '../../../../components/layouts/Breadcrumbs';
import { ORGANIZATION_BREADCRUMBS_ITEMS } from '../../../../components/layouts/Breadcrumbs/breadcrumbs.constants';
import LoadingCardRow                     from '../../../../components/LoadingCardRow';
import MenuBar                            from '../../../../components/MenuBar';
import { MenuItemType }                   from '../../../constants/menu.constants';
import APP_PATHS                          from '../../../constants/routes.constants';
import { ProjectTypeEnum }                from '../../../interfaces/api/ApiProject';

const studentItems: MenuItemType[] = [
  {
    i18nTitle: 'organization:routes.projects.edit',
    route: APP_PATHS.organization.projects.edit,
  },
  {
    i18nTitle: 'organization:routes.projects.updates',
    route: APP_PATHS.organization.projects.updates,
  },
  {
    i18nTitle: 'organization:routes.projects.scores',
    route: APP_PATHS.organization.projects.scores,
  },
];

const institutionalItems: MenuItemType[] = [
  {
    i18nTitle: 'organization:routes.projects.edit',
    route: APP_PATHS.organization.projects.edit,
  },
  {
    i18nTitle: 'organization:routes.projects.updates',
    route: APP_PATHS.organization.projects.updates,
  },
];

const OrganizationProjectsLayout: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const {
    data, isLoading, isFetching, error,
  } = useGetProjectQuery(params.projectId);

  useEffect(() => {
    // Redirecting to detail page if the project can no longer be edited
    if (data?.finished || data?.statusId !== 1) {
      navigate(generatePath(APP_PATHS.organization.projectRoot, params));
    }
  }, [data, error, params, navigate]);

  useEffect(() => {
    // Redirecting to edit page if trying to access scores from institutional project
    const currentPath = pathname.split('/').pop();
    if (data?.typeId === ProjectTypeEnum.INSTITUTIONAL
      && currentPath === APP_PATHS.organization.projects.scores
    ) {
      navigate(generatePath(APP_PATHS.organization.projects.edit, params));
    }

    // Redirecting to the main project page if trying to access 'detail' route,
    // which only holds the layout
    if (currentPath === APP_PATHS.organization.projects.detail.split('/').pop()) {
      navigate(generatePath(APP_PATHS.organization.projectRoot, params));
    }
  }, [data, pathname, navigate]);

  return (
    <>
      <MenuBar
        items={ data?.typeId === ProjectTypeEnum.STUDENT ? studentItems : institutionalItems}
        selectedKey={
            params.updateId !== undefined
              ? 1
              : 0
          }
      />
      <div className='container mx-auto pt-10 w-100'>
        {
          isLoading && isFetching
          && <LoadingCardRow count={ 1 } />
        }
        {
          !(isLoading && isFetching)
          && (
            <Content>
              <Breadcrumbs items={ ORGANIZATION_BREADCRUMBS_ITEMS } />
              <Outlet />
            </Content>
          )
        }
      </div>
    </>
  );
};

export default OrganizationProjectsLayout;
