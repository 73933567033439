import { PlusOutlined }      from '@ant-design/icons';
import {
  Button, Col, Modal, Row,
}                                                        from 'antd';
import { useForm }                                 from 'antd/es/form/Form';
import React, { useEffect }                        from 'react';
import { useTranslation }                          from 'react-i18next';
import {
  useCreateExampleMutation,
  useDeleteExampleMutation,
  useGetExamplesQuery, useUpdateExampleMutation,
}                                                       from '../../../../adapters/services/example.service';
import EntityTable                                      from '../../../../components/EntityTable';
import { ActionTuple }                                  from '../../../../components/EntityTable/types';
import ExampleForm, { ExampleFields, ExamplesCallback } from '../../../../components/forms/admin/ExampleForm/form';
import { useAppDispatch }                               from '../../../hooks/store';
import useCrudNotificationResponse                      from '../../../hooks/useCrudNotificationResponse';
import ApiExample                                       from '../../../interfaces/api/ApiExample';
import { defaultConfirmProps, defaultInfoProps }        from '../../../shared/defaults/defaultModalProps';
import { setLoader }                                    from '../../../store/features/uiSlice';
import columns                                          from './columns';

const ListExamplesPage = () => {
  const { t } = useTranslation(['admin', 'common']);
  const dispatch = useAppDispatch();
  const [form] = useForm<ExampleFields>();

  const { data, isLoading, isFetching } = useGetExamplesQuery();

  const [createExample, {
    isLoading: isCreating,
    isSuccess: created,
    error: createError,
  }] = useCreateExampleMutation();

  const [updateExample, {
    isLoading: isUpdating,
    isSuccess: updated,
    error: updateError,
  }] = useUpdateExampleMutation();

  const [deleteExample, {
    isLoading: isDeleting,
    isSuccess: deleted,
    error: deleteError,
  }] = useDeleteExampleMutation();

  useCrudNotificationResponse({ isSuccess: created, error: createError, form });
  useCrudNotificationResponse({ isSuccess: updated, error: updateError, form });
  useCrudNotificationResponse({ isSuccess: deleted, error: deleteError });

  useEffect(() => {
    dispatch(setLoader(isCreating || isUpdating || isDeleting));
  }, [dispatch, isCreating, isUpdating, isDeleting]);

  const handleFinish: ExamplesCallback = (fields, example) => {
    if (example === undefined) {
      createExample(fields);
    } else {
      updateExample({
        id: example.id,
        ...fields,
      });
    }
  };

  const onSave = () => {
    Modal.info({
      ...defaultInfoProps,
      title: t('admin:new'),
      width: '65%',
      content: <ExampleForm
        form={ form }
        onFinish={ handleFinish }
      />,
    });
  };

  const onEdit = (example: ApiExample) => {
    Modal.info({
      ...defaultInfoProps,
      title: t('admin:update'),
      content: <ExampleForm
        entity={ example }
        form={ form }
        onFinish={ handleFinish }
      />,
    });
  };

  const onDelete = (example: ApiExample) => Modal.confirm({
    ...defaultConfirmProps,
    title: t('admin:delete'),
    onOk: () => deleteExample(example),
  });

  const actions: ActionTuple<ApiExample>[] = [
    ['edit', onEdit],
    ['delete', onDelete],
  ];

  return (
    <Row gutter={ [16, 32] }>
      <Col span={ 24 }>
        <h2>
          { t('admin:example.name') }
        </h2>
      </Col>
      <Col span={ 24 }>
        <Button
          icon={ <PlusOutlined /> }
          size='large'
          type='primary'
          onClick={ () => onSave() }
        >
          { t('admin:new') }
        </Button>
      </Col>
      <Col span={ 24 }>
        <EntityTable
          loading={ isLoading || isFetching }
          columns={ columns }
          actions={ actions }
          data={ data }
          rowKey='id'
        />
      </Col>
    </Row>
  );
};

export default ListExamplesPage;
