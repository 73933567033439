import moment             from 'moment';
import React              from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  date: string;
  long?: boolean;
}

export const LocalizedDateString: React.FC<Props> = ({ date, long = false }) => {
  const { i18n } = useTranslation();
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: long ? 'long' : 'numeric', day: 'numeric' };
  return <span>{moment(date).toDate().toLocaleDateString(i18n.language, options)}</span>;
};
