import {
  Button, Col, Form, Row, Upload, UploadProps,
}                                   from 'antd';
import { useForm }                  from 'antd/lib/form/Form';
import TextArea                     from 'antd/lib/input/TextArea';
import { RcFile }                   from 'antd/lib/upload';
import React, { useMemo, useState } from 'react';
import { useTranslation }           from 'react-i18next';
import { ApiProject }               from '../../../../core/interfaces/api/ApiProject';
import './style.less';
import { OutlinedButton }           from '../../../buttons';

const { Item } = Form;

type ProjectDeletionFields = {
  description: string;
  document: RcFile;
}

type Props = {
  entity: ApiProject;
  onDelete: (payload: {id: string; body: FormData}) => void;
}

const DeleteProjectForm: React.FC<Props> = ({ entity, onDelete }) => {
  const { t } = useTranslation(['common', 'organization']);

  const [form] = useForm<ProjectDeletionFields>();
  const [document, setDocument] = useState<RcFile>();

  const uploadProps: UploadProps = useMemo(() => ({
    maxCount: 1,
    beforeUpload: () => false,
    onChange: (info) => {
      if (info.fileList[0] !== undefined) {
        setDocument(info.fileList[0].originFileObj);
      } else {
        setDocument(undefined);
      }
    },
  }), [setDocument]);

  const handleFinish = (fields: ProjectDeletionFields) => {
    if (document !== undefined) {
      const frmData = new FormData();
      frmData.append('description', fields.description);
      frmData.append('document', document);

      onDelete({ id: entity.id, body: frmData });
    }
  };

  return (
    <Form<ProjectDeletionFields>
      form={ form }
      layout='vertical'
      name='frmDeleteProject'
      className='delete-project-form'
      requiredMark={ false }
      preserve={ false }
      onFinish={ handleFinish }
    >
      <Row gutter={ [16, 0] }>
        <Col span={ 24 }>
          <Item
            label={ t('organization:projects.delete.description.label') }
            name='description'
            rules={ [
              {
                required: true,
                message: t('organization:projects.delete.description.required'),
              },
            ] }
          >
            <TextArea
              name='description'
              placeholder={ t('organization:projects.delete.description.placeholder') }
            />
          </Item>
        </Col>
        <Col span={ 24 }>
          <Item className='document-upload'>
            <Upload {...uploadProps}>
              <OutlinedButton>
                { t('organization:projects.delete.upload') }
              </OutlinedButton>
            </Upload>
          </Item>
        </Col>
      </Row>
      <Item>
        <Button
          block
          type='primary'
          size='large'
          htmlType='submit'
          disabled={ document === undefined }
        >
          { t('common:delete') }
        </Button>
      </Item>
    </Form>
  );
};

export default DeleteProjectForm;
