import * as React      from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { Trans }       from 'react-i18next';
import { ApiGrade }    from '../../../interfaces/api/ApiGrade';

const columns: ColumnsType<ApiGrade> = [
  {
    title: () => <Trans i18nKey='admin:grades.form.name.label' />,
    dataIndex: 'name',
  },
  {
    title: () => <Trans i18nKey='admin:grades.form.abbreviation.label' />,
    dataIndex: 'abbreviation',
  },
  {
    title: () => <Trans i18nKey='admin:grades.form.age.label' />,
    dataIndex: 'age',
  },
];

export default columns;
