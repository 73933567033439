import { generatePath }              from 'react-router-dom';
import { LIST_ID_TAG, STUDENTS_TAG } from '../../core/constants/api.tags.constants';
import { STUDENTS_URI }              from '../../core/constants/api.uris.constants';
import { ApiStudent }                from '../../core/interfaces/api/ApiStudent';
import fundEducationApi              from '../apis/fundEducation.api';

export const studentsApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getAvailableStudents: build.query<ApiStudent[], string | undefined>({
      query: (id) => generatePath(STUDENTS_URI.available, { id }),
      providesTags: [{ type: STUDENTS_TAG, id: LIST_ID_TAG }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAvailableStudentsQuery,
} = studentsApi;
