/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction }    from '@reduxjs/toolkit';
import OrgState, { PetitionStatusState } from '../../interfaces/store/states/OrgState';

const defaultStatus: PetitionStatusState = {
  notCreated: false,
  incomplete: false,
  pending: false,
  approved: false,
  rejected: false,
};

const initialState: OrgState = {
  petitionStatus: defaultStatus,
};

export const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    setPetitionStatus: (state, action: PayloadAction<PetitionStatusState>) => {
      state.petitionStatus = action.payload;
    },
  },
});

export const { setPetitionStatus } = orgSlice.actions;

const orgReducer = orgSlice.reducer;
export default orgReducer;
