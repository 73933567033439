import {
  Button, Card, Col, Form, Input, Row,
} from 'antd';
import { useForm }                              from 'antd/lib/form/Form';
import React, { useEffect }                     from 'react';
import { useTranslation }                       from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useGetProjectUpdateQuery }             from '../../../../adapters/services/projectsUpdates.services';
import ImageField                               from '../../../../components/fields/ImageField';
import APP_PATHS                                from '../../../constants/routes.constants';
import defaultCardColProps                      from '../../../shared/defaults/defaultCardColProps';
import useProjectUpdatesEffects                 from './useProjectUpdatesEffects';
import {
  buildProjectUpdateFormData,
  ProjectUpdatesCallback,
  ProjectUpdatesFields,
} from './helpers';

import './index.less';

const { Item } = Form;
const { TextArea } = Input;

const initialValues: ProjectUpdatesFields = {
  title: '',
  description: '',
  imageUrl: undefined,
  projectId: '',
};

const ManageUpdatePage = () => {
  const { t } = useTranslation();
  const { updateId, institutionId, projectId } = useParams();
  const navigate = useNavigate();

  const onClose = () => {
    navigate(generatePath(
      APP_PATHS.organization.projectRoot,
      { institutionId, projectId },
    ));
  };

  const [form] = useForm<ProjectUpdatesFields>();

  const { data: projectUpdate } = useGetProjectUpdateQuery(
    updateId,
    { skip: updateId === undefined },
  );

  const { createProjectUpdate, updateProjectUpdate } = useProjectUpdatesEffects(
    { t, form, onClose },
  );

  const handleFinish: ProjectUpdatesCallback = (fields) => {
    const formData = buildProjectUpdateFormData(fields);
    if (projectUpdate !== undefined && updateId !== undefined) {
      updateProjectUpdate({
        id: projectUpdate.id,
        body: formData,
      });
    } else {
      if (projectId !== undefined) {
        formData.append('project_id', projectId);
      }
      createProjectUpdate(formData);
    }
  };

  useEffect(() => {
    if (projectUpdate !== undefined) {
      form.setFieldsValue({
        title: projectUpdate.title,
        description: projectUpdate.description,
        imageUrl: projectUpdate.imageUrl,
        projectId: projectUpdate.id,
      });
    }
  }, [projectUpdate, form]);

  useEffect(() => {
    if (updateId === undefined) {
      form.resetFields();
    }
  }, [updateId]);

  return (
    <Row justify='center'>
      <Col { ...defaultCardColProps }>
        <Card id='create-update-form'>
          <div className='text-h1'>
            {
              updateId === undefined
                ? t('organization:institution.projects.updates.create.title')
                : t('organization:institution.projects.updates.update.title')
            }
          </div>

          <Form<ProjectUpdatesFields>
            form={ form }
            layout='vertical'
            name='frmProjectUpdates'
            requiredMark={ false }
            initialValues={ initialValues }
            onFinish={ (v) => handleFinish(v) }
          >
            <Row gutter={ [16, 8] }>
              <Col span={24}>
                <Item
                  label={ t('organization:institution.projects.updates.form.name.label') }
                  name='title'
                  className='text-body-bold'
                  rules={[
                    {
                      required: true,
                      message: t('organization:institution.projects.updates.form.name.required'),
                    },
                  ]}
                >
                  <Input />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={ t('organization:institution.projects.updates.form.description.label') }
                  name='description'
                  className='text-body-bold'
                  rules={[
                    {
                      required: true,
                      message: t('organization:institution.projects.updates.form.description.required'),
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={ t('organization:institution.projects.updates.form.image.label') }
                  name='imageUrl'
                  className='text-body-bold'
                  rules={[
                    {
                      required: true,
                      message: t('organization:institution.projects.updates.form.image.required'),
                    },
                  ]}
                >
                  <ImageField />
                </Item>
              </Col>
              <Col span={24}>
                <Button type='primary' block htmlType='submit'>
                  {
                    updateId === undefined
                      ? t('organization:institution.projects.updates.create.save')
                      : t('organization:institution.projects.updates.update.modify')
                  }
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ManageUpdatePage;
