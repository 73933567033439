/* eslint-disable import/no-cycle */
import { ApiOrganization }     from './ApiOrganization';
import { ApiPetitionDocument } from './ApiPetitionDocument';

export enum PetitionStatusEnum {
  INCOMPLETE = 1,
  PENDING,
  APPROVED,
  REJECTED
}

export interface ApiOrganizationPetition {
  id: string;
  message: string;
  organizationId: string;
  organization: ApiOrganization;
  petitionStatusId: PetitionStatusEnum;
  documents: ApiPetitionDocument[];
}
