import { Moment }                         from 'moment';
import { ImageFieldValuesType }           from '../../../../components/fields/ImageField';
import { sanitizeBody, WYSIWYGValueType } from '../../../../components/fields/WYGIWYSField';

export type ProjectCategoryFields = {
  id?: string;
  goal: number;
  raised: number; // Used for validation only
  description: WYSIWYGValueType;
  imageUrl: ImageFieldValuesType;
  typeId: string;
}

export type StudentFields = {
  id?: string;
  firstName: string;
  lastName: string;
  birthdate: Moment;
  gradeId: string;
}

export type ProjectFields = {
  title: string;
  summary: string;
  description: WYSIWYGValueType;
  imageUrl: ImageFieldValuesType;
  expirationDate: Moment;
  student?: StudentFields;
  categories: ProjectCategoryFields[];
}

export const buildProjectFormData = (values: ProjectFields) => {
  const frmData = new FormData();

  const format = 'YYYY-MM-DD';

  const {
    categories, summary, description, expirationDate, imageUrl, student, title,
  } = values;

  if (imageUrl !== undefined && typeof imageUrl !== 'string') {
    frmData.append('image', imageUrl);
  }
  frmData.append('title', title);
  frmData.append('summary', summary);
  frmData.append('description', sanitizeBody(description?.html as string));
  frmData.append('expiration_date', expirationDate.format(format));

  if (student !== undefined) {
    const {
      birthdate, firstName, gradeId, lastName,
    } = student;
    frmData.append('student[first_name]', firstName);
    frmData.append('student[last_name]', lastName);
    frmData.append('student[birthdate]', birthdate.format(format));
    frmData.append('student[grade_id]', gradeId);
  }

  categories.forEach((category, i) => {
    const {
      description: categoryDesc, goal, id, imageUrl: categoryImg, typeId,
    } = category;
    if (id !== undefined) {
      frmData.append(`categories[${i}][id]`, id);
    }
    if (categoryImg !== undefined && typeof categoryImg !== 'string') {
      frmData.append(`categories[${i}][image]`, categoryImg);
    }
    frmData.append(`categories[${i}][goal]`, goal.toString());
    frmData.append(`categories[${i}][description]`, sanitizeBody(categoryDesc?.html as string));
    frmData.append(`categories[${i}][type_id]`, typeId);
  });

  return frmData;
};
