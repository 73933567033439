import { Avatar }                     from 'antd';
import { AvatarProps }                from 'antd/lib/avatar/avatar';
import React, { useEffect, useState } from 'react';
import { useAppSelector }             from '../../../core/hooks/store';
import './index.less';

export const UserAvatar: React.FC<AvatarProps> = (props) => {
  const { user } = useAppSelector((state) => state.auth);
  const [initials, setInitials] = useState<string | null>(null);

  useEffect(() => {
    if (user != null) {
      const { firstName, lastName } = user;
      setInitials((`${firstName[0]}${lastName[0]}`).toUpperCase());
    }
  }, [user, setInitials]);

  return (
    <div>
      {
        user?.imageUrl != null
          ? <Avatar src={user.imageUrl} {...props} />
          : (
            <Avatar className='profile-avatar' {...props}>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 360.96 358.98'>
                <text x='50%' y='50%' dominantBaseline='middle' textAnchor='middle'>{ initials }</text>
              </svg>
            </Avatar>
          )
      }
    </div>
  );
};
