import { PROFILE_TAG }          from '../../core/constants/api.tags.constants';
import { PROFILE_URIS }         from '../../core/constants/api.uris.constants';
import { ApiUser }              from '../../core/interfaces/api/ApiUser';
import { ChangePasswordFields } from '../../core/pages/shared/UserProfilePage/components/ChangePasswordForm';
import fundEducationApi         from '../apis/fundEducation.api';

export const profileApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<ApiUser, void>({
      query: () => PROFILE_URIS.root,
      providesTags: [{ type: PROFILE_TAG }],
    }),
    updateProfile: build.mutation<null, FormData>({
      query: (body) => {
        body.append('_method', 'PUT');

        return {
          url: PROFILE_URIS.root,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: PROFILE_TAG }],
    }),
    updatePassword: build.mutation<null, ChangePasswordFields>({
      query: (body) => ({
        url: PROFILE_URIS.password,
        method: 'PUT',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUpdatePasswordMutation,
} = profileApi;
