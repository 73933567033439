import { Checkbox }    from 'antd';
import moment          from 'moment';
import * as React      from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { Trans }       from 'react-i18next';
import { DATE_FORMAT } from '../../../constants/utils.constants';
import { ApiDonation } from '../../../interfaces/api/ApiDonation';

const columns: ColumnsType<ApiDonation> = [
  {
    title: () => <Trans i18nKey='admin:donations.columns.holder' />,
    dataIndex: 'holder',
    render: (_, { transaction }) => transaction?.holder,
  },
  {
    title: () => <Trans i18nKey='admin:donations.columns.email' />,
    dataIndex: 'email',
    render: (_, { transaction }) => transaction?.email,
  },
  {
    title: () => <Trans i18nKey='admin:donations.columns.amount' />,
    dataIndex: 'amount',
    render: (_, { transaction, chargedAmount }) => `$${transaction?.amount ?? Number(chargedAmount).toFixed(2)}`,
  },
  {
    title: () => <Trans i18nKey='admin:donations.columns.created_at' />,
    dataIndex: 'createdAt',
    render: (_, { transaction }) => moment(transaction?.createdAt).format(DATE_FORMAT),
  },
  {
    title: () => <Trans i18nKey='admin:donations.columns.project' />,
    dataIndex: 'project',
    render: (_, { projectCategory }) => projectCategory.project?.title,
  },
  {
    title: () => <Trans i18nKey='admin:donations.columns.refunded' />,
    dataIndex: 'refunded',
    render: (_, { refundId }) => (<Checkbox checked={ refundId !== null } disabled />),
  },
];

export default columns;
