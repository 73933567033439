import { generatePath } from 'react-router-dom';
import {
  BLOG_ENTRIES_PUBLIC_TAG,
  BLOG_ENTRY_RELATED_PUBLIC_TAG,
  LIST_ID_TAG,
} from '../../core/constants/api.tags.constants';
import { HOME_URIS }                from '../../core/constants/api.uris.constants';
import ApiBlogEntry                 from '../../core/interfaces/api/ApiBlogEntry';
import ListData                     from '../../core/interfaces/general/ListData';
import PageableInputType            from '../../core/shared/utils/pageableInputType';
import serializeObjectToQueryParams from '../../core/shared/utils/serializeObjectToQueryParams';
import fundEducationApi             from '../apis/fundEducation.api';

export type BlogEntriesFilters = {
  title?: string;
  tag?: string;
}

type GetBlogEntriesInputType = {
  typeId: string;
  filters?: BlogEntriesFilters;
} & PageableInputType;

export const blogEntriesPublicService = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getBlogEntriesByType: build.query<ListData<ApiBlogEntry>, GetBlogEntriesInputType>({
      query: ({
        typeId,
        page = 1,
        perPage = 3,
        filters = {},
      }) => ({
        url: `${generatePath(HOME_URIS.blogEntries, { typeId })}?page=${page}&perPage=${perPage}${serializeObjectToQueryParams(filters)}`,
        method: 'GET',
      }),
      providesTags: (result) => (
        result
          ? [
            ...result.data.map(({ id }) => ({ type: BLOG_ENTRIES_PUBLIC_TAG, id } as const)),
            { type: BLOG_ENTRIES_PUBLIC_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: BLOG_ENTRIES_PUBLIC_TAG, id: LIST_ID_TAG }]
      ),
    }),
    getBlogEntry: build.query<ApiBlogEntry, string | undefined>({
      query: (id) => generatePath(HOME_URIS.blogEntry, { id }),
      providesTags: (_result, _error, id) => [{ type: BLOG_ENTRIES_PUBLIC_TAG, id }],
    }),
    getBlogEntriesRelated: build.query<ApiBlogEntry[], string | undefined>({
      query: (id) => generatePath(HOME_URIS.blogEntryRelated, { id }),
      providesTags: (_result, _error, id) => [{ type: BLOG_ENTRY_RELATED_PUBLIC_TAG, id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetBlogEntriesByTypeQuery,
  useGetBlogEntryQuery,
  useGetBlogEntriesRelatedQuery,
} = blogEntriesPublicService;
