/* eslint-disable import/prefer-default-export, global-require,
@typescript-eslint/no-var-requires */
export const PublicLogo = require('../../assets/app-logo.png');
export const FundLogo = require('../../assets/logo-fund.svg');
export const FlagSV = require('../../assets/sv-flag.png');
export const ImageIcon = require('../../assets/image.svg');

export const PosterVideo = require('../../assets/public/video/poster-video.jpg');
export const Video = require('../../assets/public/video/sample-video.mp4');

export const HowItWorksImages = {
  'image-1': require('../../assets/public/how-it-works/image-1.jpg'),
  'image-2': require('../../assets/public/how-it-works/image-2.jpg'),
};

export const InstitutionalProjectPng = require('../../assets/institutional-project.png');
export const StudentProjectPng = require('../../assets/student-project.png');
export const DonationHeartPng = require('../../assets/donation-heart.png');

export const VisaPng = require('../../assets/credit-cards/visa.png');
export const MasterCardPng = require('../../assets/credit-cards/mastercard.png');
export const AmericanExpressPng = require('../../assets/credit-cards/americanexpress.png');

export const ConfettiGif = require('../../assets/confetti.gif');
