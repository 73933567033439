import { generatePath }                            from 'react-router-dom';
import { LIST_ID_TAG, PROJECT_CATEGORY_TYPES_TAG } from '../../core/constants/api.tags.constants';
import { PROJECT_CATEGORY_TYPES_URI }              from '../../core/constants/api.uris.constants';
import ApiProjectCategoryType, {
  CreateProjectCategoryType,
} from '../../core/interfaces/api/ApiProjectCategoryType';
import ListData          from '../../core/interfaces/general/ListData';
import PageableInputType from '../../core/shared/utils/pageableInputType';
import fundEducationApi  from '../apis/fundEducation.api';

export const projectCategoryTypesApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationCategoryTypes: build.query<ApiProjectCategoryType[], void>({
      query: () => PROJECT_CATEGORY_TYPES_URI.organizationRoot,
      providesTags: () => [{ type: PROJECT_CATEGORY_TYPES_TAG, id: LIST_ID_TAG }],
    }),
    getProjectCategoryTypes: build.query<ListData<ApiProjectCategoryType>, PageableInputType>({
      query: ({ page = 1, perPage = 10 }) => ({
        url: `${generatePath(PROJECT_CATEGORY_TYPES_URI.root)}?page=${page}&perPage=${perPage}`,
        method: 'GET',
      }),
      providesTags: (result) => (
        result
          ? [
            ...result.data.map(({ id }) => ({ type: PROJECT_CATEGORY_TYPES_TAG, id } as const)),
            { type: PROJECT_CATEGORY_TYPES_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: PROJECT_CATEGORY_TYPES_TAG, id: LIST_ID_TAG }]
      ),
    }),
    createProjectCategoryType: build.mutation<null, CreateProjectCategoryType>({
      query: (body) => ({
        url: PROJECT_CATEGORY_TYPES_URI.root,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: PROJECT_CATEGORY_TYPES_TAG, id: LIST_ID_TAG }],
    }),
    updateProjectCategoryType: build.mutation<null, ApiProjectCategoryType>({
      query: ({ id, ...payload }) => ({
        url: generatePath(PROJECT_CATEGORY_TYPES_URI.action, { id }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: PROJECT_CATEGORY_TYPES_TAG, id }],
    }),
    deleteProjectCategoryType: build.mutation<null, ApiProjectCategoryType>({
      query: (payload) => ({
        url: generatePath(PROJECT_CATEGORY_TYPES_URI.action, { id: payload.id }),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: PROJECT_CATEGORY_TYPES_TAG, id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetOrganizationCategoryTypesQuery,
  useGetProjectCategoryTypesQuery,
  useCreateProjectCategoryTypeMutation,
  useUpdateProjectCategoryTypeMutation,
  useDeleteProjectCategoryTypeMutation,
} = projectCategoryTypesApi;
