import React              from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

export const PlatformInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='information container mx-auto'>
      <h1 className='text-center'>
        { t('common:institution.projects.info.title') }
      </h1>
      <p className='text-gray'>
        <span className='text-black text-body-bold'>
          { t('common:institution.projects.item1.title') }
        </span>
        { t('common:institution.projects.item1.description') }
      </p>
      <p className='text-gray'>
        <span className='text-black text-body-bold'>
          { t('common:institution.projects.item2.title') }
        </span>
        { t('common:institution.projects.item2.description') }
      </p>
      <p className='text-gray'>
        <span className='text-black text-body-bold'>
          { t('common:institution.projects.item3.title') }
        </span>
        { t('common:institution.projects.item3.description') }
      </p>
      <p className='text-gray'>
        <span className='text-black text-body-bold'>
          { t('common:institution.projects.item4.title') }
        </span>
        { t('common:institution.projects.item4.description') }
      </p>
      <p className='text-gray'>
        <span className='text-black text-body-bold'>
          { t('common:institution.projects.item5.title') }
        </span>
        { t('common:institution.projects.item5.description') }
      </p>
    </div>
  );
};
