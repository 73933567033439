import { RcFile }       from 'antd/lib/upload';
import { getFileAsSrc } from '../../../components/fields/utils';

const getImageSize = (file: RcFile) => new Promise<{ width: number; height: number }>((resolve) => {
  const img = new Image();
  getFileAsSrc(file).then((src) => {
    if (src !== null) img.src = src;
  });

  img.onload = () => {
    resolve(img);
  };
});

export default getImageSize;
