import { COUNTRIES_TAG, LIST_ID_TAG } from '../../core/constants/api.tags.constants';
import { COUNTRIES_URIS }             from '../../core/constants/api.uris.constants';
import { ApiCountry }                 from '../../core/interfaces/api/ApiCountry';
import fundEducationApi               from '../apis/fundEducation.api';

export const countriesApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getCountries:
      build.query<ApiCountry[], void>({
        query: () => COUNTRIES_URIS.root,
        providesTags: () => [{ type: COUNTRIES_TAG, id: LIST_ID_TAG }],
      }),
  }),
  overrideExisting: true,
});

export const { useGetCountriesQuery } = countriesApi;
