import { UserRoleType } from '../hooks/useGetRoles';
import APP_PATHS        from './routes.constants';

export type MenuItemType = {
  i18nTitle: string;
  route: string;
}

const ADMIN_MENU_ITEMS: MenuItemType[] = [
  /* {
    i18nTitle: 'admin:routes.payment_gateway',
    route: APP_PATHS.admin.paymentGateways,
  }, */
  {
    i18nTitle: 'admin:routes.blog_entries',
    route: APP_PATHS.admin.blogEntries,
  },
  /* {
    i18nTitle: 'admin:routes.school_reports',
    route: APP_PATHS.admin.schoolsReports,
  }, */
  {
    i18nTitle: 'admin:routes.users',
    route: APP_PATHS.admin.users,
  },
  {
    i18nTitle: 'admin:routes.pending_accounts',
    route: APP_PATHS.admin.petitions,
  },
  {
    i18nTitle: 'admin:routes.project_category_types',
    route: APP_PATHS.admin.projectCategoryTypes,
  },
  {
    i18nTitle: 'admin:routes.grades.root',
    route: APP_PATHS.admin.grades.root,
  },
  {
    i18nTitle: 'admin:routes.donations',
    route: APP_PATHS.admin.donations,
  },
];

const ORGANIZATION_MENU_ITEMS: MenuItemType[] = [
  {
    i18nTitle: 'organization:routes.institutions',
    route: APP_PATHS.organization.institutions,
  },
];

const SPONSOR_MENU_ITEMS: MenuItemType[] = [];

export const MENU_ITEMS: Record<UserRoleType, MenuItemType[]> = {
  ADMIN: ADMIN_MENU_ITEMS,
  ORGANIZATION: ORGANIZATION_MENU_ITEMS,
  SPONSOR: SPONSOR_MENU_ITEMS,
};
