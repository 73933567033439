import { Divider, Skeleton }        from 'antd';
import React                        from 'react';
import { useTranslation }           from 'react-i18next';
import { useGetPlatformCountQuery } from '../../../../../../adapters/services/home.service';

import './index.less';

export const PlatformCount: React.FC = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetPlatformCountQuery();

  const onChangeNumber = (loading: boolean, value: number) => (
    loading ? <Skeleton loading={loading} /> : value
  );

  if (data?.institutionsCount === 0 || data?.studentsCount === 0 || data?.donorsCount === 0) {
    return <div />;
  }

  return (
    <div className='platform-count container mx-auto d-flex flex-row justify-space-around text-center'>
      <div className='platform-count platform-count__item d-flex flex-column'>
        <div className='platform-count platform-count__item--title text-body-bold'>
          { t('public:information_count.partners') }
        </div>
        <div className='platform-count platform-count__item--number text-h1 text-primary'>
          { data !== undefined && (onChangeNumber(isLoading, data.institutionsCount)) }
        </div>
      </div>
      <Divider type='vertical' className='platform-count platform-count__divider h-auto' />
      <div className='platform-count platform-count__item d-flex flex-column'>
        <div className='platform-count platform-count__item--title text-body-bold'>
          { t('public:information_count.students') }
        </div>
        <div className='platform-count platform-count__item--number text-h1 text-primary'>
          { data !== undefined && (onChangeNumber(isLoading, data.studentsCount)) }
        </div>
      </div>
      <Divider type='vertical' className='platform-count platform-count__divider h-auto' />
      <div className='platform-count platform-count__item d-flex flex-column'>
        <div className='platform-count platform-count__item--title text-body-bold'>
          { t('public:information_count.donors') }
        </div>
        <div className='platform-count platform-count__item--number text-h1 text-primary'>
          { data !== undefined && (onChangeNumber(isLoading, data.donorsCount)) }
        </div>
      </div>
    </div>
  );
};
