import { useKeycloak }    from '@react-keycloak/web';
import {
  Card, Col, Form, Row,
}                      from 'antd';
import { useForm }                                   from 'antd/lib/form/Form';
import React, { useEffect, useState }                from 'react';
import { useTranslation }                            from 'react-i18next';
import { generatePath, useNavigate, useParams }      from 'react-router-dom';
import { useCreateDonationMutation }                 from '../../../../adapters/services/donations.service';
import { useGetProjectQuery }                        from '../../../../adapters/services/projects.service';
import APP_PATHS                                     from '../../../constants/routes.constants';
import { useAppDispatch }                            from '../../../hooks/store';
import useCrudNotificationResponse                   from '../../../hooks/useCrudNotificationResponse';
import { DonationFields }                            from '../../../interfaces/api/ApiDonation';
import defaultCardColProps                           from '../../../shared/defaults/defaultCardColProps';
import { setLoader }                                 from '../../../store/features/uiSlice';
import { CreditCardPanel, DonationGeneralDataPanel } from './components';

const initialValues: DonationFields = {
  projectCategoryId: '',
  projectId: '',
  amount: 1,
  message: '',
  anonymous: false,
  reminderEnabled: false,
  cardNumber: '',
  expirationDate: '',
  cvv: '',
  cardHolder: '',
  email: '',
};

const DonationFormPage = () => {
  const { t } = useTranslation('sponsor');
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();

  const navigate = useNavigate();
  const { projectId } = useParams();

  const [step, setStep] = useState(1);
  const [donationId, setDonationId] = useState<string>();

  const [form] = useForm<DonationFields>();

  const { data: project } = useGetProjectQuery(projectId);

  const [donate, {
    isLoading: isDonating,
    isSuccess: donated,
    error: donateError,
  }] = useCreateDonationMutation();

  useCrudNotificationResponse({
    isSuccess: donated,
    error: donateError,
    successNotification: {
      message: t('donations.actions.save.success.title'),
      description: t('donations.actions.save.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isDonating));
  }, [dispatch, isDonating]);

  useEffect(() => form.setFieldsValue({ projectId: project?.id }), [project]);

  useEffect(
    () => {
      if (donationId !== undefined) {
        navigate(generatePath(APP_PATHS.public.donationThanks, { donationId }));
      }
    },
    [donationId],
  );

  const handleDonation = async (fields: DonationFields) => {
    const donationFields = keycloak.authenticated
      ? {
        ...fields,
        sponsorId: keycloak.idTokenParsed?.sub,
      }
      : fields;

    const result = donate(donationFields);
    const id = await result.unwrap();
    setDonationId(id as unknown as string);
  };

  return (
    <Row id='donation-form' justify='center'>
      <Col {...defaultCardColProps}>
        <Card>
          <Form
            form={form}
            layout='vertical'
            onFinish={handleDonation}
            requiredMark={ false }
            initialValues={initialValues}
          >
            <div className={step === 2 ? 'hidden' : ''}>
              <DonationGeneralDataPanel
                form={form}
                project={project}
                onNext={() => setStep(2)}
              />
            </div>
            <div className={step === 1 ? 'hidden' : ''}>
              <CreditCardPanel
                form={form}
                project={project}
                onPrev={() => setStep(1)}
              />
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default DonationFormPage;
