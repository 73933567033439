import { isNaN }               from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams }     from 'react-router-dom';

type PageInfo = {
  page: number;
  setQueryPage: (_p: number) => void;
}

const usePagination = (pageName = 'page'): PageInfo => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryPage = Number.parseInt(searchParams.get(pageName) ?? '', 10);
  const [page, setPage] = useState(!isNaN(queryPage) ? queryPage : 1);

  const setQueryPage = (_p: number) => {
    searchParams.set(pageName, _p.toString());
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const queriedPage = Number.parseInt(searchParams.get(pageName) ?? '1', 10);

    if (!isNaN(queriedPage) && queriedPage !== page) {
      setPage(queriedPage);
    }
  }, [page, searchParams]);

  return { page, setQueryPage };
};

export default usePagination;
