import { Card, Col, Row }         from 'antd';
import { useForm }                from 'antd/es/form/Form';
import React, { useEffect }       from 'react';
import { useTranslation }         from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateGradeMutation,
  useGetGradeQuery,
  useUpdateGradeMutation,
}                                 from '../../../../adapters/services/grades.service';
import { GoBackButton }                          from '../../../../components/buttons';
import APP_PATHS                                 from '../../../constants/routes.constants';
import { useAppDispatch }                        from '../../../hooks/store';
import useCrudNotificationResponse               from '../../../hooks/useCrudNotificationResponse';
import { ApiGradeLocalization }                  from '../../../interfaces/api/ApiGradeLocalization';
import { setLoader }                             from '../../../store/features/uiSlice';
import { FormGrade, GradeCallback, GradeFields } from './form';

const ManageGradePage = () => {
  const { t } = useTranslation('admin');
  const { gradeId } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [form] = useForm<GradeFields>();

  const { data: grade } = useGetGradeQuery(gradeId, { skip: gradeId === undefined });

  const onClose = () => {
    navigate(APP_PATHS.admin.grades.root);
  };

  const [createGrade, {
    isLoading: isCreating,
    isSuccess: created,
    error: createError,
  }] = useCreateGradeMutation();

  const [updateGrade, {
    isLoading: isUpdating,
    isSuccess: updated,
    error: updateError,
  }] = useUpdateGradeMutation();

  const handleFinish: GradeCallback = (fields) => {
    const {
      name, abbreviation, age, eschoolId, localizations: newLocalizations,
    } = fields;
    const newFields = {
      name,
      abbreviation,
      age,
      eschoolId,
      localizations: newLocalizations.map((localization) => ({
        name: localization.name,
        abbreviation: localization.abbreviation,
        countries: localization?.countries?.map((country) => country),
      })),
    };
    if (grade === undefined) {
      createGrade(newFields);
    } else {
      updateGrade({
        id: grade.id,
        ...newFields,
      });
    }
  };

  useCrudNotificationResponse({
    isSuccess: created, error: createError, form, onClose,
  });
  useCrudNotificationResponse({
    isSuccess: updated, error: updateError, form, onClose,
  });

  useEffect(() => {
    dispatch(setLoader(isCreating || isUpdating));
  }, [dispatch, isCreating, isUpdating]);

  useEffect(() => {
    if (grade !== undefined) {
      const localizationsProc: ApiGradeLocalization[] = [];
      grade?.localizations?.forEach((localization) => {
        // eslint-disable-next-line max-len
        const newLocalizations = localizationsProc.find((item) => item.name === localization.name && item.abbreviation === localization.abbreviation);
        const newCountries = [];
        if (newLocalizations === undefined) {
          newCountries.push(localization.countryId);
          localizationsProc.push({
            id: localization.id,
            name: localization.name,
            abbreviation: localization.abbreviation,
            // eslint-disable-next-line max-len
            countries: newCountries as unknown as Array<string>,
          });
        } else {
          newLocalizations.countries?.push(localization.countryId!);
        }
      });
      form.setFieldsValue({
        name: grade.name,
        abbreviation: grade.abbreviation,
        age: grade.age,
        eschoolId: grade.eschoolId,
        localizations: localizationsProc,
      });
    }
  }, [grade, form]);

  return (
    <Row>
      <Col span={24}>
        <GoBackButton onClick={ onClose } />
      </Col>
      <Col span={24}>
        <Card className='mb-12'>
          <p className='text-h2 mb-8'>
            {
              gradeId === undefined
                ? t('admin:grades.create.title')
                : t('admin:grades.update.title')
            }
          </p>
          <FormGrade
            gradeId={ gradeId }
            onFinish={ handleFinish }
            form={ form }
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ManageGradePage;
