import { generatePath }            from 'react-router-dom';
import { GRADES_TAG, LIST_ID_TAG } from '../../core/constants/api.tags.constants';
import { GRADES_URI }              from '../../core/constants/api.uris.constants';
import { ApiGrade, CreateGrade }   from '../../core/interfaces/api/ApiGrade';
import ListData                    from '../../core/interfaces/general/ListData';
import PageableInputType           from '../../core/shared/utils/pageableInputType';
import fundEducationApi            from '../apis/fundEducation.api';

export const gradesApi = fundEducationApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationGrades: build.query<ApiGrade[], string | undefined>({
      query: (id) => generatePath(GRADES_URI.organizationRoot, { id }),
      providesTags: (_result, _error, id) => [{ type: GRADES_TAG, id }],
    }),
    getGrades: build.query<ListData<ApiGrade>, PageableInputType>({
      query: ({ page = 1, perPage = 10 }) => ({
        url: `${generatePath(GRADES_URI.root)}?page=${page}&perPage=${perPage}`,
        method: 'GET',
      }),
      providesTags: (result) => (
        result
          ? [
            ...result.data.map(({ id }) => ({ type: GRADES_TAG, id } as const)),
            { type: GRADES_TAG, id: LIST_ID_TAG },
          ]
          : [{ type: GRADES_TAG, id: LIST_ID_TAG }]
      ),
    }),
    getGrade: build.query<ApiGrade, string | undefined>({
      query: (id) => generatePath(GRADES_URI.action, { id }),
      providesTags: (_result, _error, id) => [{ type: GRADES_TAG, id }],
    }),
    createGrade: build.mutation<null, CreateGrade>({
      query: (body) => ({
        url: GRADES_URI.root,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: GRADES_TAG, id: LIST_ID_TAG }],
    }),
    updateGrade: build.mutation<null, ApiGrade>({
      query: ({ id, ...payload }) => ({
        url: generatePath(GRADES_URI.action, { id }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: GRADES_TAG, id }],
    }),
    deleteGrade: build.mutation<null, ApiGrade>({
      query: (payload) => ({
        url: generatePath(GRADES_URI.action, { id: payload.id }),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: GRADES_TAG, id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetOrganizationGradesQuery,
  useGetGradesQuery,
  useGetGradeQuery,
  useCreateGradeMutation,
  useUpdateGradeMutation,
  useDeleteGradeMutation,
} = gradesApi;
