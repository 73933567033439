import { Checkbox }    from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import * as React      from 'react';
import { Trans }       from 'react-i18next';
import ApiExample      from '../../../interfaces/api/ApiExample';

const columns: ColumnsType<ApiExample> = [
  {
    title: () => <Trans i18nKey='admin:example.title' />,
    dataIndex: 'name',
  },
  {
    title: () => <Trans i18nKey='admin:example.price' />,
    dataIndex: 'price',
    render: (_, example) => `$${example.price}`,
  },
  {
    title: () => <Trans i18nKey='admin:example.sequential' />,
    dataIndex: 'sequential',
    render: (_, example) => <Checkbox checked={ example.sequential } disabled />,
  },
];

export default columns;
