import {
  Col, Row, Grid, Card,
}                                     from 'antd';
import React, { useEffect, useState } from 'react';
import { Share2 }                     from 'react-feather';
import { useTranslation }             from 'react-i18next';
import { useParams }                  from 'react-router-dom';
import { useGetProjectQuery }         from '../../../../adapters/services/projects.service';
import { OutlinedButton }             from '../../../../components/buttons';
import LoadingCardRow                 from '../../../../components/LoadingCardRow';
import {
  DonationPanel,
  GeneralDetails,
  ScoresSummary,
  ProjectCategoriesSummary,
  ProjectCategoriesPanel,
  ProjectUpdatesPanel,
}                             from './components';
import './index.less';

const { useBreakpoint } = Grid;

const mainColSpan = 15;
const subColSpan = 24 - mainColSpan;

type Props = {
  editable?: boolean;
  showGrades?: boolean;
}

const ProjectDetailPage: React.FC<Props> = ({ editable = false, showGrades = false }) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const screens = useBreakpoint();

  const { data: project } = useGetProjectQuery(projectId);

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setEnabled(!project?.finished && project?.statusId === 1);
  }, [project, setEnabled]);

  return (
    <Row id='project-detail' gutter={[50, 16]}>
      <Col xs={24} md={{ span: subColSpan, push: mainColSpan }}>
        <Row gutter={[0, 24]}>
          {
            project !== undefined && (
              <>
                <Col span={24}>
                  <img alt={ project.title } src={ project.imageUrl } className='project-img' />
                </Col>
                {
                  // This displays on small viewports. When changing something within this
                  // block, don't forget to apply the same changes to its big viewport
                  // counterpart, some lines below
                  !screens.md && (
                    <Col span={24}>
                      <GeneralDetails
                        project={project}
                        canEdit={editable && enabled}
                      />
                    </Col>
                  )
                }
                <Col span={24}>
                  <ProjectCategoriesSummary
                    project={project}
                    canDonate={!editable && enabled}
                  />
                </Col>
              </>
            )
          }
          {
            project === undefined && (
              <Col span={24}>
                <LoadingCardRow count={1} />
              </Col>
            )
          }
          {
            !project?.finished && (
              <>
                <Col span={24}>
                  <span id='donation-disclaimer'>
                    { t('projects.continuous_donation_disclaimer') }
                  </span>
                </Col>
                <Col span={24}>
                  <OutlinedButton>
                    <Share2 />
                    { t('projects.help_sharing') }
                  </OutlinedButton>
                </Col>
              </>
            )
          }
          {
            project?.student != null && showGrades && (
              <Col span={24}>
                <ScoresSummary project={project} />
              </Col>
            )
          }
          {
            project !== undefined && (
              <>
                <Col span={24}>
                  <Card id='institution-card'>
                    <p className='text-body1'>
                      { t('projects.created_by') }
                    </p>
                    <p id='institution-name' className='text-blue-bold'>
                      { project.institution.name }
                    </p>
                  </Card>
                </Col>
                <Col span={ 24 }>
                  <DonationPanel project={project} />
                </Col>
              </>
            )
          }
        </Row>
      </Col>
      <Col xs={24} md={{ span: mainColSpan, pull: subColSpan }}>
        <Row gutter={ [0, 32] }>
          {
            project !== undefined && (
              <>
                {
                  // This displays on big viewports. When changing something within this
                  // block, don't forget to apply the same changes to its small viewport
                  // counterpart, some lines above
                  screens.md && (
                    <Col span={ 24 }>
                      <GeneralDetails
                        project={ project }
                        canEdit={ editable && enabled }
                      />
                    </Col>
                  )
                }
                <Col span={ 24 }>
                  <ProjectCategoriesPanel categories={ project.categories } />
                </Col>
              </>
            )
          }
          {
            project === undefined && <LoadingCardRow count={ 1 } />
          }
          <Col span={ 24 }>
            {
              project !== undefined && (
                <ProjectUpdatesPanel
                  project={ project }
                  canEdit={ editable && enabled }
                />
              )
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectDetailPage;
