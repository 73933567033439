import { useAppSelector } from './store';

type ConvertCurrencyFunction = (value: number) => string;

const useConvertCurrency = (): ConvertCurrencyFunction => {
  const { currency } = useAppSelector((state) => state.ui);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.code,
  });

  return (value) => formatter.format(value * currency.rate);
};

export default useConvertCurrency;
