import { useKeycloak }               from '@react-keycloak/web';
import React                         from 'react';
import { Navigate, Outlet }          from 'react-router-dom';
import HOME_ROUTES                   from '../../constants/homeRoutes.constants';
import APP_PATHS                     from '../../constants/routes.constants';
import useGetRoles, { UserRoleType } from '../../hooks/useGetRoles';

type Props = {
  role: UserRoleType;
}

const AuthMiddleware: React.FC<Props> = ({ role }) => {
  const { keycloak } = useKeycloak();
  const { role: localRole } = useGetRoles();

  const authenticatedRedirection = !keycloak.hasResourceRole(role) && localRole
    ? HOME_ROUTES[localRole] : null;

  const redirectTo = !keycloak.authenticated
    ? APP_PATHS.errors.not_allowed
    : authenticatedRedirection;

  return redirectTo !== null ? <Navigate to={ redirectTo } /> : <Outlet />;
};

export default AuthMiddleware;
