import { Col, Row }           from 'antd';
import parse                  from 'html-react-parser';
import React, { Fragment }    from 'react';
import useDBTranslation       from '../../../../../hooks/useDBTranslation';
import { ApiProjectCategory } from '../../../../../interfaces/api/ApiProjectCategory';
import { CategoryProgress }   from '../../../../../../components/projects/CategoryProgress';
import './index.less';

type Props = {
  categories: ApiProjectCategory[];
}

export const ProjectCategoriesPanel: React.FC<Props> = ({ categories }) => {
  const tDB = useDBTranslation();

  return (
    <Row gutter={[0, 32]} id='project-categories-panel'>
      {
        categories.map((category) => (
          <Fragment key={category.id}>
            <Col span={24} className='category-header'>
              <h4>{ tDB(category.type, 'name') }</h4>
              <CategoryProgress category={category} />
            </Col>
            <Col span={24}>
              { parse(category.description) }
            </Col>
            <Col span={24} className='img-container'>
              <img alt={ category.type.name } src={ category.imageUrl } />
            </Col>
          </Fragment>
        ))
      }
    </Row>
  );
};
