import React                       from 'react';
import { useTranslation }          from 'react-i18next';
import { BreadcrumbComponentType } from 'use-react-router-breadcrumbs';

const userTypeKeys = {
  ADM: 'user.admins',
  SPO: 'user.sponsors',
  ORG: 'user.organizations',
};

export const UserTypeBreadcrumb: BreadcrumbComponentType = ({ match }) => {
  const { t } = useTranslation('admin');
  return <span>{ t(userTypeKeys[(match.params.typeId ?? '') as keyof typeof userTypeKeys]) }</span>;
};
