import React                                            from 'react';
import {
  Button,
  Col, Empty, Form, FormInstance, Input, Row, Select,
}                               from 'antd';
import { PlusCircle, Trash2 }   from 'react-feather';
import { useTranslation }       from 'react-i18next';
import { useGetCountriesQuery } from '../../../../adapters/services/countries.service';
import { ApiGrade }             from '../../../interfaces/api/ApiGrade';

import './form.less';
import { ApiGradeLocalization } from '../../../interfaces/api/ApiGradeLocalization';

const { Item, List, ErrorList } = Form;
const { Option } = Select;

export type GradeFields = {
  name: string;
  abbreviation: string;
  age: number;
  eschoolId: string;
  localizations: ApiGradeLocalization[];
}

const initialValues: GradeFields = {
  name: '',
  abbreviation: '',
  age: 5,
  eschoolId: '',
  localizations: [],
};

export type GradeCallback = (field: GradeFields, entity?: ApiGrade) => void;

type Props = {
  entity?: ApiGrade;
  onFinish: GradeCallback;
  form: FormInstance<GradeFields>;
  gradeId?: string;
}

export const FormGrade: React.FC<Props> = ({
  entity, onFinish, form, gradeId,
}) => {
  const { t } = useTranslation(['common', 'admin']);

  const { data: countries } = useGetCountriesQuery();

  return (
    <Form<GradeFields>
      autoComplete='off'
      layout='vertical'
      name='frmGrade'
      requiredMark={ false }
      form={ form }
      initialValues={ initialValues }
      onFinish={ (v) => onFinish(v, entity) }
    >
      <Row gutter={ [16, 8]}>
        <Col span={24}>
          <Item
            label={ t('admin:grades.form.name.label') }
            name='name'
            className='text-body-bold'
            rules={[
              {
                required: true,
                message: t('admin:grades.form.name.required'),
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={24}>
          <Item
            label={ t('admin:grades.form.abbreviation.label') }
            name='abbreviation'
            className='text-body-bold'
            rules={[
              {
                required: true,
                message: t('admin:grades.form.abbreviation.required'),
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={24}>
          <Item
            label={ t('admin:grades.form.age.label') }
            name='age'
            className='text-body-bold'
            rules={[
              {
                required: true,
                message: t('admin:grades.form.age.required'),
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={24}>
          <Item
            label={ t('admin:grades.form.e_school.label') }
            name='eschoolId'
            className='text-body-bold'
          >
            <Input placeholder={ t('admin:grades.form.e_school.placeholder') } />
          </Item>
        </Col>
        <Col span={24}>
          <List name='localizations'>
            { (localizations, { add, remove }, { errors }) => (
              <Row gutter={ [16, 16] }>
                <Col span={24}>
                  <div className='d-flex flex-row justify-space-between'>
                    <p className='text-h2'>
                      <span>{ t('admin:grades.form.localizations.title') }</span>
                    </p>
                    <Button
                      type='primary'
                      className='d-flex align-center'
                      onClick={ add }
                    >
                      <span className='mr-2'>{ t('common:add') }</span>
                      <PlusCircle />
                    </Button>
                  </div>
                </Col>
                {
                  localizations.length === 0 && (
                    <Col span={24} className='mb-8'>
                      <Empty description={ t('admin:grades.form.localizations.empty') } />
                    </Col>
                  )
                }
                {
                  localizations.map((field, i) => (
                    <Col key={ field.key } span={ 24 } className='localizations--item'>
                      <div className='d-flex align-center justify-space-between'>
                        <p className='text-h4 mb-0'>
                          { t('admin:grades.form.localizations.localization') }
                        </p>
                        <Button
                          danger
                          type='text'
                          icon={ <Trash2 /> }
                          onClick={ () => remove(i) }
                        />
                      </div>
                      <div className='mb-8'>
                        <Item
                          label={ t('admin:grades.form.name.label') }
                          name={ [i, 'name'] }
                          className='text-body-bold'
                          rules={[
                            {
                              required: true,
                              message: t('admin:grades.form.name.required'),
                            },
                          ]}
                        >
                          <Input />
                        </Item>
                      </div>
                      <div className='mb-8'>
                        <Item
                          label={ t('admin:grades.form.abbreviation.label') }
                          name={ [i, 'abbreviation'] }
                          className='text-body-bold'
                          rules={[
                            {
                              required: true,
                              message: t('admin:grades.form.abbreviation.required'),
                            },
                          ]}
                        >
                          <Input />
                        </Item>
                      </div>
                      <div>
                        <Item
                          label={ t('admin:grades.form.localizations.country.label') }
                          name={ [i, 'countries'] }
                          className='text-body-bold'
                          rules={ [
                            {
                              required: true,
                              message: t('admin:grades.form.localizations.country.required'),
                            },
                          ] }
                        >
                          <Select
                            allowClear
                            mode='multiple'
                            showSearch
                            placeholder={ t('admin:grades.form.localizations.country.placeholder') }
                            filterOption={
                              (input, option) => (option?.children as unknown as string)
                                .toLowerCase().includes(input.toLowerCase())
                            }
                          >
                            {
                              countries?.map((country) => (
                                <Option key={ country.id } value={country.id}>
                                  { country.name }
                                </Option>
                              ))
                            }
                          </Select>
                        </Item>
                      </div>
                    </Col>
                  ))
                }
                {
                  errors.length !== 0 && (
                    <Col span={24}>
                      <ErrorList errors={ errors } />
                    </Col>
                  )
                }
              </Row>
            )}
          </List>
        </Col>
        <Col span={24}>
          <Button type='primary' block htmlType='submit'>
            {
              gradeId === undefined
                ? t('admin:grades.create.action')
                : t('admin:grades.update.action')
            }
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
