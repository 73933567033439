import {
  Button,
  Col, Form, FormInstance, Input, Row,
}                           from 'antd';
import React, { useEffect }   from 'react';
import { useTranslation }     from 'react-i18next';
import ApiProjectCategoryType from '../../../../core/interfaces/api/ApiProjectCategoryType';

const { Item } = Form;

export type ProjectCategoryTypeFields = {
  name: string;
  nameEn: string;
}

export type ProjectCategoryTypeCallback = (
  field: ProjectCategoryTypeFields, entity?: ApiProjectCategoryType
) => void;

type Props = {
  entity?: ApiProjectCategoryType;
  onFinish: ProjectCategoryTypeCallback;
  form: FormInstance<ProjectCategoryTypeFields>;
}

const initialValues: ProjectCategoryTypeFields = {
  name: '',
  nameEn: '',
};

const ProjectCategoryTypeForm: React.FC<Props> = ({ entity, onFinish, form }) => {
  const { t } = useTranslation(['common', 'admin']);

  useEffect(() => {
    if (entity !== undefined) {
      form.setFieldsValue({
        name: entity.name,
        nameEn: entity.nameEn,
      });
    }
  }, [entity, form]);

  return (
    <Form<ProjectCategoryTypeFields>
      layout='vertical'
      name='frmProjectCategoryType'
      requiredMark={ false }
      form={ form }
      initialValues={ initialValues }
      preserve={ false }
      onFinish={ (v) => onFinish(v, entity) }
    >
      <Row gutter={ [16, 0] }>
        <Col span={24}>
          <Item
            label={ t('admin:projects.category_types.form.name.label') }
            name='name'
            rules={ [
              {
                required: true,
                message: t('admin:projects.category_types.form.name.required'),
              },
            ] }
          >
            <Input name='name' />
          </Item>
        </Col>
        <Col span={24}>
          <Item
            label={ t('admin:projects.category_types.form.name_en.label') }
            name='nameEn'
            rules={ [
              {
                required: true,
                message: t('admin:projects.category_types.form.name_en.required'),
              },
            ] }
          >
            <Input name='nameEn' />
          </Item>
        </Col>
      </Row>

      <Item>
        <Button
          block
          type='primary'
          size='large'
          htmlType='submit'
        >
          { t('save') }
        </Button>
      </Item>
    </Form>
  );
};

export default ProjectCategoryTypeForm;
