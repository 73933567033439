import { Card, Col, Row }                       from 'antd';
import React                                    from 'react';
import { useTranslation }                       from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useGetDonationInfoQuery }              from '../../../../adapters/services/donations.service';
import { ConfettiImg }                          from '../../../../components/assets/Images';
import { OutlinedButton }                       from '../../../../components/buttons';
import TitleImage                               from '../../../../components/projects/TitleImage';
import APP_PATHS                                from '../../../constants/routes.constants';
import defaultCardColProps                      from '../../../shared/defaults/defaultCardColProps';
import './index.less';

const DonationThanksPage = () => {
  const { t } = useTranslation('sponsor');
  const { donationId } = useParams();
  const navigate = useNavigate();

  const { data } = useGetDonationInfoQuery(donationId);

  const backToProject = () => navigate(
    generatePath(APP_PATHS.public.projectDetail, { projectId: data?.projectId }),
  );

  return (
    <Row justify='center'>
      <Col {...defaultCardColProps}>
        <Card id='donation-thanks'>
          {
            data !== undefined && (
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <h1 className='text-primary'>
                    { t('donations.thanks.title') }
                  </h1>
                </Col>
                <Col span={24}>
                  <ConfettiImg id='confetti-img' />
                </Col>
                <Col span={24}>
                  <p className='text-body1'>
                    { t('donations.thanks.description') }
                  </p>
                </Col>
                <Col span={24}>
                  <TitleImage
                    title={data.projectTitle}
                    imageUrl={data.projectImageUrl}
                  />
                </Col>
                <Col span={24}>
                  <h3 id='amount-text'>
                    <span>{ t('donations.supported_amount') }</span>
                    <span>
                      $
                      { parseFloat(data.amount).toFixed(2) }
                    </span>
                  </h3>
                </Col>
                <Col span={24}>
                  <OutlinedButton onClick={backToProject}>
                    { t('donations.thanks.back') }
                  </OutlinedButton>
                </Col>
              </Row>
            )
          }
        </Card>
      </Col>
    </Row>
  );
};

export default DonationThanksPage;
