import moment             from 'moment';
import React              from 'react';
import { useTranslation } from 'react-i18next';
import ReactTimeAgo       from 'react-time-ago';

type Props = {
  date: string;
}

export const LocalizedTimeAgo: React.FC<Props> = ({ date }) => {
  const { i18n } = useTranslation();
  return <ReactTimeAgo date={moment(date).toDate()} locale={i18n.language} />;
};
