import {
  Card, Col, Row, Steps,
}                                     from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { useGetOrganizationQuery }    from '../../../../adapters/services/organizations.service';
import './index.less';
import AlertError                     from '../../../../components/Alerts/AlertError';
import CreateOrganizationForm         from '../../../../components/forms/organization/WizardForms/step1';
import UpdatePetitionMessageForm      from '../../../../components/forms/organization/WizardForms/step2';
import UploadPetitionDocumentsForm    from '../../../../components/forms/organization/WizardForms/step3';
import CompletePetitionForm           from '../../../../components/forms/organization/WizardForms/step4';
import { useAppSelector }             from '../../../hooks/store';
import defaultCardColProps            from '../../../shared/defaults/defaultCardColProps';

const { Step } = Steps;

type StepType = {
  key: string;
  title: string;
  description: string;
  disabled: boolean;
}

const JoinWizardPage = () => {
  const { t } = useTranslation('organization');
  const { petitionStatus } = useAppSelector((state) => state.org);

  const [steps, setSteps] = useState<StepType[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const { data } = useGetOrganizationQuery();

  useEffect(() => {
    setSteps([
      {
        key: '1',
        title: t('wizard.step1.title'),
        description: t('wizard.step1.description'),
        disabled: false,
      },
      {
        key: '2',
        title: t('wizard.step2.title'),
        description: t('wizard.step2.description'),
        disabled: data?.id === undefined,
      },
      {
        key: '3',
        title: t('wizard.step3.title'),
        description: t('wizard.step3.description'),
        disabled: data?.petition?.message === undefined
                  || data?.petition?.message === null,
      },
      {
        key: '4',
        title: t('wizard.step4.title'),
        description: t('wizard.step4.description'),
        disabled: data?.petition?.documents.length === undefined
                  || data?.petition?.documents.length === 0,
      },
    ]);
  }, [t, data]);

  const goToNextStep = () => setCurrentStep(currentStep + 1);

  return (
    <>
      <h1 className='text-center title a mt-10'>{ t('wizard.title') }</h1>
      <Row justify='center'>
        <Col { ...defaultCardColProps }>
          {
            petitionStatus.rejected && (
              <AlertError title={ t('wizard.rejected') } />
            )
          }
          <Steps
            current={currentStep}
            className='join-wizard'
            labelPlacement='vertical'
            onChange={ setCurrentStep }
          >
            {steps.map((props) => (
              <Step {...props} />
            ))}
          </Steps>
          <Card className='wizard-content'>
            { currentStep === 0
              && <CreateOrganizationForm entity={data} onSuccess={goToNextStep} />}
            { currentStep === 1
              && <UpdatePetitionMessageForm entity={data?.petition} onSuccess={goToNextStep} />}
            { currentStep === 2
              && <UploadPetitionDocumentsForm entity={data?.petition} onSuccess={goToNextStep} /> }
            { currentStep === 3
              && <CompletePetitionForm /> }
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default JoinWizardPage;
