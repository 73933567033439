import React                                     from 'react';
import { useTranslation }                        from 'react-i18next';
import {
  Button, Col, Form, FormInstance, Input, Row,
}                                         from 'antd';

const { Item } = Form;

export type ChangePasswordFields = {
  oldPassword: string;
  newPassword: string;
}

const initialValues: ChangePasswordFields = {
  oldPassword: '',
  newPassword: '',
};

type Props = {
  form: FormInstance<ChangePasswordFields>;
  onFinish: (fields: ChangePasswordFields) => void;
}

const ChangePasswordForm: React.FC<Props> = ({ form, onFinish }) => {
  const { t } = useTranslation();

  return (
    <Form<ChangePasswordFields>
      autoComplete='off'
      form={ form }
      layout='vertical'
      name='frmPassword'
      preserve={ false }
      requiredMark={ false }
      initialValues={ initialValues }
      onFinish={ (v) => onFinish(v) }
    >
      <Row gutter={ [16, 8] }>
        <Col span={24}>
          <Item
            label={ t('profile.form.old_password.label') }
            name='oldPassword'
            rules={[
              {
                required: true,
                message: t('profile.form.old_password.required'),
              },
            ]}
          >
            <Input.Password visibilityToggle={ false } />
          </Item>
        </Col>
        <Col span={24}>
          <Item
            label={ t('profile.form.new_password.label') }
            name='newPassword'
            rules={[
              {
                required: true,
                message: t('profile.form.new_password.required'),
              },
              {
                min: 8,
                message: t('profile.form.new_password.min'),
              },
            ]}
          >
            <Input.Password />
          </Item>
        </Col>
        <Col span={24}>
          <Button
            block
            type='primary'
            htmlType='submit'
          >
            { t('save') }
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ChangePasswordForm;
