import React                                 from 'react';
import { generatePath, Navigate, useParams } from 'react-router-dom';
import APP_PATHS                             from '../../../constants/routes.constants';

const ProjectListPage = () => {
  const params = useParams();
  return <Navigate to={ generatePath(APP_PATHS.organization.institution, params) } />;
};

export default ProjectListPage;
