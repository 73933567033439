import { Button }         from 'antd';
import { ButtonProps }    from 'antd/lib/button/button';
import React              from 'react';
import { ArrowLeft }      from 'react-feather';
import { useTranslation } from 'react-i18next';

export const GoBackButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Button type='primary' className='mb-6' {...props}>
      <div className='d-flex align-center'>
        <ArrowLeft className='mr-2' />
        { t('go_back') }
      </div>
    </Button>
  );
};
