import { ApiDeletionJustification } from './ApiDeletionJustification';
import { ApiInstitution }           from './ApiInstitution';
// eslint-disable-next-line import/no-cycle
import { ApiProjectCategory } from './ApiProjectCategory';
import { ApiStudent }         from './ApiStudent';

export enum ProjectTypeEnum {
  STUDENT = 1,
  INSTITUTIONAL
}

export interface ApiProject {
  id: string;
  title: string;
  summary: string;
  description: string;
  imageUrl: string;
  raised: number;
  goal: number;
  qrUrl?: string;
  expirationDate: string;
  typeId: ProjectTypeEnum;
  institutionId: string;
  institution: ApiInstitution;
  studentId?: string;
  student?: ApiStudent;
  finished: boolean;
  categories: ApiProjectCategory[];
  statusId: number;
  deletionJustification?: ApiDeletionJustification;
}

export interface ApiSponsorProject extends ApiProject {
  notificationsEnabled: boolean;
}
