import React              from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row }       from 'antd';
import { ApiUser }        from '../../../core/interfaces/api/ApiUser';

import './index.less';

interface Props {
  data: ApiUser | undefined;
  handleOnView?: () => void;
  handleOnDelete?: () => void;
}

const UserList = (props: Props) => {
  const { data: user, handleOnView, handleOnDelete } = props;
  const { t } = useTranslation('common');

  return (
    <Row align='middle' className='user-panel px-4 py-2 my-2' key={user?.id}>
      <Col span={24}>
        <div className='text-body-bold mb-2'>
          <span>{ `${user?.firstName} ${user?.lastName}` }</span>
        </div>
      </Col>
      <Col>
        <div className='d-flex'>
          <div className='mr-4'>
            <button
              type='button'
              className='btn-text-custom btn-text-custom-primary'
              onClick={ handleOnView }
            >
              { t('common:see') }
            </button>
          </div>
          <div>
            <button
              type='button'
              className='btn-text-custom btn-text-custom-secondary'
              onClick={ handleOnDelete }
            >
              { t('common:delete') }
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default UserList;
