import {
  Button, Card, Col, Modal, Row,
} from 'antd';
import { useForm }               from 'antd/lib/form/Form';
import React, { useEffect }      from 'react';
import { Edit, Eye, Trash2 }     from 'react-feather';
import { useTranslation }        from 'react-i18next';
import { useParams }             from 'react-router-dom';
import {
  useCreateReportCardMutation,
  useDeleteReportCardMutation,
  useGetReportCardsQuery,
  useUpdateReportCardMutation,
}                                                from '../../../../adapters/services/reportCards.service';
import { OutlinedItem }                          from '../../../../components/containers';
import { LocalizedDateString }                   from '../../../../components/l10n';
import { useAppDispatch }                        from '../../../hooks/store';
import useCrudNotificationResponse               from '../../../hooks/useCrudNotificationResponse';
import { ApiReportCard, ReportCardFields }       from '../../../interfaces/api/ApiReportCard';
import defaultCardColProps                       from '../../../shared/defaults/defaultCardColProps';
import { defaultConfirmProps, defaultInfoProps } from '../../../shared/defaults/defaultModalProps';
import { setLoader }                             from '../../../store/features/uiSlice';
import { ReportCardForm, ReportCardsCallback }   from './components/ReportCardForm';
import './index.less';

const ListReportCardsPage = () => {
  const { t } = useTranslation(['common', 'organization']);
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const [form] = useForm<ReportCardFields>();

  const { data } = useGetReportCardsQuery(projectId);

  const [createCard, {
    isLoading: isCreating,
    isSuccess: created,
    error: createError,
  }] = useCreateReportCardMutation();

  const [updateCard, {
    isLoading: isUpdating,
    isSuccess: updated,
    error: updateError,
  }] = useUpdateReportCardMutation();

  const [deleteCard, {
    isLoading: isDeleting,
    isSuccess: deleted,
    error: deleteError,
  }] = useDeleteReportCardMutation();

  useCrudNotificationResponse({
    isSuccess: created,
    error: createError,
    form,
    successNotification: {
      message: t('organization:report_cards.actions.save.success.title'),
      description: t('organization:report_cards.actions.save.success.description'),
    },
  });
  useCrudNotificationResponse({
    isSuccess: updated,
    error: updateError,
    form,
    successNotification: {
      message: t('organization:report_cards.actions.update.success.title'),
      description: t('organization:report_cards.actions.update.success.description'),
    },
  });
  useCrudNotificationResponse({
    isSuccess: deleted,
    error: deleteError,
    successNotification: {
      message: t('organization:report_cards.actions.delete.success.title'),
      description: t('organization:report_cards.actions.delete.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isCreating || isUpdating || isDeleting));
  }, [dispatch, isCreating, isUpdating, isDeleting]);

  const handleFinish: ReportCardsCallback = (fields, reportCard) => {
    // Stupid workaround for stupid antd issue that makes scores to have a lot of useless metadata
    // that makes the camelCase converter crash
    const scores = fields.scores.map(({ id, subject, score }) => ({ id, subject, score }));

    if (reportCard === undefined) {
      createCard({
        projectId,
        scores,
      });
    } else {
      updateCard({
        id: reportCard.id,
        projectId,
        scores,
      });
    }
  };

  const onAdd = () => {
    Modal.info({
      ...defaultInfoProps,
      onCancel: () => form.resetFields(),
      onOk: () => form.resetFields(),
      title: t('organization:report_cards.new'),
      width: '65%',
      content: <ReportCardForm
        form={ form }
        onFinish={ handleFinish }
        previousReport={ data?.[0] }
      />,
    });
  };

  const onView = (reportCard: ApiReportCard) => {
    Modal.info({
      ...defaultInfoProps,
      onCancel: () => form.resetFields(),
      onOk: () => form.resetFields(),
      title: t('organization:report_cards.view'),
      content: <ReportCardForm
        entity={ reportCard }
        form={ form }
        isReadOnly
      />,
    });
  };

  const onEdit = (reportCard: ApiReportCard) => {
    Modal.info({
      ...defaultInfoProps,
      onCancel: () => form.resetFields(),
      onOk: () => form.resetFields(),
      title: t('organization:report_cards.update'),
      content: <ReportCardForm
        entity={ reportCard }
        form={ form }
        onFinish={ handleFinish }
      />,
    });
  };

  const onDelete = (reportCard: ApiReportCard) => Modal.confirm({
    ...defaultConfirmProps,
    title: t('organization:report_cards.delete'),
    onOk: () => deleteCard(reportCard),
  });

  return (
    <Row justify='center'>
      <Col {...defaultCardColProps}>
        <Card id='report-cards'>
          <h1>{ t('organization:report_cards.title') }</h1>
          {
            data?.map((reportCard) => (
              <OutlinedItem
                key={ reportCard.id }
                description={ <LocalizedDateString date={ reportCard.createdAt } /> }
                actions={(
                  <>
                    <Button
                      shape='circle'
                      size='large'
                      onClick={() => onView(reportCard)}
                    >
                      <Eye color='var(--fund-education-primary-color)' />
                    </Button>
                    <Button
                      shape='circle'
                      size='large'
                      onClick={() => onEdit(reportCard)}
                    >
                      <Edit color='var(--fund-education-primary-color)' />
                    </Button>
                    <Button
                      shape='circle'
                      size='large'
                      onClick={() => onDelete(reportCard)}
                    >
                      <Trash2 color='var(--gray)' />
                    </Button>
                  </>
                )}
              />
            ))
          }
          <Button
            block
            type='primary'
            size='large'
            onClick={ onAdd }
          >
            { t('organization:report_cards.new') }
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default ListReportCardsPage;
