import React              from 'react';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate }    from 'react-router-dom';
import APP_PATHS          from '../../core/constants/routes.constants';

const NotFound = () => {
  const { t } = useTranslation();
  const nav = useNavigate();

  return (
    <Result
      status='404'
      title={ t('not_found.title') }
      subTitle={ t('not_found.subtitle') }
      extra={(
        <Button onClick={ () => nav(APP_PATHS.home) } type='primary'>
          { t('go_home') }
        </Button>
      )}
    />
  );
};

export default NotFound;
