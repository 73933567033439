import { Card }             from 'antd';
import React                from 'react';
import { useNavigate }      from 'react-router-dom';
import APP_PATHS            from '../../../core/constants/routes.constants';
import { useAppSelector }   from '../../../core/hooks/store';
import { EditCircleButton } from '../../buttons';
import { UserAvatar }       from '../UserAvatar';

export const ProfileCard = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const goToForm = () => navigate(APP_PATHS.shared.profile);

  return (
    <Card>
      <div className='d-flex align-center'>
        <div>
          <UserAvatar size={ 80 } />
        </div>
        <div className='ml-4 w-100 d-flex justify-space-between align-center'>
          <div className='text-break-word'>
            <h3 className='text-primary'>
              { `${user?.firstName} ${user?.lastName}` }
            </h3>
            <span className='text _16'>
              { user?.email }
            </span>
          </div>
          <div>
            <EditCircleButton onClick={goToForm} />
          </div>
        </div>
      </div>
    </Card>
  );
};
