import React, { useEffect }                    from 'react';
import {
  Button, Card, Col, Modal, Pagination, Row,
}                                  from 'antd';
import { Edit, Trash2 }        from 'react-feather';
import { useTranslation }      from 'react-i18next';
import { generatePath, Link }  from 'react-router-dom';
import {
  useDeleteProjectUpdateMutation,
  useGetProjectUpdatesQuery,
}                                  from '../../../../../../adapters/services/projectsUpdates.services';
import { LocalizedTimeAgo }        from '../../../../../../components/l10n';
import APP_PATHS                   from '../../../../../constants/routes.constants';
import { useAppDispatch }          from '../../../../../hooks/store';
import useCrudNotificationResponse from '../../../../../hooks/useCrudNotificationResponse';
import usePagination               from '../../../../../hooks/usePagination';
import { ApiProject }              from '../../../../../interfaces/api/ApiProject';
import { ApiProjectUpdate }        from '../../../../../interfaces/api/ApiProjectUpdate';
import { defaultConfirmProps }     from '../../../../../shared/defaults/defaultModalProps';
import { setLoader }               from '../../../../../store/features/uiSlice';

type Props = {
  project: ApiProject;
  canEdit: boolean;
}

const perPage = 2;

export const ProjectUpdatesPanel: React.FC<Props> = ({ project, canEdit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { page, setQueryPage } = usePagination('updatesPage');
  const { detail, updatesEdit } = APP_PATHS.organization.projects;

  const { data: updates } = useGetProjectUpdatesQuery({
    projectId: project.id,
    page,
    perPage,
  });

  const [deleteProjectUpdate, {
    isLoading: isDeleting,
    isSuccess: deletedProjectUpdate,
    error: projectUpdateError,
  }] = useDeleteProjectUpdateMutation();

  const onDeleteProjectUpdate = ({ id }: ApiProjectUpdate) => {
    Modal.confirm({
      ...defaultConfirmProps,
      title: t('organization:institution.projects.updates.delete.confirm'),
      onOk: () => deleteProjectUpdate(id),
      width: '50%',
    });
  };

  useCrudNotificationResponse({
    isSuccess: deletedProjectUpdate,
    error: projectUpdateError,
    successNotification: {
      message: t('organization:institution.projects.updates.delete.success.title'),
      description: t('organization:institution.projects.updates.delete.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isDeleting));
  }, [dispatch, isDeleting]);

  return (
    <Row gutter={ [0, 24] } className='mb-7'>
      {
          updates !== undefined && (
            <>
              <Col span={24}>
                <h4 className='text-h2 d-flex justify-space-between mb-2'>
                  <span>{ t('projects.updates') }</span>
                  <span className='text-gray'>{ updates.total }</span>
                </h4>
              </Col>
              {
                updates.data.map((update) => (
                  <Col span={ 24 } key={update.id}>
                    <Card>
                      <div className='text-body-bold text-primary'>
                        <LocalizedTimeAgo date={ update.createdAt } />
                      </div>
                      <div className='d-flex justify-center align-center'>
                        <div className='text-h4 flex-fill'>
                          { update.title }
                        </div>
                        {
                          canEdit && (
                            <>
                              <Link
                                to={
                                  generatePath(
                                    `${detail}/${updatesEdit}`,
                                    {
                                      institutionId: project.institutionId,
                                      projectId: project.id,
                                      updateId: update.id,
                                    },
                                  )
                                }
                                className='d-flex mr-3'
                              >
                                <Edit />
                              </Link>
                              <Button type='text' shape='circle' onClick={ () => onDeleteProjectUpdate(update) }>
                                <Trash2 className='text-gray-2' />
                              </Button>
                            </>
                          )
                        }
                      </div>
                      <div className='text-body1'>
                        { update.description }
                      </div>
                      <div className='mt-4 d-flex justify-center'>
                        <img src={ update.imageUrl } alt={ update.title } />
                      </div>
                    </Card>
                  </Col>
                ))
              }
              {
                updates.total > 0 && (
                  <Col className='d-flex justify-center' span={24}>
                    <Pagination
                      size='small'
                      showLessItems
                      current={page}
                      hideOnSinglePage
                      pageSize={perPage}
                      onChange={setQueryPage}
                      total={updates.total}
                    />
                  </Col>
                )
              }
            </>
          )
        }
    </Row>
  );
};
