import {
  Button, Col, Form, Input, Row,
}                                                        from 'antd';
import { useForm }                          from 'antd/lib/form/Form';
import React, { useEffect }                 from 'react';
import { useTranslation }                   from 'react-i18next';
import { useAppDispatch }                   from '../../../../core/hooks/store';
import useCrudNotificationResponse          from '../../../../core/hooks/useCrudNotificationResponse';
import { ApiOrganizationPetition }          from '../../../../core/interfaces/api/ApiOrganizationPetition';
import { setLoader }                        from '../../../../core/store/features/uiSlice';
import { useUpdatePetitionMessageMutation } from '../../../../adapters/services/organizations.service';
import './style.less';

const { Item } = Form;
const { TextArea } = Input;

type MessageFields = {
  message: string;
}

const initialValues: MessageFields = {
  message: '',
};

type Props = {
  entity?: ApiOrganizationPetition;
  onSuccess: () => void;
}

const UpdatePetitionMessageForm: React.FC<Props> = ({ entity, onSuccess }) => {
  const { t } = useTranslation('organization');
  const dispatch = useAppDispatch();
  const [form] = useForm<MessageFields>();

  const [updateMessage, {
    isLoading,
    isSuccess,
    error,
  }] = useUpdatePetitionMessageMutation();

  useCrudNotificationResponse({
    isSuccess,
    error,
    onClose: onSuccess,
    successNotification: {
      message: t('wizard.step2.actions.update.success.title'),
      description: t('wizard.step2.actions.update.success.description'),
    },
  });

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (entity !== undefined) {
      form.setFieldsValue({
        message: entity.message,
      });
    }
  }, [entity, form]);

  return (
    <Form<MessageFields>
      form={ form }
      layout='vertical'
      name='frmPetitionMessage'
      requiredMark={ false }
      className='wizard-form'
      initialValues={ initialValues }
      onFinish={ updateMessage }
    >
      <Row gutter={ [16, 0] }>
        <Col span={ 24 } className='text-center'>
          <h4>{ t('wizard.step2.form.title') }</h4>
        </Col>
        <Col span={ 24 } className='wizard-step-description'>
          { t('wizard.step2.form.description') }
        </Col>
        <Col span={ 24 }>
          <Item
            label={ t('wizard.step2.form.message.label') }
            name='message'
            rules={ [
              {
                required: true,
                message: t('wizard.step2.form.message.required'),
              },
            ] }
          >
            <TextArea
              name='message'
              placeholder={ t('wizard.step2.form.message.placeholder') }
            />
          </Item>
        </Col>
      </Row>
      <Item>
        <Button
          block
          type='primary'
          size='large'
          htmlType='submit'
        >
          { t('wizard.step2.form.submit') }
        </Button>
      </Item>
    </Form>
  );
};

export default UpdatePetitionMessageForm;
