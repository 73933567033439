import React, { useEffect, useState } from 'react';
import {
  Col, Empty, Pagination, Row,
}                                        from 'antd';
import { useTranslation }         from 'react-i18next';
import { generatePath }           from 'react-router-dom';
import {
  ProjectFilters,
  ProjectTypeFilter,
  useGetPaginatedProjectsQuery,
}                                        from '../../../../adapters/services/projects.service';
import { SearchInput, SelectInput } from '../../../../components/inputs';
import ProjectCard                  from '../../../../components/projects/ProjectCard';
import APP_PATHS                    from '../../../constants/routes.constants';
import { useFilterSearchParams }    from '../../../hooks/useFilterSearchParams';
import usePagination                from '../../../hooks/usePagination';
import { defaultPagination }        from '../../../shared/defaults/pagination';

const perPage = 15;

const buildFilters = (
  queryTitle: string | null,
  queryType: string | null,
): ProjectFilters => ({
  title: queryTitle ?? undefined,
  type: queryType as ProjectTypeFilter ?? undefined,
  status: 'ACTIVE',
  order: 'DATE',
});

const DiscoverPage = () => {
  const { t } = useTranslation();

  const { page, setQueryPage } = usePagination();

  const { searchParams, onFilter } = useFilterSearchParams();
  const queryTitle = searchParams.get('title');
  const queryType = searchParams.get('type');
  const [filters, setFilters] = useState<ProjectFilters>(buildFilters(queryTitle, queryType));

  const {
    data: projects,
    isLoading,
    isFetching,
  } = useGetPaginatedProjectsQuery({ perPage, page, filters });

  const buildLink = (projectId: string) => generatePath(
    APP_PATHS.public.projectDetail,
    { projectId },
  );

  useEffect(() => {
    if (queryType === null) {
      // Student projects will be shown by default
      searchParams.set('type', 'STUDENT');
    }
    setFilters(buildFilters(queryTitle, queryType));
  }, [queryTitle, queryType]);

  const typeOptions = [
    {
      label: t('discover.types.student'),
      value: 'STUDENT',
    },
    {
      label: t('discover.types.institutional'),
      value: 'INSTITUTIONAL',
    },
  ];

  return (
    <Row id='discover-page' gutter={[24, 32]}>
      <Col span={24} className='text-center'>
        <h1 className='text-primary'>{ t('discover.title') }</h1>
      </Col>
      <Col xs={24} sm={16} lg={18} xl={20}>
        <SearchInput
          loading={ isLoading || isFetching }
          defaultValue={ queryTitle ?? undefined }
          onSearch={(value) => onFilter(queryTitle, 'title', value)}
        />
      </Col>
      <Col xs={24} sm={8} lg={6} xl={4}>
        <SelectInput
          options={ typeOptions }
          optionFilterProp='label'
          loading={ isLoading || isFetching }
          defaultValue={ queryType ?? 'STUDENT' }
          onChange={ (value) => onFilter(queryType, 'type', value) }
        />
      </Col>
      {
        projects?.total === 0 && <Col span={24}><Empty description={ t('projects.empty') } /></Col>
      }
      {
        projects?.data.map((project) => (
          <Col
            xs={ 24 }
            sm={ 12 }
            xl={ 8 }
            key={ project.id }
          >
            <ProjectCard project={ project } to={ buildLink(project.id) } animation />
          </Col>
        ))
      }
      <Col span={ 24 } className='text-center'>
        <Pagination
          size='small'
          showLessItems
          pageSize={ perPage }
          onChange={ setQueryPage }
          current={ page }
          total={ projects?.total }
          { ...defaultPagination }
        />
      </Col>
    </Row>
  );
};

export default DiscoverPage;
